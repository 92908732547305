import { BILL_ERROR,BILL_SUCCESS,EDIT_BILL,SHOW_BILL,CREATE_BILL,PAY_BILL } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createBill = (bill) => {
    return {
        type: CREATE_BILL,
        payload: { bill }
    }
}

export const showBill = (bill_id) => {
    return {
        type: SHOW_BILL,
        payload: { bill_id }
    }
}

export const editBill = (bill) => {
    return {
        type: EDIT_BILL,
        payload: { bill }
    }
}

export const billPayment = (bill) => {
    return {
        type: PAY_BILL,
        payload: { bill }
    }
}

export const billSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: BILL_SUCCESS,
        payload: msg
    }
}


export function billShow(bill_id){
    return getApi().get('bill/'+bill_id)
}


export function billList(params){
    return getApi().get('bill/list',{
        params: params
    })
}

export function searchByBillData(data){
    return getApi().post('bill/generate',data)
}

export function customerSearchList(keyword,params){
    return getApi().get('customer/search/'+keyword,{
        params: params
    })
}

export function customerVehicleList(keyword,f_rate_type_id){
    return getApi().get('customerVehicleMapping/search/'+keyword+'/'+f_rate_type_id)
}

export function userList(params){
    return getApi().get('user/list',{
        params: params
    })
}

export function companyInfo(params){
    return getApi().get('companyInfo',{
        params: params
    })
}

export const billError = (error) => {
    return {
        type: BILL_ERROR,
        payload: error
    }
}
