import { DEVICE_SETTINGS_ERROR,DEVICE_SETTINGS_SUCCESS,EDIT_DEVICE_SETTINGS } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const DeviceSetings = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_DEVICE_SETTINGS:
            state = { ...state }
            break;
        case DEVICE_SETTINGS_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case DEVICE_SETTINGS_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default DeviceSetings;