import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardHeader,CardFooter,Modal, FormGroup } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createParkIn,searchByParkOutReport,searchByExpenseReport,searchByDayTopSheet} from '../../../store/park/actions';
import { rateTypeList,vehicleTypeList} from '../../../store/parkingRate/actions';
import { companyInfo} from '../../../store/bill/actions';
import '../Report/tableDesign.scss';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../pages/Loader/Loader";

// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";



class DayReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                loading: false,
                toggleSwitch: false,
                // startDate: new Date().toISOString().split('T')[0],
                startDate: new Date(),
                endDate: new Date(),
                edittoggleSwitch: false,  
                modal_scroll: false,
                slot: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchRateTypeList();     
            this.fetchVehicleTypeList();     
            this.fetchCompanyInfo();     
    }

    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList : response.data.rateType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchVehicleTypeList = () => {
        vehicleTypeList().then((response) => {
            this.setState({
                vehicleTypeList : response.data.vehicleType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (slot) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.slot = slot;
        this.removeBodyCss();
      }


      printDiv = () => {
        var content = document.getElementById('example').innerHTML;
        var title = document.title;
        var printWindow = window.open("", "_blank", "width=800,height=600");
        printWindow.document.open();
        printWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous"><style>footer { font-size: 10px; text-align: center;} @media print { footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width: 210mm; height: 297mm; }.table-bordered th, .table-bordered td {border: 1px solid #181b1e !important;}.table th, .table td {border-top: 1px solid #181b1e !important;}</style></head><body>');
        printWindow.document.write(content);
        printWindow.document.write('<footer>Printed at: '+ new Date().toLocaleString() + '</footer></body></html>');
        printWindow.document.close();
        printWindow.focus();
        setTimeout(function () {
          printWindow.print();
          printWindow.close();
        }, 1000);
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {       
            this.state.loading = true;   
            searchByDayTopSheet(values).then((response) => {
                this.setState({
                    expenseSummary : response.data.expense, 
                    parkOutSummary : response.data.parkOut, 
                    loading: false,
                });
            })
            .catch((err) => {
                this.state.loading = false;   

                console.log(err);
            })

            // searchByExpenseReport(values).then((response) => {
            //     this.setState({
            //         expenseSummary : response.data.summary, 
            //         loading: false,
            //     });
            // })
            // .catch((err) => {
            //     this.state.loading = false;   

            //     console.log(err);
            // })
            this.form && this.form.reset();
        }

        fetchCompanyInfo = () => {

            companyInfo().then((response) => {
                this.setState({
                    company: response.data
                })
            })
            .catch((err) => {
                console.log(err);
            })
            
          }
          handleSubmit(event) {
            alert('A name was submitted: ' + this.state.value);
            event.preventDefault();
          }
    render() {
        if (this.state.loading) return <Loader />;

        let vehicleTypeList = [];
        let rateTypeList = [];        
        let parkOutData = [];        
        let parkOutSummary = [];        
        let expenseSummary = [];        
        let company_name = '' , address = '' , mobile = '',total_hour_spend =0 , total_billed_amount =0 , total_receive_amount =0,total_expense = 0,total_vehicle=0;

        if (this.state.hasOwnProperty('vehicleTypeList')) { vehicleTypeList = this.state.vehicleTypeList; }
        if (this.state.hasOwnProperty('rateTypeList')) { rateTypeList = this.state.rateTypeList; }
        if (this.state.hasOwnProperty('parkOutData')) { parkOutData = this.state.parkOutData; }
        if (this.state.hasOwnProperty('parkOutSummary')) { parkOutSummary = this.state.parkOutSummary; }
        if (this.state.hasOwnProperty('expenseSummary')) { expenseSummary = this.state.expenseSummary; }
        if (this.state.hasOwnProperty('company')) {
            company_name = this.state.company.name;
            address = this.state.company.address;
            mobile = this.state.company.mobile;
        }
        parkOutSummary.map(function(element, index){
            total_vehicle += element.total_vehicle*1;
            total_hour_spend += element.total_hour_spend*1;
            total_billed_amount += element.total_billed_amount*1;
            total_receive_amount += element.total_receive_amount*1;
        });
        expenseSummary.map(function(element, index){
            total_expense += element.total_expense_amount;
        });
        
        const products = [];
          {parkOutSummary.map((summary, index) =>
            products.push({
                "id" : index+1, 
                "vehicle_type_name_en" : summary.vehicle_type_name_en, 
                "total_vehicle" : summary.total_vehicle,
                "total_hour_spend" : summary.total_hour_spend,
                "total_billed_amount" : summary.total_billed_amount,
                "total_receive_amount" : summary.total_receive_amount
            })
            )}
                  



          const columns = [
            {
                dataField: "id",
                text: "ID"
              },
            {
              dataField: "vehicle_type_name_en",
              text: "Vehicle Type"
            },
            {
              dataField: "total_vehicle",
              text: "Total Vehicle"
            },
            {
              dataField: "total_hour_spend",
              text: "Spent Time"
            },
            {
              dataField: "total_billed_amount",
              text: "Bill Amount"
            },
            {
                dataField: "total_receive_amount",
                text: "Receive Amount"
              }
          ];
          const rowEvents = {
            onMouseLeave: (e, row, rowIndex) => {
                products.push( parkOutSummary.map(function(element, index){
                    total_vehicle += element.total_vehicle*1;
                    total_hour_spend += element.total_hour_spend*1;
                    total_billed_amount += element.total_billed_amount*1;
                    total_receive_amount += element.total_receive_amount*1;})
                );
            }
          };

          products.push({
            "id" : 'Total', 
            "vehicle_type_name_en" : '', 
            "total_vehicle" : total_vehicle,
            "total_hour_spend" : total_hour_spend,
            "total_billed_amount" : total_billed_amount,
            "total_receive_amount" : total_receive_amount
        })
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Day Report" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Day Report</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>

                                            <FormGroup row>
                                                
                                                <div className="col-md-6">
                                                    <label>Date Range</label>
                                                    <DatePicker
                                                        selected={this.state.startDate}
                                                        className="form-control" 
                                                        onChange={date => this.setState({startDate: date})}
                                                        selectsStart
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                    />
                                                    <DatePicker
                                                        selected={this.state.endDate}
                                                        className="form-control" 
                                                        onChange={date => this.setState({endDate: date})}
                                                        selectsEnd
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        minDate={this.state.startDate}
                                                    />
                                                        <AvField    type="hidden" name="start_date" id="start_date" value={this.state.startDate} />
                                                        <AvField    type="hidden" name="end_date" id="end_date" value={this.state.endDate} />

                                                    {/* <AvField className="form-control" label=" Date"  type="date" name="search_date" id="search_date" value={this.state.searchDate} onChange={(e,v)=>this.setState({searchDate: v})} required/> */}
                                                </div>

                                                
                                            </FormGroup>

                                                 
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Search</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader>
                                        <FormGroup row>
                                                <CardTitle> DAY REPORT</CardTitle>

                                                <button size="lg" style={{marginLeft: '80%'}} className="btn-sm btn-primary mr-2 pull-right" onClick={() => this.printDiv()}>PRINT</button>
                                        </FormGroup>
                                    </CardHeader>
                                    <CardBody>
                                        <div id="example">

                                                    <div className="row">
                                                        <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                            <h2 className="text-center"><b>{company_name}</b></h2>
                                                            <span className="text-center">{address}</span><br></br>
                                                            <span className="text-center">Phone: {mobile}</span>
                                                            <br></br>
                                                            <h6><u>DAY REPORT</u></h6>
                                                            <p>Date: {new Date(this.state.startDate).toLocaleDateString()}  {(new Date(this.state.startDate).getDate() == new Date(this.state.endDate).getDate() && new Date(this.state.startDate).getFullYear() == new Date(this.state.endDate).getFullYear() && new Date(this.state.startDate).getMonth() == new Date(this.state.endDate).getMonth() )?'':'-'+new Date(this.state.endDate).toLocaleDateString()}</p>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 col-lg-6 " align="center">

                                                        <BootstrapTable
                                                                keyField="id"
                                                                data={products}
                                                                columns={columns}
                                                                cellEdit={cellEditFactory({ mode: "click" })}
                                                                rowEvents={ rowEvents }
                                                            />
                                                        </div>
                                                        <div className="col-6 col-lg-6 " align="center">
                                                    <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={3} style={{textAlign: 'center'}}>Expense</th></tr>
                                                                <tr>
                                                                    <th>Expense Type</th>
                                                                    <th>Expense Group</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {expenseSummary.map((summary, index) =>
                                                                <tr key={summary.expense_type_id .toString()}>
                                                                    <td>{summary.expense_type_name}</td>
                                                                    <td>{summary.expense_group_name}</td>
                                                                    <td>{summary.total_expense_amount} Tk</td>
                                                                </tr>
                                                                )}
                                                                <tr>
                                                                    <th colSpan={2}> Total</th>
                                                                    
                                                                    <th>{total_expense} Tk.</th>
                                                                </tr>
                                                            </tbody>
                                                    </Table>
                                                    </div>
                                                </div>
                                            </div>
                                                
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingSlot;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createParkIn })(DayReport));
