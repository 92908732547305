import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_BILL,BILL_SUCCESS,PAY_BILL,EDIT_BILL,SHOW_BILL } from './actionTypes';
import { billSuccess, billError,billShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createBill({ payload: { bill } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'bill/create', bill);
             if(response.success == false){
                
                yield put(billError(response.message));
            }else if(response.success == true){
                
                yield put(billSuccess(response.data.message));
            }

          }
    } catch (error) {
        yield put(billError(error));
    }
}


function* editBill({ payload: { bill } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'bill/'+bill.bill_id, bill);
             if(response.success == false){
                
                yield put(billError(response.message));
            }else if(response.success == true){
                
                yield put(billSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(billError(error));
    }
}


function* billPayment({ payload: { bill } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'bill/payment', bill);
             if(response.success == false){
                
                yield put(billError(response.message));
            }else if(response.success == true){
                
                yield put(billSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(billError(error));
    }
}


function* showBill({ payload: { bill_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'bill/'+bill_id);
             yield put(billShow(response));
          }
          
          
    } catch (error) {
        yield put(billError(error));
    }
}

export function* watchBill() {
    yield takeEvery(EDIT_BILL, editBill)
    yield takeEvery(PAY_BILL, billPayment)
    yield takeEvery(CREATE_BILL, createBill)
    yield takeEvery(SHOW_BILL, showBill)
}

function* BillSaga() {
    yield all([
        fork(watchBill),
    ]);
}

export default BillSaga;