import { EXPENSE_TYPE_ERROR,EXPENSE_TYPE_SUCCESS,EDIT_EXPENSE_TYPE,SHOW_EXPENSE_TYPE,CREATE_EXPENSE_TYPE } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createExpenseType = (expenseType) => {
    return {
        type: CREATE_EXPENSE_TYPE,
        payload: { expenseType }
    }
}

export const showExpenseType = (expense_type_id) => {
    return {
        type: SHOW_EXPENSE_TYPE,
        payload: { expense_type_id }
    }
}

export const editExpenseType = (expenseType) => {
    return {
        type: EDIT_EXPENSE_TYPE,
        payload: { expenseType }
    }
}



export const expenseTypeSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: EXPENSE_TYPE_SUCCESS,
        payload: msg
    }
}


export function expenseTypeShow(expense_type_id){
    return getApi().get('expenseType/'+expense_type_id)
}


export function expenseTypeList(params){
    return getApi().get('expenseType/list',{
        params: params
    })
}

export function expenseTypeSearchList(keyword,params){
    return getApi().get('expenseType/search/'+keyword,{
        params: params
    })
}



export const expenseTypeError = (error) => {
    return {
        type: EXPENSE_TYPE_ERROR,
        payload: error
    }
}
