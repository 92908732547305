import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_CUSTOMER,CUSTOMER_SUCCESS,EDIT_CUSTOMER,SHOW_CUSTOMER } from './actionTypes';
import { customerSuccess, customerError,customerShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createCustomer({ payload: { customer } }) {
    console.log('I am here');

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'customer/create', customer);
             if(response.success == false){
                
                yield put(customerError(response.message));
            }else if(response.success == true){
                
                yield put(customerSuccess(response.data.message));
            }

          }
    } catch (error) {
        yield put(customerError(error));
    }
}


function* editCustomer({ payload: { customer } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'customer/'+customer.customer_id, customer);
             if(response.success == false){
                
                yield put(customerError(response.message));
            }else if(response.success == true){
                
                yield put(customerSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(customerError(error));
    }
}


function* showCustomer({ payload: { customer_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'customer/'+customer_id);
             yield put(customerShow(response));
          }
          
          
    } catch (error) {
        yield put(customerError(error));
    }
}

export function* watchCustomer() {
    yield takeEvery(EDIT_CUSTOMER, editCustomer)
    yield takeEvery(CREATE_CUSTOMER, createCustomer)
    yield takeEvery(SHOW_CUSTOMER, showCustomer)
}

function* CustomerSaga() {
    yield all([
        fork(watchCustomer),
    ]);
}

export default CustomerSaga;