import { DEVICE_SETTINGS_ERROR,DEVICE_SETTINGS_SUCCESS,EDIT_DEVICE_SETTINGS,SHOW_DEVICE_SETTINGS,CREATE_DEVICE_SETTINGS } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createDeviceSettings = (deviceSettings) => {
    return {
        type: CREATE_DEVICE_SETTINGS,
        payload: { deviceSettings }
    }
}

export const showDeviceSettings = (deviceSettings_id) => {
    return {
        type: SHOW_DEVICE_SETTINGS,
        payload: { deviceSettings_id }
    }
}

export const editDeviceSettings = (deviceSettings) => {
    return {
        type: EDIT_DEVICE_SETTINGS,
        payload: { deviceSettings }
    }
}



export const deviceSettingsSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: DEVICE_SETTINGS_SUCCESS,
        payload: msg
    }
}


export function deviceSettingsShow(deviceSettings_id){
    return getApi().get('deviceSettings/'+deviceSettings_id)
}


export function deviceSettingsList(params){
    return getApi().get('deviceSettings/list',{
        params: params
    })
}

export function userList(params){
    return getApi().get('user/list',{
        params: params
    })
}


export const deviceSettingsError = (error) => {
    return {
        type: DEVICE_SETTINGS_ERROR,
        payload: error
    }
}
