import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_DEVICE_SETTINGS,DEVICE_SETTINGS_SUCCESS,EDIT_DEVICE_SETTINGS,SHOW_DEVICE_SETTINGS } from './actionTypes';
import { deviceSettingsSuccess, deviceSettingsError,deviceSettingsShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createDeviceSettings({ payload: { deviceSettings } }) {
    console.log('I am here');

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'deviceSettings/create', deviceSettings);
             if(response.success == false){
                
                yield put(deviceSettingsError(response.message));
            }else if(response.success == true){
                
                yield put(deviceSettingsSuccess(response.data.message));
            }

          }
    } catch (error) {
        yield put(deviceSettingsError(error));
    }
}


function* editDeviceSettings({ payload: { deviceSettings } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'deviceSettings/'+deviceSettings.id, deviceSettings);
             if(response.success == false){
                
                yield put(deviceSettingsError(response.message));
            }else if(response.success == true){
                
                yield put(deviceSettingsSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(deviceSettingsError(error));
    }
}


function* showDeviceSettings({ payload: { deviceSettings_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'deviceSettings/'+deviceSettings_id);
             yield put(deviceSettingsShow(response));
          }
          
          
    } catch (error) {
        yield put(deviceSettingsError(error));
    }
}

export function* watchDeviceSettings() {
    yield takeEvery(EDIT_DEVICE_SETTINGS, editDeviceSettings)
    yield takeEvery(CREATE_DEVICE_SETTINGS, createDeviceSettings)
    yield takeEvery(SHOW_DEVICE_SETTINGS, showDeviceSettings)
}

function* DeviceSettingsSaga() {
    yield all([
        fork(watchDeviceSettings),
    ]);
}

export default DeviceSettingsSaga;