import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PARK_DEVICE_SETTINGS,DEVICE_SETTINGS_SUCCESS,EDIT_PARK_DEVICE_SETTINGS,SHOW_DEVICE_SETTINGS } from './actionTypes';
import { deviceSettingsSuccess, deviceSettingsError,deviceSettingsShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createParkDeviceSettings({ payload: { ParkdeviceSettings } }) {
    console.log('I am here');

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'ParkDeviceAuthorizationSettings/create', ParkdeviceSettings);
             if(response.success == false){
                
                yield put(deviceSettingsError(response.message));
            }else if(response.success == true){
                
                yield put(deviceSettingsSuccess(response.data.message));
            }

          }
    } catch (error) {
        yield put(deviceSettingsError(error));
    }
}


function* editParkDeviceSettings({ payload: { park_Device_Settings } }) { 
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'ParkDeviceAuthorizationSettings/'+park_Device_Settings.id, park_Device_Settings);
             if(response.success == false){
                
                yield put(deviceSettingsError(response.message));
            }else if(response.success == true){
                
                yield put(deviceSettingsSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(deviceSettingsError(error));
    }
}


function* showDeviceSettings({ payload: { deviceSettings_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'deviceSettings/'+deviceSettings_id);
             yield put(deviceSettingsShow(response));
          }
          
          
    } catch (error) {
        yield put(deviceSettingsError(error));
    }
}

export function* watchParkDeviceSettings() {
    yield takeEvery(EDIT_PARK_DEVICE_SETTINGS, editParkDeviceSettings)
    yield takeEvery(CREATE_PARK_DEVICE_SETTINGS, createParkDeviceSettings)
    yield takeEvery(SHOW_DEVICE_SETTINGS, showDeviceSettings)
}

function* ParkDeviceSettingsSaga() {
    yield all([
        fork(watchParkDeviceSettings),
    ]);
}

export default ParkDeviceSettingsSaga;