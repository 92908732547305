import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { EDIT_PROFILE,SHOW_PROFILE } from './actionTypes';
import { profileSuccess, profileError,profileShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtProfile, postJwtProfile  } from '../../../helpers/parking_login_helper';
 

function* editProfile({ payload: { user } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtProfile, 'users/id/'+user.user_id, { 
                    user_name : user.user_name,
                    email : user.email,
                    user_id : user.user_id,  
                    mobile : user.mobile,  
                    first_name : user.first_name,  
                    last_name : user.last_name,
                });
                console.log(' profile res',response)
             yield put(profileSuccess(response));
          }
          
          
    } catch (error) {
        yield put(profileError(error));
    }
}


function* showProfile({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtProfile, 'users/id/'+user_id);
             yield put(profileShow(response));
          }
          
          
    } catch (error) {
        yield put(profileError(error));
    }
}

export function* watchProfile() {
    yield takeEvery(EDIT_PROFILE, editProfile)
    yield takeEvery(SHOW_PROFILE, showProfile)
}

function* ProfileSaga() {
    yield all([
        fork(watchProfile),
    ]);
}

export default ProfileSaga;