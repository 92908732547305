import { BILL_ERROR,BILL_SUCCESS,EDIT_BILL } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const Bill = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_BILL:
            state = { ...state }
            break;
        case BILL_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case BILL_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Bill;