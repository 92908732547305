import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createCustomerVehicleMap,customerVehicleMapList,editCustomerVehicleMap,userList} from '../../../store/customerVehicleMap/actions';
import { rateTypeList,vehicleTypeList} from '../../../store/parkingRate/actions';
import { parkingSpaceNameList} from '../../../store/parkingSpaceName/actions';
import { parkingSlotList} from '../../../store/parkingSlot/actions';
import { customerList} from '../../../store/customer/actions';

class CustomerVehicleMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                cusVehicleMap: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchcustomerVehicleMapList();  
            this.fetchRateTypeList();     
            this.fetchVehicleTypeList();     
            this.fetchparkingSpaceNameList();     
            this.fetchparkingSlotList();  
            this.fetchcustomerList();  
    }

    

    fetchcustomerList = () => {
        
        customerList().then((response) => {

            this.setState({
                customerList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList : response.data.rateType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchVehicleTypeList = () => {
        vehicleTypeList().then((response) => {
            this.setState({
                vehicleTypeList : response.data.vehicleType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkingSpaceNameList = () => {
        parkingSpaceNameList().then((response) => {
            this.setState({
                parkingSpaceNameList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkingSlotList = () => {
        
        parkingSlotList().then((response) => {

            this.setState({
                parkingSlotList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchcustomerVehicleMapList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        customerVehicleMapList(options).then((response) => {
            console.log('p',response);

            this.setState({
                customerVehicleMapList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (cusVehicleMap) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.cusVehicleMap = cusVehicleMap;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createCustomerVehicleMap(values);
            this.form && this.form.reset();
            this.fetchcustomerVehicleMapList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editCustomerVehicleMap(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));

            this.fetchcustomerVehicleMapList(); 
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchcustomerVehicleMapList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchcustomerVehicleMapList();
            });
        }

    render() {
        let vehicleTypeList = [];
        let rateTypeList = [];
        let parkingSpaceNameList = [];
        let customerVehicleMapList = [];
        let parkingSlotList = [];
        let customerList = [];
        let paginator;
        if (this.state.hasOwnProperty('customerVehicleMapList')) {
            customerVehicleMapList = this.state.customerVehicleMapList.data;
            paginator = {
                'currentPage':this.state.customerVehicleMapList.current_page,
                'pagesCount':this.state.customerVehicleMapList.last_page,
                'total':this.state.customerVehicleMapList.total,
                'to':this.state.customerVehicleMapList.to,
                'from':this.state.customerVehicleMapList.from,
            }
        }

        if (this.state.hasOwnProperty('vehicleTypeList')) { vehicleTypeList = this.state.vehicleTypeList; }
        if (this.state.hasOwnProperty('rateTypeList')) { rateTypeList = this.state.rateTypeList; }
        if (this.state.hasOwnProperty('parkingSpaceNameList')) { parkingSpaceNameList = this.state.parkingSpaceNameList; }
        if (this.state.hasOwnProperty('parkingSlotList')) { parkingSlotList = this.state.parkingSlotList; }
        if (this.state.hasOwnProperty('customerList')) { customerList = this.state.customerList; }

        const {customer_vehicle_map_id,vehicle_name,vehicle_number,chesis_number,model_number,registration_number,f_vehicle_type_id,f_rate_type_id,monthly_rate,responsible_person_name,responsible_person_number,f_parking_space_name_id,f_parking_slot_id,f_customer_id,is_android} = this.state.cusVehicleMap;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Customer Vehicle Map" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="8" md="8">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Customer Vehicle Map List</CardTitle>
                                        <div className="table-responsive">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table  mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Customer</th>
                                                        <th>Vehicle Name</th>
                                                        <th>Vehicle No</th>
                                                        <th>Responsible Phone No</th>
                                                        <th>Parking Space</th>
                                                        <th>Parking Slot</th>
                                                        <th>Vehicle Type</th>
                                                        <th>Rate Type</th>
                                                        <th>Monthly Rate </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {customerVehicleMapList.map((cusVehicleMap, index) =>
                                                    <tr key={cusVehicleMap.customer_vehicle_map_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{cusVehicleMap.customer == null?cusVehicleMap.f_customer_id:cusVehicleMap.customer.customer_name_en}</td>
                                                        <td>{cusVehicleMap.vehicle_name}</td>
                                                        <td>{cusVehicleMap.vehicle_number}</td>
                                                        <td>{cusVehicleMap.responsible_person_number}</td>
                                                        <td>{cusVehicleMap.parking_space_name == null? cusVehicleMap.f_parking_space_name_id:cusVehicleMap.parking_space_name.space_name_en}</td>
                                                        <td>{cusVehicleMap.parking_slot == null? cusVehicleMap.f_parking_slot_id: cusVehicleMap.parking_slot.parking_slot_name_en}</td>
                                                        <td>{cusVehicleMap.vehicle_type == null? cusVehicleMap.f_vehicle_type_id: cusVehicleMap.vehicle_type.vehicle_type_name_en}</td>
                                                        <td>{cusVehicleMap.rate_type == null? cusVehicleMap.f_rate_type_id: cusVehicleMap.rate_type.rate_name_en }</td>
                                                        <td>{cusVehicleMap.monthly_rate}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(cusVehicleMap) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{vehicle_name}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Customer Vehicle Map Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                
                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Customer" name="f_customer_id" id="f_customer_id" value={f_customer_id} >
                                                            <option defaultValue>Select</option>
                                                            {customerList.map((customer, index) =>
                                                                <option value={customer.customer_id}>{customer.customer_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                 <div className="col-md-12">
                                                 <AvField className="form-control" type="text" label="Vehicle Name"  id="vehicle_name" name="vehicle_name" value={vehicle_name} />
                                                     <AvField className="form-control" type="hidden"   id="customer_vehicle_map_id" name="customer_vehicle_map_id" value={customer_vehicle_map_id} />
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Vehicle number "  type="text" name="vehicle_number" id="vehicle_number" value={vehicle_number} />
                                                 </div>

                                                 <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Vehicle Type" name="f_vehicle_type_id" id="f_vehicle_type_id" value={f_vehicle_type_id} >
                                                            <option defaultValue>Select</option>
                                                            {vehicleTypeList.map((vehicle, index) =>
                                                                <option value={vehicle.id}>{vehicle.vehicle_type_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Model No "  type="text" name="model_number" id="model_number" value={model_number} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Chesis Number "  type="text" name="chesis_number" id="chesis_number" value={chesis_number} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Registration No "  type="text" name="registration_number" id="registration_number" value={registration_number} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Responsible Person Name  "  type="text" name="responsible_person_name" id="responsible_person_name" value={responsible_person_name} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Responsible Person Number "  type="number" name="responsible_person_number" id="responsible_person_number" value={responsible_person_number} />
                                                 </div>
                                            
                                             
                                                 <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Rate Type" name="f_rate_type_id" id="f_rate_type_id" value={f_rate_type_id}>
                                                            <option defaultValue>Select</option>
                                                            {rateTypeList.map((rtype, index) =>
                                                                <option value={rtype.id}>{rtype.rate_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                    
                                                

                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Parking Space" name="f_parking_space_name_id" id="f_parking_space_name_id" value={f_parking_space_name_id} >
                                                            <option defaultValue>Select</option>
                                                            {parkingSpaceNameList.map((space, index) =>
                                                                <option value={space.id}>{space.space_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>
                                                
                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="parking Slot" name="f_parking_slot_id" id="f_parking_slot_id" value={f_parking_slot_id} >
                                                            <option defaultValue>Select</option>
                                                            {parkingSlotList.map((slot, index) =>
                                                                <option value={slot.id}>{slot.parking_slot_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <div className="col-md-12">
                                                     <AvField className="form-control" label="Monthly Rate  "  type="number" name="monthly_rate" id="monthly_rate" value={monthly_rate} />
                                                 </div>
                                                 
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="4" md="4">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Customer Vehicle Map</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Customer" name="f_customer_id" id="f_customer_id" >
                                                            <option defaultValue>Select</option>
                                                            {customerList.map((customer, index) =>
                                                                <option value={customer.customer_id}>{customer.customer_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Vehicle Name"  id="vehicle_name" name="vehicle_name" />
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Vehicle number "  type="text" name="vehicle_number" id="vehicle_number" />
                                                 </div>

                                                 <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Vehicle Type" name="f_vehicle_type_id" id="f_vehicle_type_id">
                                                            <option defaultValue>Select</option>
                                                            {vehicleTypeList.map((vehicle, index) =>
                                                                <option value={vehicle.id}>{vehicle.vehicle_type_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Model No "  type="text" name="model_number" id="model_number" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Chesis Number "  type="text" name="chesis_number" id="chesis_number" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Registration No "  type="text" name="registration_number" id="registration_number" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Responsible Person Name  "  type="text" name="responsible_person_name" id="responsible_person_name" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Responsible Person Number "  type="number" name="responsible_person_number" id="responsible_person_number" />
                                                 </div>
                                            
                                             
                                                 <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Rate Type" name="f_rate_type_id" id="f_rate_type_id">
                                                            <option defaultValue>Select</option>
                                                            {rateTypeList.map((rtype, index) =>
                                                                <option value={rtype.id}>{rtype.rate_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                    
                                                

                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Parking Space" name="f_parking_space_name_id" id="f_parking_space_name_id">
                                                            <option defaultValue>Select</option>
                                                            {parkingSpaceNameList.map((space, index) =>
                                                                <option value={space.id}>{space.space_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>
                                                
                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="parking Slot" name="f_parking_slot_id" id="f_parking_slot_id">
                                                            <option defaultValue>Select</option>
                                                            {parkingSlotList.map((slot, index) =>
                                                                <option value={slot.id}>{slot.parking_slot_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <div className="col-md-12">
                                                     <AvField className="form-control" label="Monthly Rate  "  type="number" name="monthly_rate" id="monthly_rate" />
                                                 </div>

                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.CustomerVehicleMap;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createCustomerVehicleMap,editCustomerVehicleMap })(CustomerVehicleMapping));
