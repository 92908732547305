import { ROLE_ERROR,ROLE_SUCCESS,EDIT_ROLE,SHOW_ROLE,CREATE_ROLE } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createRole = (role) => {
    return {
        type: CREATE_ROLE,
        payload: { role }
    }
}

export const showRole = (role_id) => {
    return {
        type: SHOW_ROLE,
        payload: { role_id }
    }
}

export const editRole = (role) => {
    return {
        type: EDIT_ROLE,
        payload: { role }
    }
}



export const roleSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: ROLE_SUCCESS,
        payload: msg
    }
}


export function roleShow(role_id){
    return getApi().get('user/role/'+role_id)
}


export function roleList(params){
    return getApi().get('user/role/list',{
        params: params
    })
}

export function getPermissionGroupList(params) {
    return getApi().get('user/permission/group/list', {
        params: params
    })
}

export function getPermissionTotal(params) {
    return getApi().get('user/permission/total', {
        params: params
    })
}

export const roleError = (error) => {
    return {
        type: ROLE_ERROR,
        payload: error
    }
}
