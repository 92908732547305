import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,FormText } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AsyncSelect from "react-select/async";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createExpenseGroupTypeMap,expenseGroupTypeMapList,editExpenseGroupTypeMap,expenseGroupSearchList,expenseTypeSearchList} from '../../../store/expenseGroupTypeMap/actions';
import { createExpenseGroup,expenseGroupList} from '../../../store/expenseGroup/actions';
import { createExpenseType,expenseTypeList} from '../../../store/expenseType/actions';
 
class ExpenseGroupTypeMaps extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,
                expenseTypeList: [],  
                expenseGroupList: [],  
                modal_scroll: false,
                expenseGroupTypeMap: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
        this.fetchexpenseGroupTypeMapList();  
        this.fetchexpenseGroupList();  
        this.fetchExpenseTypeList();  
    }

    
    fetchexpenseGroupTypeMapList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        expenseGroupTypeMapList(options).then((response) => {

            this.setState({
                expenseGroupTypeMapList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    
    fetchExpenseTypeList = () => {
        let options = {
            page: this.state.page,
            per_page: 10,
            search: this.state.search
        }
        expenseTypeList(options).then((response) => {

            var item = [];

            response.data.data.forEach(element => {
                item.push({'label': element.expense_type_name, 'value': element.expense_type_id})
            });
            this.setState({
                expenseTypeList : item, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchexpenseGroupList = () => {
        let options = {
            page: this.state.page,
            per_page: 10,
            search: this.state.search
        }
        expenseGroupList(options).then((response) => {

            var item = [];

            response.data.data.forEach(element => {
                item.push({'label': element.expense_group_name, 'value': element.expense_group_id})
            });
            this.setState({
                expenseGroupList : item, 
            });
            
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (expenseGroupTypeMap) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.expenseGroupTypeMap = expenseGroupTypeMap;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createExpenseGroupTypeMap(values);
            this.form && this.form.reset();
            this.fetchexpenseGroupTypeMapList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editExpenseGroupTypeMap(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));

            this.fetchexpenseGroupTypeMapList(); 
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchexpenseGroupTypeMapList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchexpenseGroupTypeMapList();
            });
        }

        loadGroupOptions = (inputValue, callback) => {

            expenseGroupSearchList(inputValue).then((response) => {
    
                const requestResults = response.data;
              
                callback(requestResults);
            })
            .catch((err) => {
                console.log(err);
            })
            
          }

          loadTypeOptions = (inputValue, callback) => {

            expenseTypeSearchList(inputValue).then((response) => {
    
                const requestResults = response.data;
              
                callback(requestResults);
            })
            .catch((err) => {
                console.log(err);
            })
            
          }

          handleGroupSearch( group){
            this.setState({
                f_expense_group_id: group.value,
            })
            this.loadTypeOptions();
        }

        handleTypeSearch( type){
            this.setState({
                f_expense_type_id: type.value,
            })
        }

        handleEditGroupSearch( group){
            this.state.expenseGroupTypeMap.f_expense_group_id = group.value;
        }

        handleEditTypeSearch( type){
            this.state.expenseGroupTypeMap.f_expense_type_id = type.value;
        }

    render() {
      
        let expenseGroupTypeMapList = [];
        
        let paginator;
        if (this.state.hasOwnProperty('expenseGroupTypeMapList')) {
            expenseGroupTypeMapList = this.state.expenseGroupTypeMapList.data;
            paginator = {
                'currentPage':this.state.expenseGroupTypeMapList.current_page,
                'pagesCount':this.state.expenseGroupTypeMapList.last_page,
                'total':this.state.expenseGroupTypeMapList.total,
                'to':this.state.expenseGroupTypeMapList.to,
                'from':this.state.expenseGroupTypeMapList.from,
            }
        }

        const {expense_group_type_map_id,f_expense_type_id,f_expense_group_id,expense_group,expense_type} = this.state.expenseGroupTypeMap;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Expense Group Type Map" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="8" md="8">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Expense Group Type Map List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table  mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Expense Type </th>
                                                        <th>Expense Group</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {expenseGroupTypeMapList.map((expenseGroupTypeMap, index) =>
                                                    <tr key={expenseGroupTypeMap.expense_group_type_map_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{expenseGroupTypeMap.expense_type.expense_type_name}</td>
                                                        <td>{expenseGroupTypeMap.expense_group.expense_group_name}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(expenseGroupTypeMap) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">EDIT EXPENSE GROUP TYPE MAP</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Expense Group Type Map Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                
                                                <div className="col-md-12">
                                                        <label>Expense Group</label>
                                                        <AsyncSelect 
                                                            isClearable={true}
                                                            cacheOptions
                                                            isSearchable={true}
                                                            defaultOptions={this.state.expenseGroupList}
                                                            defaultValue={{ label: (expense_group == null)?'':expense_group.expense_group_name, value: f_expense_type_id }}
                                                            loadOptions={this.loadGroupOptions}
                                                            onChange={(value) => this.handleEditGroupSearch(value)}
                                                        />
                                                        <AvField type="hidden" name="f_expense_type_id" id="f_expense_type_id" value={f_expense_type_id} />
                                                        <AvField type="hidden" name="expense_group_type_map_id" id="expense_group_type_map_id" value={expense_group_type_map_id} />
                                                 </div>

                                                 <div className="col-md-12">
                                                        <label>Expense Type</label>
                                                        <AsyncSelect 
                                                            isClearable={true}
                                                            cacheOptions
                                                            isSearchable={true}
                                                            defaultOptions={this.state.expenseTypeList}
                                                            defaultValue={{ label: (expense_type == null)?'':expense_type.expense_type_name, value: f_expense_group_id }}
                                                            loadOptions={this.loadTypeOptions}
                                                            onChange={(value) => this.handleEditTypeSearch(value)}
                                                        />
                                                        <AvField type="hidden" name="f_expense_group_id" id="f_expense_group_id" value={f_expense_group_id}/>
                                                 </div>

                                                 
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="4" md="4">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create New Expense Group Type Map</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                
                                             
                                                 <div className="col-md-12">
                                                        <label>Expense Group</label>
                                                        <AsyncSelect 
                                                            isClearable={true}
                                                            cacheOptions
                                                            isSearchable={true}
                                                            defaultOptions={this.state.expenseGroupList}
                                                            loadOptions={this.loadGroupOptions}
                                                            onChange={(value) => this.handleGroupSearch(value)}
                                                        />
                                                        <FormText style={{background: 'yellow'}}>Search By Expense Group Name </FormText>
                                                        <AvField type="hidden" name="f_expense_group_id" id="f_expense_group_id" value={this.state.f_expense_group_id}/>
                                                 </div>

                                                 <div className="col-md-12">
                                                        <label>Expense Type</label>
                                                        <AsyncSelect 
                                                            isClearable={true}
                                                            cacheOptions
                                                            isSearchable={true}
                                                            defaultOptions={this.state.expenseTypeList}
                                                            loadOptions={this.loadTypeOptions}
                                                            onChange={(value) => this.handleTypeSearch(value)}
                                                        />
                                                        <FormText style={{background: 'yellow'}}>Search By Expense Type Name </FormText>
                                                        <AvField type="hidden" name="f_expense_type_id" id="f_expense_type_id" value={this.state.f_expense_type_id} />

                                                 </div>

                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ExpenseGroupTypeMap;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createExpenseGroupTypeMap,editExpenseGroupTypeMap })(ExpenseGroupTypeMaps));
