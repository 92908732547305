import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,FormGroup,CardHeader } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AsyncSelect from "react-select/async";
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createBill,searchByBillData,billShow,customerSearchList,customerVehicleList,companyInfo} from '../../../store/bill/actions';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CSVLink, CSVDownload } from "react-csv";

class BillDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                customer_name: '',
                comission: 0,
                selectedVehicle: [],
                customer: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
        if (this.props.match.params.id) {
            this.fetchbillShow(this.props.match.params.id);
        }
        this.fetchCompanyInfo();  
    }


    fetchCompanyInfo = () => {

        companyInfo().then((response) => {
            this.setState({
                company: response.data
            })
        })
        .catch((err) => {
            console.log(err);
        })
        
      }
    
    fetchbillShow = (bill_id) => {
        billShow(bill_id).then((response) => {

            this.setState({
                billShow :response.data.billMaster, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (customer) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.customer = customer;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createBill(values);
            alert('Bill Created Successfully');
            // window.location.reload();    
        }

        printDiv = () => {
            var content = document.getElementById('example').innerHTML;
            var title = document.title;
            var printWindow = window.open("", "_blank", "width=800,height=600");
            printWindow.document.open();
            printWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous"><style>footer { font-size: 10px; text-align: center;} @media print { footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width: 210mm; height: 297mm; }.table-bordered th, .table-bordered td {border: 1px solid #181b1e !important;}.table th, .table td {border-top: 1px solid #181b1e !important;}</style></head><body>');
            printWindow.document.write(content);
            printWindow.document.write('<footer>Printed at: '+ new Date().toLocaleString() + '</footer></body></html>');
            printWindow.document.close();
            printWindow.focus();
            setTimeout(function () {
              printWindow.print();
              printWindow.close();
            }, 1000);
          }

          downloadPDF = () => {
            const input = document.getElementById('example');
            html2canvas(input,{ height: '700'})
              .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                orientation: 'landscape',
                });
                
                const imgProps= pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth() - 10;
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width + 5;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

                
                pdf.save('download.pdf');
              })
            ;                                         
          }

        
       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchbillList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchbillList();
            });
        }

    render() {
      
        let billShow = [];
        let company_name = '' , address = '' , mobile = '';
        let customer = '' , customer_address = '', bill_generate_date = '' , bill_generate_month ='' ;

        let billDetails= [];


        if (this.state.hasOwnProperty('company')) {
            company_name = this.state.company.name;
            address = this.state.company.address;
            mobile = this.state.company.mobile;
        }

        if (this.state.hasOwnProperty('billShow')) { 
            billShow = this.state.billShow; 
            customer = billShow.customer.customer_name_en;
            customer_address = billShow.customer.address;
            bill_generate_date = billShow.bill_generate_date;
            bill_generate_month = billShow.bill_generate_month;
            billDetails = billShow.bill_details;
        }


      

        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        

        //  csv download start
      const csvData  = [];
      const report_header  = ["", "", " "," ","Bill Details", " "," ",];
      const report_customer   = ["", ""," ","Customer ", customer, ""];
      const report_customer_address   = ["", ""," ","Address ", customer_address, ""];
      const header  = ["SL","Vehicle Name", "Vehicle No", "Responsible Phone No","Parking Space","Parking Slot","Vehicle Type","Rate Type","Monthly Rate"];
      csvData.push(report_header)
      csvData.push(report_customer)
      csvData.push(report_customer_address)
      csvData.push(header)
      let i = 0;
      billDetails.map(function(monthValue){
        i++;
        let temp_array = [];
        temp_array.push(i);
        temp_array.push(monthValue.customer_vehicle_map.vehicle_name);
        temp_array.push(monthValue.customer_vehicle_map.vehicle_number);
        temp_array.push(monthValue.customer_vehicle_map.responsible_person_number);
        temp_array.push(monthValue.customer_vehicle_map.parking_space_name == null? monthValue.customer_vehicle_map.f_parking_space_name_id:monthValue.customer_vehicle_map.parking_space_name.space_name_en);
        temp_array.push(monthValue.customer_vehicle_map.parking_slot == null? monthValue.customer_vehicle_map.f_parking_slot_id: monthValue.customer_vehicle_map.parking_slot.parking_slot_name_en);
        temp_array.push(monthValue.customer_vehicle_map.vehicle_type == null? monthValue.customer_vehicle_map.f_vehicle_type_id: monthValue.customer_vehicle_map.vehicle_type.vehicle_type_name_en);
        temp_array.push(monthValue.customer_vehicle_map.rate_type == null? monthValue.customer_vehicle_map.f_rate_type_id: monthValue.customer_vehicle_map.rate_type.rate_name_en);
        temp_array.push(monthValue.bill_amount);
        csvData.push(temp_array);
      });
      const commision = ["","","","","","","","Comission",billShow.total_commision];
      const total_bill = ["","","","","","","","Total Bill",(billShow.total_bill*1 - billShow.total_commision*1)];
      csvData.push(commision);
      csvData.push(total_bill);

      var day =new Date().getDate();
      var month = new Date().getMonth()+1;
      var year = new Date().getFullYear();
      var fileName = day+"-"+month+"-"+year+"-bill-details.csv";
    //  csv download end
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Bill Details" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader>
                                        <div className="row">
                                            <div className="col-2 col-lg-2 offset-lg-10" align="center">
                                            <CSVLink filename={fileName} data={csvData} size="sm" color="blue" className="btn-sm btn-danger mr-2 pull-right">EXCEL</CSVLink>
                                            <button size="lg"  className="btn-sm btn-warning mr-2 pull-right" onClick={() => this.downloadPDF()}>PDF</button>
                                            <button size="lg"  className="btn-sm btn-primary mr-2 pull-right" onClick={() => this.printDiv()}>PRINT</button>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                            <div id="example" className="form-controll">

                                                    <div className="row">
                                                        <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                            <h2 className="text-center"><b>{company_name}</b></h2>
                                                            <span className="text-center">{address}</span><br></br>
                                                            <span className="text-center">Phone: {mobile}</span>
                                                            <br></br>
                                                            <h4 className="text-center"><u>{customer}</u></h4>
                                                            <h6 className="text-center">{customer_address}</h6>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4">
                                                                <h6><u>BILL DETAILS</u></h6>
                                                        </div>
                                                        <div className="col-4">
                                                            <h3 className="text-center" style={{border: 'solid'}}>{ new Date(bill_generate_month).toLocaleString('default', { month: 'long' })} { new Date(bill_generate_month).getFullYear()}</h3>
                                                        </div>
                                                        <div className="col-4" style={{textAlign: 'right'}}>
                                                            <p>Date: {new Date(bill_generate_date).toLocaleDateString()}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-12">
                                                        <Table bordered responsive style={{borderColor: 'black'}}>
                                                            <thead>
                                                                <tr>
                                                                <th>Vehicle Name</th>
                                                                <th>Vehicle No</th>
                                                                <th>Responsible Phone No</th>
                                                                <th>Parking Space</th>
                                                                <th>Parking Slot</th>
                                                                <th>Vehicle Type</th>
                                                                <th>Rate Type</th>
                                                                <th>Monthly Rate </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {billDetails.map((monthValue, index) =>
                                                                    <tr>
                                                                        <td>{monthValue.customer_vehicle_map.vehicle_name}</td>
                                                                        <td>{monthValue.customer_vehicle_map.vehicle_number}</td>
                                                                        <td>{monthValue.customer_vehicle_map.responsible_person_number}</td>
                                                                        <td>{monthValue.customer_vehicle_map.parking_space_name == null? monthValue.customer_vehicle_map.f_parking_space_name_id:monthValue.customer_vehicle_map.parking_space_name.space_name_en}</td>
                                                                        <td>{monthValue.customer_vehicle_map.parking_slot == null? monthValue.customer_vehicle_map.f_parking_slot_id: monthValue.customer_vehicle_map.parking_slot.parking_slot_name_en}</td>
                                                                        <td>{monthValue.customer_vehicle_map.vehicle_type == null? monthValue.customer_vehicle_map.f_vehicle_type_id: monthValue.customer_vehicle_map.vehicle_type.vehicle_type_name_en}</td>
                                                                        <td>{monthValue.customer_vehicle_map.rate_type == null? monthValue.customer_vehicle_map.f_rate_type_id: monthValue.customer_vehicle_map.rate_type.rate_name_en }</td>
                                                                        <td>{monthValue.bill_amount}</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan={7} style={{textAlign: 'end'}}><b>Comission</b></td>
                                                                    <td>{billShow.total_commision}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={7} style={{textAlign: 'end'}}><b>Total Bill</b></td>
                                                                    <td>{billShow.total_bill*1 - billShow.total_commision*1}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        </div>

                                                    </div>
                                            </div>
                                                    
                                                
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingSlot;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createBill })(BillDetails));
