import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PARKING_RATE,PARKING_RATE_SUCCESS,EDIT_PARKING_RATE,SHOW_PARKING_RATE } from './actionTypes';
import { parkingRateInfoSuccess, parkingRateInfoError,parkingRateInfoShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createParkingRateInfo({ payload: { parkingRateInfo } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'parkingRateInfo/create', parkingRateInfo);
                if(response.success == false){
                    
                    yield put(parkingRateInfoError(response.message));
                }else if(response.success == true){
                    
                    yield put(parkingRateInfoSuccess(response.data.message));
                }
          }
    } catch (error) {
        yield put(parkingRateInfoError(error));
    }
}


function* editParkingRateInfo({ payload: { parkingRateInfo } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'parkingRateInfo/'+parkingRateInfo.id, parkingRateInfo);
             if(response.success == false){
                
                yield put(parkingRateInfoError(response.message));
            }else if(response.success == true){
                
                yield put(parkingRateInfoSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(parkingRateInfoError(error));
    }
}


function* showParkingRateInfo({ payload: { parkingRateInfo_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'parkingRateInfo/'+parkingRateInfo_id);
             yield put(parkingRateInfoShow(response));
          }
          
          
    } catch (error) {
        yield put(parkingRateInfoError(error));
    }
}

export function* watchParkingRateInfo() {
    yield takeEvery(EDIT_PARKING_RATE, editParkingRateInfo)
    yield takeEvery(CREATE_PARKING_RATE, createParkingRateInfo)
    yield takeEvery(SHOW_PARKING_RATE, showParkingRateInfo)
}

function* ParkingRateInfoSaga() {
    yield all([
        fork(watchParkingRateInfo),
    ]);
}

export default ParkingRateInfoSaga;