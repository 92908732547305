import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createParkingRateInfo,parkingRateInfoList,editParkingRateInfo,rateTypeList,vehicleTypeList} from '../../../store/parkingRate/actions';
import { parkingSpaceNameList} from '../../../store/parkingSpaceName/actions';

class ParkingRateInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                rate: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchparkingRateInfoList();     
            this.fetchRateTypeList();     
            this.fetchVehicleTypeList();     
            this.fetchparkingSpaceNameList();     
    }

    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList : response.data.rateType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchVehicleTypeList = () => {
        vehicleTypeList().then((response) => {
            this.setState({
                vehicleTypeList : response.data.vehicleType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkingSpaceNameList = () => {
        parkingSpaceNameList().then((response) => {
            this.setState({
                parkingSpaceNameList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkingRateInfoList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        parkingRateInfoList(options).then((response) => {
            this.setState({
                parkingRateInfoList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (rate) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.rate = rate;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {     
            values = Object.entries(values).reduce((a,[k,v]) => (v === null ||v === '' ? a : (a[k]=v, a)), {}); 
            this.props.createParkingRateInfo(values);
            this.form && this.form.reset();
            this.fetchparkingRateInfoList();   
        }

       
       handleEditSubmit(event, values) { 
            values = Object.entries(values).reduce((a,[k,v]) => (v === null ||v === '' ? a : (a[k]=v, a)), {}); 
            this.props.editParkingRateInfo(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchparkingRateInfoList();   
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchparkingRateInfoList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchparkingRateInfoList();
            });
        }

    render() {
        let vehicleTypeList = [];
        let rateTypeList = [];
        let parkingSpaceNameList = [];
        let parkingRateInfoList = [];
        let paginator;
        if (this.state.hasOwnProperty('parkingRateInfoList')) {
            parkingRateInfoList = this.state.parkingRateInfoList.data;
            paginator = {
                'currentPage':this.state.parkingRateInfoList.currentPage,
                'pagesCount':this.state.parkingRateInfoList.pagesCount,
                'total':this.state.parkingRateInfoList.total,
                'to':this.state.parkingRateInfoList.to,
                'from':this.state.parkingRateInfoList.from,
            }
        }

        if (this.state.hasOwnProperty('vehicleTypeList')) { vehicleTypeList = this.state.vehicleTypeList; }
        if (this.state.hasOwnProperty('rateTypeList')) { rateTypeList = this.state.rateTypeList; }
        if (this.state.hasOwnProperty('parkingSpaceNameList')) { parkingSpaceNameList = this.state.parkingSpaceNameList; }

        const {id,rate_info_name_en,rate_info_name_bn,f_parking_space_name_id,f_rate_type_id,f_vehicle_type_id,fixed_rate,per_hour_rate,entry_first_hour_rate} = this.state.rate;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Parking Rate" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Parking Rate List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0 table-responsive">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Rate Name</th>
                                                        <th>Rate Type</th>
                                                        <th>Parking Space</th>
                                                        <th>Vehicle Type</th>
                                                        <th>First Hour Rate</th>
                                                        <th>Per Hour Rate</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {parkingRateInfoList.map((rate, index) =>
                                                    <tr key={rate.id .toString()}>
                                                    <td scope="row">{index+1}</td>
                                                    <td>{rate.rate_info_name_en}</td>
                                                    <td>{rate.rate_type == null? rate.f_rate_type_id: rate.rate_type.rate_name_en }</td>
                                                    <td>{rate.parking_space_name == null? rate.f_parking_space_name_id:rate.parking_space_name.space_name_en}</td>
                                                    <td>{rate.vehicle_type == null? rate.f_vehicle_type_id: rate.vehicle_type.vehicle_type_name_en}</td>
                                                    <td>{rate.entry_first_hour_rate}</td>
                                                    <td>{rate.per_hour_rate}</td>
                                                    <td><button type="button"
                                                           className="btn btn-sm btn-primary waves-effect waves-light"
                                                           onClick={() => { this.tog_scroll(rate) }} 
                                                           data-toggle="modal"
                                                         >Edit</button>
                                                        </td>
                                                   </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{rate_info_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Rate Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Rate Name"  id="rate_info_name_en" name="rate_info_name_en" value={rate_info_name_en} required />
                                                     <AvField name="id" id="id" value={id}  type="hidden"  />
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Rate  Name (Bangla)"  type="text" name="rate_info_name_bn" id="rate_info_name_bn" value={rate_info_name_bn} />
                                                 </div>
                                            
                                             
                                                 <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Rate Type" name="f_rate_type_id" id="f_rate_type_id" value={f_rate_type_id} required>
                                                            <option defaultValue>Select</option>
                                                            {rateTypeList.map((rtype, index) =>
                                                                <option value={rtype.id}>{rtype.rate_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Parking Space" name="f_parking_space_name_id" id="f_parking_space_name_id" value={f_parking_space_name_id} required>
                                                            <option defaultValue>Select</option>
                                                            {parkingSpaceNameList.map((space, index) =>
                                                                <option value={space.id}>{space.space_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Vehicle Type" name="f_vehicle_type_id" id="f_vehicle_type_id" value={f_vehicle_type_id} required >
                                                            <option defaultValue>Select</option>
                                                            {vehicleTypeList.map((vehicle, index) =>
                                                                <option value={vehicle.id}>{vehicle.vehicle_type_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <Row>
                                                <div className="col-md-4" style={{paddingLeft: '25px'}}>
                                                     <AvField className="form-control" label="First Hour Rate"  type="number" name="entry_first_hour_rate" id="entry_first_hour_rate" value = {entry_first_hour_rate} required />
                                                 </div>

                                                 <div className="col-md-4">
                                                     <AvField className="form-control" label="Per Hour Rate"  type="number" name="per_hour_rate" id="per_hour_rate" value={per_hour_rate} required />
                                                 </div>

                                                 <div className="col-md-4">
                                                     <AvField className="form-control" label="Fixed Rate"  type="number" name="fixed_rate" id="fixed_rate" value={fixed_rate} />
                                                 </div>
                                                 </Row>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Parking Rate</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Rate Name"  id="rate_info_name_en" name="rate_info_name_en" required />
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Rate  Name (Bangla)"  type="text" name="rate_info_name_bn" id="rate_info_name_bn" />
                                                 </div>
                                            
                                             
                                                 <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Rate Type" name="f_rate_type_id" id="f_rate_type_id" required>
                                                                <option defaultValue>Select</option>
                                                            {rateTypeList.map((rtype, index) =>
                                                                <option value={rtype.id}>{rtype.rate_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Parking Space" name="f_parking_space_name_id" id="f_parking_space_name_id" required>
                                                            <option defaultValue>Select</option>
                                                            {parkingSpaceNameList.map((space, index) =>
                                                                <option value={space.id}>{space.space_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Vehicle Type" name="f_vehicle_type_id" id="f_vehicle_type_id" required >
                                                            <option defaultValue>Select</option>
                                                            {vehicleTypeList.map((vehicle, index) =>
                                                                <option value={vehicle.id}>{vehicle.vehicle_type_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <Row>
                                                <div className="col-md-4" style={{paddingLeft: '25px'}}>
                                                     <AvField className="form-control" label="First Hour Rate"  type="number" name="entry_first_hour_rate" id="entry_first_hour_rate" required />
                                                 </div>

                                                 <div className="col-md-4">
                                                     <AvField className="form-control" label="Per Hour Rate"  type="number" name="per_hour_rate" id="per_hour_rate" required />
                                                 </div>

                                                 <div className="col-md-4">
                                                     <AvField className="form-control" label="Fixed Rate"  type="number" name="fixed_rate" id="fixed_rate"  />
                                                 </div>
                                                 </Row>
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingRate;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createParkingRateInfo,editParkingRateInfo })(ParkingRateInfo));
