import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,FormText } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AsyncSelect from "react-select/async";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createExpenseTransaction,expenseTransactionList,editExpenseTransaction,expenseGroupSearchList,expenseTypeByGroupSearchList} from '../../../store/expenseTransaction/actions';
import { createExpenseGroup,expenseGroupList} from '../../../store/expenseGroup/actions';
 
class ExpenseTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                expenseGroupList: [],
                expenseTypeList: [],
                expenseDate: new Date().toISOString().split('T')[0],
                expenseTransaction: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
        this.fetchexpenseTransactionList();  
        this.fetchexpenseGroupList();  
    }

    
    fetchexpenseTransactionList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        expenseTransactionList(options).then((response) => {

            this.setState({
                expenseTransactionList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }


    fetchexpenseGroupList = () => {
        let options = {
            page: this.state.page,
            per_page: 10,
            search: this.state.search
        }
        expenseGroupList(options).then((response) => {

            var item = [];

            response.data.data.forEach(element => {
                item.push({'label': element.expense_group_name, 'value': element.expense_group_id})
            });
            this.setState({
                expenseGroupList : item, 
            });
            
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (expenseTransaction) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.expenseTransaction = expenseTransaction;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) { 
            if(values.f_expense_group_type_map_id != null && values.f_expense_group_type_map_id != undefined ){
                this.props.createExpenseTransaction(values);
                this.form && this.form.reset();
                this.fetchexpenseTransactionList();     
            } else{
                alert('Please Select Expense Group / Type!')
            }        
        }

       
       handleEditSubmit(event, values) {  
            this.props.editExpenseTransaction(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));

            this.fetchexpenseTransactionList(); 
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchexpenseTransactionList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchexpenseTransactionList();
            });
        }

        loadGroupOptions = (inputValue, callback) => {

            expenseGroupSearchList(inputValue).then((response) => {
    
                const requestResults = response.data;
              
                callback(requestResults);
            })
            .catch((err) => {
                console.log(err);
            })
            
          }

          loadTypeOptions = (inputValue, callback) => {

            expenseTypeByGroupSearchList(inputValue,{f_expense_group_id: this.state.f_expense_group_id}).then((response) => {
    
                const requestResults = response.data;
              
                callback(requestResults);
            })
            .catch((err) => {
                console.log(err);
            })
            
          }

        handleGroupSearch( group){
            this.setState({
                f_expense_group_id: (group == null)?'':group.value,
            })
            this.fetchMapTypeList((group == null)?'':group.value);
        }

        handleTypeSearch( type){
            this.setState({
                f_expense_group_type_map_id: (type == null)?'':type.value,
                f_expense_type_no: (type == null)?'':type.expense_type_id,
            })
        }

        handleEditGroupSearch( group){
            this.state.expenseTransaction.f_expense_group_id = (group == null)?'':group.value;
            this.fetchMapTypeList((group == null)?'':group.value);
        }

        handleEditTypeSearch( type){
            this.state.expenseTransaction.f_expense_type_no = (type == null)?'':type.value;
            this.state.expenseTransaction.f_expense_group_type_map_id = (type == null)?'':type.expense_type_id;
        }

        fetchMapTypeList=(expense_group_id) => {
            expenseTypeByGroupSearchList(0,{f_expense_group_id: expense_group_id}).then((response) => {
                this.setState({
                    expenseTypeList : response.data, 
                });              
            })
            .catch((err) => {
                console.log(err);
            })
        }

    render() {
      
        let expenseTransactionList = [];
        
        let paginator;
        if (this.state.hasOwnProperty('expenseTransactionList')) {
            expenseTransactionList = this.state.expenseTransactionList.data;
            paginator = {
                'currentPage':this.state.expenseTransactionList.current_page,
                'pagesCount':this.state.expenseTransactionList.last_page,
                'total':this.state.expenseTransactionList.total,
                'to':this.state.expenseTransactionList.to,
                'from':this.state.expenseTransactionList.from,
            }
        }

        const {expense_transaction_no,f_expense_type_no,f_expense_group_id,expense_group,expense_type,comment,expense_date,f_deposit_type_no,expense_amount,f_expense_group_type_map_id} = this.state.expenseTransaction;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Expense Transaction" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="7" md="7">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Expense Transaction List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table  mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Expense Date</th>
                                                        <th>Expense Type </th>
                                                        <th>Expense Amount</th>
                                                        <th>Payment Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {expenseTransactionList.map((expenseTransaction, index) =>
                                                    <tr key={expenseTransaction.expense_transaction_no .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{expenseTransaction.expense_date}</td>
                                                        <td>{expenseTransaction.expense_type.expense_type_name}</td>
                                                        <td>{expenseTransaction.f_deposit_type_no ==1?'Bank':'Cash'}</td>
                                                        <td>{expenseTransaction.expense_amount}</td>

                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(expenseTransaction) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">Edit Expense Transaction</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Expense Transaction Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <div className="col-md-12">
                                                     <AvField className="form-control" label="Expense Date "  type="date" name="expense_date" id="expense_date" value={expense_date}/>
                                                </div>

                                                <Row>
                                                <div className="col-md-6 " style={{paddingLeft: '20px'}}>
                                                        <label>Expense Group</label>
                                                        <AsyncSelect 
                                                            isClearable={true}
                                                            cacheOptions
                                                            isSearchable={true}
                                                            defaultOptions={this.state.expenseGroupList}
                                                            defaultValue={{ label: (expense_group == null)?'':expense_group.expense_group_name, value: f_expense_type_no }}
                                                            loadOptions={this.loadGroupOptions}
                                                            onChange={(value) => this.handleEditGroupSearch(value)}
                                                        />
                                                        <AvField type="hidden" name="f_expense_type_no" id="f_expense_type_no" value={f_expense_type_no} />
                                                        <AvField type="hidden" name="expense_transaction_no" id="expense_transaction_no" value={expense_transaction_no} />
                                                 </div>

                                                 <div className="col-md-6" style={{paddingLeft: '20px'}}>
                                                        <label>Expense Type</label>
                                                        <AsyncSelect 
                                                            isClearable={true}
                                                            cacheOptions
                                                            isSearchable={true}
                                                            defaultOptions={this.state.expenseTypeList}
                                                            defaultValue={{ label: (expense_type == null)?'':expense_type.expense_type_name, value: f_expense_group_id }}
                                                            loadOptions={this.loadTypeOptions}
                                                            onChange={(value) => this.handleEditTypeSearch(value)}
                                                        />
                                                        <AvField type="hidden" name="f_expense_group_id" id="f_expense_group_id" value={f_expense_group_id}/>
                                                        <AvField type="hidden" name="f_expense_group_type_map_id" id="f_expense_group_type_map_id" value={f_expense_group_type_map_id} />
                                                 </div>
                                                </Row>
                                                 <div className="col-md-12">
                                                    <AvField type="select"className="custom-select" label="Payment Type" name="f_deposit_type_no" id="f_deposit_type_no" value={f_deposit_type_no} >
                                                                <option >Select</option>
                                                                <option value={1}>Bank</option>
                                                                <option  value={2}>Cash</option>
                                                    </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Expense Amount "  type="number" name="expense_amount" id="expense_amount" value={expense_amount} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Narration "  type="textarea" name="comment" id="comment"  value={comment} />
                                                 </div>

                                                 
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="5" md="5">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create New Expense Transaction</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Expense Date "  type="date" name="expense_date" id="expense_date" required value={this.state.expenseDate}/>
                                                 </div>
                                                 
                                                <Row>
                                                 <div className="col-md-6" style={{paddingLeft: '20px'}}>
                                                        <label>Expense Group</label>
                                                        <AsyncSelect 
                                                            isClearable={true}
                                                            cacheOptions
                                                            isSearchable={true}
                                                            defaultOptions={this.state.expenseGroupList}    
                                                            loadOptions={this.loadGroupOptions}
                                                            onChange={(value) => this.handleGroupSearch(value)}
                                                        />
                                                        <FormText style={{background: 'yellow'}}>Search By Expense Group Name </FormText>
                                                        <AvField type="hidden" name="f_expense_group_id" id="f_expense_group_id" value={this.state.f_expense_group_id}/>
                                                 </div>

                                                 <div className="col-md-6">
                                                        <label>Expense Type</label>
                                                        <AsyncSelect 
                                                            isClearable={true}
                                                            cacheOptions
                                                            isSearchable={true}
                                                            defaultOptions={this.state.expenseTypeList}    
                                                            loadOptions={this.loadTypeOptions}
                                                            onChange={(value) => this.handleTypeSearch(value)}
                                                        />
                                                        <FormText style={{background: 'yellow'}}>Search By Expense Type Name </FormText>
                                                        <AvField type="hidden" name="f_expense_type_no" id="f_expense_type_no" value={this.state.f_expense_type_no} />
                                                        <AvField type="hidden" name="f_expense_group_type_map_id" id="f_expense_group_type_map_id" value={this.state.f_expense_group_type_map_id} />
                                                        
                                                 </div>
                                                 </Row>

                                                 <div className="col-md-12">
                                                    <AvField type="select"className="custom-select" label="Payment Type" name="f_deposit_type_no" id="f_deposit_type_no" value={2} required>
                                                                <option >Select</option>
                                                                <option value={1}>Bank</option>
                                                                <option  value={2}>Cash</option>
                                                    </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Expense Amount "  type="number" name="expense_amount" id="expense_amount" required />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Narration "  type="textarea" name="comment" id="comment" />
                                                 </div>

                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ExpenseTransaction;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createExpenseTransaction,editExpenseTransaction })(ExpenseTransaction));
