import { PARKING_SLOT_ERROR,PARKING_SLOT_SUCCESS,EDIT_PARKING_SLOT,SHOW_PARKING_SLOT,CREATE_PARKING_SLOT } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createParkingSlot = (parkingSlot) => {
    return {
        type: CREATE_PARKING_SLOT,
        payload: { parkingSlot }
    }
}

export const showParkingSlot = (parkingSlot_id) => {
    return {
        type: SHOW_PARKING_SLOT,
        payload: { parkingSlot_id }
    }
}

export const editParkingSlot = (parkingSlot) => {
    return {
        type: EDIT_PARKING_SLOT,
        payload: { parkingSlot }
    }
}



export const parkingSlotSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: PARKING_SLOT_SUCCESS,
        payload: msg
    }
}


export function parkingSlotShow(parkingSlot_id){
    return getApi().get('parkingSlot/'+parkingSlot_id)
}


export function parkingSlotList(params){
    return getApi().get('parkingSlot/list',{
        params: params
    })
}


export const parkingSlotError = (error) => {
    return {
        type: PARKING_SLOT_ERROR,
        payload: error
    }
}
