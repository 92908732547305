import { CUSTOMER_ERROR,CUSTOMER_SUCCESS,EDIT_CUSTOMER,SHOW_CUSTOMER,CREATE_CUSTOMER } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createCustomer = (customer) => {
    return {
        type: CREATE_CUSTOMER,
        payload: { customer }
    }
}

export const showCustomer = (customer_id) => {
    return {
        type: SHOW_CUSTOMER,
        payload: { customer_id }
    }
}

export const editCustomer = (customer) => {
    return {
        type: EDIT_CUSTOMER,
        payload: { customer }
    }
}



export const customerSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: CUSTOMER_SUCCESS,
        payload: msg
    }
}


export function customerShow(customer_id){
    return getApi().get('customer/'+customer_id)
}


export function customerList(params){
    return getApi().get('customer/list',{
        params: params
    })
}

export function customerSearchList(keyword,params){
    return getApi().get('customer/search/'+keyword,{
        params: params
    })
}

export function customerVehicleList(keyword,params){
    return getApi().get('customerVehicleMapping/search/'+keyword,{
        params: params
    })
}

export function userList(params){
    return getApi().get('user/list',{
        params: params
    })
}


export const customerError = (error) => {
    return {
        type: CUSTOMER_ERROR,
        payload: error
    }
}
