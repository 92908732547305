import { EXPENSE_GROUP_TYPE_MAP_ERROR,EXPENSE_GROUP_TYPE_MAP_SUCCESS,EDIT_EXPENSE_GROUP_TYPE_MAP,SHOW_EXPENSE_GROUP_TYPE_MAP,CREATE_EXPENSE_GROUP_TYPE_MAP } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createExpenseGroupTypeMap = (expenseGroupTypeMap) => {
    return {
        type: CREATE_EXPENSE_GROUP_TYPE_MAP,
        payload: { expenseGroupTypeMap }
    }
}

export const showExpenseGroupTypeMap = (expense_group_type_map_id) => {
    return {
        type: SHOW_EXPENSE_GROUP_TYPE_MAP,
        payload: { expense_group_type_map_id }
    }
}

export const editExpenseGroupTypeMap = (expenseGroupTypeMap) => {
    return {
        type: EDIT_EXPENSE_GROUP_TYPE_MAP,
        payload: { expenseGroupTypeMap }
    }
}



export const expenseGroupTypeMapSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: EXPENSE_GROUP_TYPE_MAP_SUCCESS,
        payload: msg
    }
}


export function expenseGroupTypeMapShow(expense_group_type_map_id){
    return getApi().get('expenseGroupTypeMap/'+expense_group_type_map_id)
}


export function expenseGroupTypeMapList(params){
    return getApi().get('expenseGroupTypeMap/list',{
        params: params
    })
}

export function expenseGroupTypeMapSearchList(keyword,params){
    return getApi().get('expenseGroupTypeMap/search/'+keyword,{
        params: params
    })
}


export function expenseGroupSearchList(keyword,params){
    return getApi().get('expenseGroup/search/'+keyword,{
        params: params
    })
}

export function expenseTypeSearchList(keyword,params){
    return getApi().get('expenseType/search/'+keyword,{
        params: params
    })
}


export const expenseGroupTypeMapError = (error) => {
    return {
        type: EXPENSE_GROUP_TYPE_MAP_ERROR,
        payload: error
    }
}
