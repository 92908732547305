import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PARK_IN,PARK_IN_SUCCESS,EDIT_PARK_IN,SHOW_PARK_IN,REMOVE_PARK_IN,RESTORE_PARK_IN} from './actionTypes';
import { parkInSuccess, parkOutError,parkOutShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData,deleteJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createParkIn({ payload: { parkingTransaction } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'parkingTransaction/create', parkingTransaction);
                console.log(' parkInSuccess res',response)
                if(response.success == false){
                    
                    yield put(parkOutError(response.message));
                }else if(response.success == true){
                    
                    yield put(parkInSuccess(response.data.message));
                }
          }
    } catch (error) {
        yield put(parkOutError(error));
    }
}


function* editParkIn({ payload: { parkingTransaction } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'parkingTransaction/'+parkingTransaction.id, parkingTransaction);
             if(response.success == false){
                
                yield put(parkOutError(response.message));
            }else if(response.success == true){
                
                yield put(parkInSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(parkOutError(error));
    }
}

function* restoreParkIn({ payload: { parkingTransaction } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'parkingTransaction/restore/'+parkingTransaction.id, parkingTransaction);
             if(response.success == false){
                
                yield put(parkOutError(response.message));
            }else if(response.success == true){
                
                yield put(parkInSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(parkOutError(error));
    }
}

function* removeParkIn({ payload: { parkingTransaction } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(deleteJwtData, 'parkingTransaction/'+parkingTransaction.id);
             if(response.success == false){
                
                yield put(parkOutError(response.message));
            }else if(response.success == true){
                
                yield put(parkInSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(parkOutError(error));
    }
}

function* showParkIn({ payload: { parkingTransaction_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'parkingTransaction/'+parkingTransaction_id);
             yield put(parkOutShow(response));
          }
          
          
    } catch (error) {
        yield put(parkOutError(error));
    }
}

export function* watchParkingTransaction() {
    yield takeEvery(EDIT_PARK_IN, editParkIn)
    yield takeEvery(RESTORE_PARK_IN, restoreParkIn)
    yield takeEvery(CREATE_PARK_IN, createParkIn)
    yield takeEvery(SHOW_PARK_IN, showParkIn)
    yield takeEvery(REMOVE_PARK_IN, removeParkIn)
}

function* ParkingTransactionSaga() {
    yield all([
        fork(watchParkingTransaction),
    ]);
}

export default ParkingTransactionSaga;