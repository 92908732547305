import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,UncontrolledTooltip } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createParkIn,deletedParkInList,restoreParkIn,rateTypeList,vehicleTypeList} from '../../../store/park/actions';
import { parkingSpaceNameList} from '../../../store/parkingSpaceName/actions';
import Loader from "../../../pages/Loader/Loader";

class ParkInDeletedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                loading: true,
                parkOutList: {
                    
                },
                parkIn:{
                    vehicle_type: {}
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchDeletedParkInList();     
            this.fetchRateTypeList();     
            this.fetchVehicleTypeList();     
            this.fetchparkingSpaceNameList();     
    }

    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList : response.data.rateType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchVehicleTypeList = () => {
        vehicleTypeList().then((response) => {
            this.setState({
                vehicleTypeList : response.data.vehicleType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkingSpaceNameList = () => {
        parkingSpaceNameList().then((response) => {
            this.setState({
                parkingSpaceNameList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchDeletedParkInList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        deletedParkInList(options).then((response) => {
            this.setState({
                parkOutList :response.data, 
                loading: false
            });
        })
        .catch((err) => {
            this.state.loading = false;   
            console.log(err);
        })
    }

    //modal
    tog_scroll = (parkIn) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        if(parkIn.hasOwnProperty('id')){
            this.state.parkIn = parkIn;
        }
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createParkIn(values);
            alert('Created Successfully');
            // this.fetchDeletedParkInList();   
            window.location.reload();    
        }

       
       handleEditSubmit(event, values) {  
            this.props.restoreParkIn(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
              setTimeout(() =>
                    this.fetchDeletedParkInList(),
                    800
                  );
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchDeletedParkInList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchDeletedParkInList();
            });
        }

    render() {
        if (this.state.loading) return <Loader />;

        let vehicleTypeList = [];
        let rateTypeList = [];
        let parkingSpaceNameList = [];
        let parkOutList = [];
        let paginator;
        if (this.state.parkOutList.hasOwnProperty('data')) {
            parkOutList = this.state.parkOutList.data;
            paginator = {
                'currentPage':this.state.parkOutList.current_page,
                'pagesCount':this.state.parkOutList.last_page,
                'total':this.state.parkOutList.total,
                'to':this.state.parkOutList.to,
                'from':this.state.parkOutList.from,
            }
        }
        const {id,token_no,vehicle_name,f_parking_space_name_id,f_rate_type_id,f_in_out_info,f_vehicle_type_id,device_id,tran_start_date,tran_start_date_time,vehicle_type,vehicleType,f_client_id,first_hourly_rate,update_date_time} = this.state.parkIn;
        let image_url = process.env.REACT_APP_IMAGE_URL ;

        if (this.state.hasOwnProperty('vehicleTypeList')) { vehicleTypeList = this.state.vehicleTypeList; }
        if (this.state.hasOwnProperty('rateTypeList')) { rateTypeList = this.state.rateTypeList; }
        if (this.state.hasOwnProperty('parkingSpaceNameList')) { parkingSpaceNameList = this.state.parkingSpaceNameList; }
        const gateTime = (tran_start_date_time,grace_period) =>{
            let hours = (Math.abs(new Date() -new Date(tran_start_date_time))/1000/60 - grace_period)/60;
            
            if(hours%1 > 0.00 &&  hours%1 < 0.51){
                hours = Math.floor(hours)+1;
            }else{
              hours = Math.floor(hours);
            }
            return hours
        }
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Deleted Park In List" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Deleted Park IN List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <table className="table table-centered table-nowrap mb-0">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Token</th>
                                                        <th>Vehicle</th>
                                                        <th>Type</th>
                                                        <th>Entry Time</th>
                                                        <th>Device</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {parkOutList.map((parkOut, index) =>
                                                    <tr key={parkOut.id .toString()}>
                                                    <td scope="row">{index+1}</td>
                                                    <td>{parkOut.token_no}</td>
                                                    <td>{parkOut.vehicle_name.substr(0,2)+'-'+parkOut.vehicle_name.substr(2)}</td>
                                                    <td>{parkOut.vehicle_type == null? parkOut.f_vehicle_type_id: parkOut.vehicle_type.vehicle_type_name_en}</td>
                                                    <td>{parkOut.tran_start_date_time}</td>
                                                    <td><Badge color={(parkOut.device_id == 'web')?'success':'info'}>{(parkOut.device_id == 'web')?'Web':'Android'}</Badge></td>
                                                    <td>
                                                        <Button 
                                                        className="btn btn-sm btn-success"
                                                        onClick={() => { this.tog_scroll(parkOut) }} 
                                                        data-toggle="modal"
                                                        >Details</Button>
                                                    </td>
                                                   </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal 
                                className={"modal-dialog-centered"}
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">Token {token_no}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Rate Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg="4">
                                                                <div className="text-lg-center">
                                                                    {
                                                                        
                                                                        vehicle_type.icon_image !== null
                                                                            ? <img src={ image_url+'vehicle/'+vehicle_type.icon_image} className="avatar-sm mr-3 rounded-circle mb-4 float-left float-lg-none" alt="img" />
                                                                            : <div className="avatar-sm mr-3 mx-lg-auto mb-4 float-left float-lg-none">
                                                                                <span className={"avatar-title rounded-circle bg-soft-green text-primary font-size-16"}>
                                                                                    {vehicle_type.vehicle_type_name_en.charAt(0)}
                                                                                </span>
                                                                            </div>
                                                                    }

                                                                    <h5 className="mb-1 font-size-15 text-truncate">{vehicle_name == null?'':vehicle_name.substr(0,2)+'-'+vehicle_name.substr(2)}</h5>
                                                                    <Badge color={(device_id == 'web')?'success':'info'}>Device: {(device_id == 'web')?'Web':device_id}</Badge>
                                                                    
                                                                </div>
                                                            </Col>

                                                            <Col lg="8">
                                                                <div>
                                                                    <Link to="#" className="d-block text-primary mb-2">Token #{token_no}</Link>
                                                                    <h5 className="text-truncate mb-4 mb-lg-5">{vehicle_type.vehicle_type_name_en}</h5>
                                                                    <ul className="list-inline mb-0">
                                                                        
                                                                        <li className="list-inline-item">
                                                                            <h5 className="font-size-14" id="duedateTooltip"><i className="bx bx-calendar mr-1 text-primary"></i>
                                                                                Start Date Time :{new Date(tran_start_date_time).toLocaleString()}
                                                                                <UncontrolledTooltip placement="top" target="duedateTooltip">
                                                                                   Start Date Time
                                                                                        </UncontrolledTooltip>
                                                                            </h5>
                                                                        </li>
                                                                        <br></br>
                                                                        <li className="list-inline-item ">
                                                                            <h5 className="font-size-14" id="timeTooltip">
                                                                                <i className="bx bx-calendar mr-1 text-primary"></i>
                                                                                    Deleted Time:   {new Date(update_date_time).toLocaleString} 
                                                                                <UncontrolledTooltip placement="top" target="timeTooltip">
                                                                                    Deleted Time
                                                                                                </UncontrolledTooltip>
                                                                            </h5>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <AvField  type="hidden" name="id" id="id" value={id}  />
                                                <AvField  type="hidden" name="f_in_out_info" id="f_in_out_info" value={f_in_out_info}  />
                                                <AvField  type="hidden" name="tran_start_date" id="tran_start_date" value={tran_start_date}  />
                                                <AvField  type="hidden" name="f_vehicle_type_id" id="f_vehicle_type_id" value={f_vehicle_type_id}  />
                                                <AvField  type="hidden" name="token_no" id="token_no" value={token_no}  />

                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Restore</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingTransaction;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createParkIn,restoreParkIn })(ParkInDeletedList));
