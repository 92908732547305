import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PARK_RATE,PARKING_SPACE_NAME_SUCCESS,EDIT_PARK_RATE,SHOW_PARKING_SPACE_NAME } from './actionTypes';
import { parkingSpaceNameSuccess, parkingSpaceNameError,parkingSpaceNameShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createParkRate({ payload: { parkRate } }) { 

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'ParkRate/create', parkRate);
               
             if(response.success == false){
                
                yield put(parkingSpaceNameError(response.message));
            }else if(response.success == true){
                
                yield put(parkingSpaceNameSuccess(response.data.message));
            }
          }
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}


function* editParkRate({ payload: { park_rate } }) {
   
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'ParkRate/'+park_rate.id, park_rate);
             if(response.success == false){
                
                yield put(parkingSpaceNameError(response.message));
            }else if(response.success == true){
                
                yield put(parkingSpaceNameSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}


function* showParkingSpaceName({ payload: { parkingSpaceName_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'parkingSpaceName/'+parkingSpaceName_id);
             yield put(parkingSpaceNameShow(response));
          }
          
          
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}

export function* watchParkRate() {
    yield takeEvery(CREATE_PARK_RATE, createParkRate)
     yield takeEvery(EDIT_PARK_RATE, editParkRate)
    // yield takeEvery(SHOW_PARKING_SPACE_NAME, showParkingSpaceName)
}

function* ParkRateSaga() {
    yield all([
        fork(watchParkRate),
    ]);
}

export default ParkRateSaga;