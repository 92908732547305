import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AsyncSelect from "react-select/async";
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createBill,searchByBillData,billList,customerSearchList,customerVehicleList,companyInfo} from '../../../store/bill/actions';
import { rateTypeList,vehicleTypeList} from '../../../store/parkingRate/actions';
 
class GenerateBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                f_rate_type_id: 4,
                customer_name: '',
                comission: 0,
                bill_generate_date: new Date().toISOString().split('T')[0],
                startDate: new Date(),
                endDate: new Date(),
                selectedVehicle: [],
                customer: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
        this.fetchCompanyInfo();  
        this.fetchRateTypeList();     
    }



    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList : response.data.rateType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchCompanyInfo = () => {

        companyInfo().then((response) => {
            this.setState({
                company: response.data
            })
        })
        .catch((err) => {
            console.log(err);
        })
        
      }
    
    fetchbillList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        billList(options).then((response) => {

            this.setState({
                billList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (customer) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.customer = customer;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createBill(values);
            this.form && this.form.reset();
        }

        handleSearchByBillData(event, values) {          
            searchByBillData(values).then((response) => {

                if(response.data.status == true){
                    this.setState({
                        bill_data: response.data.bill
                    })
                }else{
                    alert(response.data.message);
                }
              
            })
            .catch((err) => {
                console.log(err);
            })
        }

        handleCustomerSearch( customer){
            this.setState({
                customer_id: customer.value,
                customer_name: customer.label,
                selectedVehicle: []
            })

            this.fetchCustomerVehicleList(customer.value);

        }


        onSelect = (selectedList, selectedItem)=> {
            let selected = selectedList;
            this.setState({
                selectedVehicle: selected,
              });
        }
        
        onRemove = (selectedList, removedItem)=> {
            let selected = selectedList;
            this.setState({
                selectedVehicle: selected,
              });
        }

        setStartDate = (month) => {
            this.setState({
                selected_month: month
            })
        }

        onChange = dates => {
            const [start, end] = dates;
            console.log('startr',start,end);
            // this.state.startDate = start;
            // this.state.endDate = end;
          };

        loadOptions = (inputValue, callback) => {

            customerSearchList(inputValue).then((response) => {

                const requestResults = response.data;
              
                callback(requestResults);
            })
            .catch((err) => {
                console.log(err);
            })
            
          }
        
          fetchCustomerVehicleList = (customer_id) => {

            var f_rate_type_id = this.state.f_rate_type_id;

            customerVehicleList(customer_id,f_rate_type_id).then((response) => {
                this.setState({
                    customerVehicleList: response.data
                })
            })
            .catch((err) => {
                console.log(err);
            })
            
          }
       

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchbillList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchbillList();
            });
        }

    render() {
      
        let bill_data = [];
        let customerVehicleList = [];
        let rateTypeList = [];
        let company_name = '' , address = '' , mobile = '';
        let total_bill = 0;
        if (this.state.hasOwnProperty('bill_data')) {
            bill_data = this.state.bill_data;
        }

        if (this.state.hasOwnProperty('company')) {
            company_name = this.state.company.name;
            address = this.state.company.address;
            mobile = this.state.company.mobile;
        }

        if (this.state.hasOwnProperty('customerVehicleList')) { customerVehicleList = this.state.customerVehicleList; }
        if (this.state.hasOwnProperty('rateTypeList')) { rateTypeList = this.state.rateTypeList; }

        bill_data.map(function(billValue){
            total_bill += billValue.monthly_rate;
        });

      

        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

       
          
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Generate Bill" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Bill Info</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleSearchByBillData(e,v) }}>
                                                
                                                <Row>
                                                    <div className="col-md-3 ">
                                                        <AvField type="select"className="custom-select" label="Rate Type" onChange={(e,v)=> { this.setState({f_rate_type_id: v})}} name="f_rate_type_id" id="f_rate_type_id" value={this.state.f_rate_type_id}>
                                                                <option defaultValue>Select</option>
                                                                {rateTypeList.map((rtype, index) =>
                                                                    <option value={rtype.id}>{rtype.rate_name_en}</option>
                                                                )}
                                                        </AvField>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label>Month</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={this.state.selected_month}
                                                            onChange={date => this.setStartDate(date)}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                        />
                                                        <AvField    type="hidden" name="selected_month" id="selected_month" value={this.state.selected_month} />
                                                    </div>

                                                    <div className="col-md-3">
                                                        <label>Customer</label>
                                                        <AsyncSelect 
                                                            loadOptions={this.loadOptions}
                                                            onChange={(value) => this.handleCustomerSearch(value)}
                                                        />
                                                        <AvField  type="hidden" name="customer_id" id="customer_id" value={this.state.customer_id} />
                                                    </div>

                                                    <div className="col-md-3">
                                                        <label>Vehicle</label>
                                                        <Multiselect
                                                            options={customerVehicleList} 
                                                            selectedValues={this.state.selectedVehicle} 
                                                            onSelect={this.onSelect} 
                                                            onRemove={this.onRemove} 
                                                            displayValue="vehicle_name" 
                                                        />
                                                        <AvField type="hidden" name="customer_vehicle_map" id="customer_vehicle_map" value ={this.state.selectedVehicle} />
                                                    </div>


                                                </Row>

                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create New Bill</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                
                                                <Row>
                                                    <AvField    type="hidden" name="bill_generate_month" id="bill_generate_month" value={this.state.selected_month} />
                                                    <AvField  type="hidden" name="f_customer_id" id="f_customer_id" value={this.state.customer_id} />
                                                    <AvField type="hidden" name="customer_vehicle_map" id="customer_vehicle_map" value ={bill_data} />
                                                    <AvField type="hidden" name="total_bill" id="total_bill" value ={total_bill} />

                                                    <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                            <h2 className="text-center"><b>{company_name}</b></h2>
                                                            <span className="text-center">{address}</span><br></br>
                                                            <span className="text-center">Phone: {mobile}</span>
                                                            <br></br>
                                                            <h4 className="text-center"><b>Bill For {monthNames[new Date(this.state.selected_month).getMonth()]} Month  </b></h4>
                                                            <span className="text-center">Customer: {this.state.customer_name}</span>
                                                    </div>
                                                    <div className="col-md-12">
                                                    <Table striped responsive >
                                                            <thead>
                                                                <tr>
                                                                    <th>Vehicle Name</th>
                                                                    <th>Vehicle No</th>
                                                                    <th>Responsible Person</th>
                                                                    <th>Parking Space</th>
                                                                    <th>Parking Slot</th>
                                                                    <th>Vehicle Type</th>
                                                                    <th>Rate Type</th>
                                                                    <th>Monthly Rate </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {bill_data.map((cusVehicleMap, index) =>
                                                                <tr key={index}>
                                                                    <td>{cusVehicleMap.vehicle_name}</td>
                                                                    <td>{cusVehicleMap.vehicle_number}</td>
                                                                    <td>{cusVehicleMap.responsible_person_name}</td>
                                                                    <td>{cusVehicleMap.parking_space_name == null? cusVehicleMap.f_parking_space_name_id:cusVehicleMap.parking_space_name.space_name_en}</td>
                                                                    <td>{cusVehicleMap.parking_slot == null? cusVehicleMap.f_parking_slot_id: cusVehicleMap.parking_slot.parking_slot_name_en}</td>
                                                                    <td>{cusVehicleMap.vehicle_type == null? cusVehicleMap.f_vehicle_type_id: cusVehicleMap.vehicle_type.vehicle_type_name_en}</td>
                                                                    <td>{cusVehicleMap.rate_type == null? cusVehicleMap.f_rate_type_id: cusVehicleMap.rate_type.rate_name_en }</td>
                                                                    <td>{cusVehicleMap.monthly_rate}</td>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td colSpan={7} style={{textAlign: 'end'}}><b>Total</b></td>
                                                                <td>{total_bill} /=</td>
                                                            </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <AvField className="form-control" label="Bill Generate Date"  type="date" name="bill_generate_date" id="bill_generate_date" value={this.state.bill_generate_date} required/>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <AvField className="form-control" label="Discount"  type="number" onChange={(e,v)=> { this.setState({comission: v})}} name="total_commision" id="total_commision" value='0'  />
                                                    </div>

                                                    

                                                    <div className="col-md-2">
                                                        <label>Total Bill</label>
                                                        <h4>{total_bill*1 - this.state.comission*1} /= </h4>
                                                    </div>

                                                    <div className="col-md-8">
                                                        <AvField className="form-control" label="Remarks"  type="textarea" name="remarks" id="remarks" />
                                                    </div>

                                                    {/* <div className="col-md-2">
                                                        <AvField className="form-control" label="Invoice No"  type="text" name="invoice_number" id="invoice_number" />
                                                    </div> */}

                                                    <div className="col-md-2">
                                                        <AvField className="form-control" label="Refernce ID"  type="text" name="ref_id" id="ref_id" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <Button type="submit" style={{marginTop: '27px'}}  color="success">Generate</Button>
                                                    </div>

                                                </Row>

                                             <div className=" mt-4">
                                                 {/* <Button type="submit" color="success">Generate</Button> */}
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Bill;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createBill })(GenerateBill));
