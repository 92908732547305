import { EXPENSE_TRANSACTION_ERROR,EXPENSE_TRANSACTION_SUCCESS,EDIT_EXPENSE_TRANSACTION,SHOW_EXPENSE_TRANSACTION,CREATE_EXPENSE_TRANSACTION } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createExpenseTransaction = (expenseTransaction) => {
    return {
        type: CREATE_EXPENSE_TRANSACTION,
        payload: { expenseTransaction }
    }
}

export const showExpenseTransaction = (expense_transaction_no) => {
    return {
        type: SHOW_EXPENSE_TRANSACTION,
        payload: { expense_transaction_no }
    }
}

export const editExpenseTransaction = (expenseTransaction) => {
    return {
        type: EDIT_EXPENSE_TRANSACTION,
        payload: { expenseTransaction }
    }
}



export const expenseTransactionSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: EXPENSE_TRANSACTION_SUCCESS,
        payload: msg
    }
}


export function expenseTransactionShow(expense_transaction_no){
    return getApi().get('expenseTransaction/'+expense_transaction_no)
}


export function expenseTransactionList(params){
    return getApi().get('expenseTransaction/list',{
        params: params
    })
}

export function expenseTransactionSearchList(keyword,params){
    return getApi().get('expenseTransaction/search/'+keyword,{
        params: params
    })
}


export function expenseGroupSearchList(keyword,params){
    return getApi().get('expenseGroup/search/'+keyword,{
        params: params
    })
}

export function expenseTypeByGroupSearchList(keyword,params){
    return getApi().get('expenseGroupTypeMap/search/'+keyword,{
        params: params
    })
}


export const expenseTransactionError = (error) => {
    return {
        type: EXPENSE_TRANSACTION_ERROR,
        payload: error
    }
}
