import { USER_ERROR,USER_SUCCESS,EDIT_USER } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const User = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_USER:
            state = { ...state }
            break;
        case USER_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case USER_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default User;