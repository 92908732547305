import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_CUSTOMER_VEHICLE_MAP,CUSTOMER_VEHICLE_MAP_SUCCESS,EDIT_CUSTOMER_VEHICLE_MAP,SHOW_CUSTOMER_VEHICLE_MAP } from './actionTypes';
import { customerVehicleMapSuccess, customerVehicleMapError,customerVehicleMapShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createCustomerVehicleMap({ payload: { customerVehicleMapping } }) {
    console.log('I am here');

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'customerVehicleMapping/create', customerVehicleMapping);
                console.log(' customerVehicleMapSuccess res',response)
                if(response.success == false){
                    
                    yield put(customerVehicleMapError(response.message));
                }else if(response.success == true){
                    
                    yield put(customerVehicleMapSuccess(response.data.message));
                }
          }
    } catch (error) {
        yield put(customerVehicleMapError(error));
    }
}


function* editCustomerVehicleMap({ payload: { customerVehicleMapping } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'customerVehicleMapping/'+customerVehicleMapping.customer_vehicle_map_id, customerVehicleMapping);
             if(response.success == false){
                
                yield put(customerVehicleMapError(response.message));
            }else if(response.success == true){
                
                yield put(customerVehicleMapSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(customerVehicleMapError(error));
    }
}


function* showCustomerVehicleMap({ payload: { customerVehicleMapping_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'customerVehicleMapping/'+customerVehicleMapping_id);
             yield put(customerVehicleMapShow(response));
          }
          
          
    } catch (error) {
        yield put(customerVehicleMapError(error));
    }
}

export function* watchCustomerVehicleMapping() {
    yield takeEvery(EDIT_CUSTOMER_VEHICLE_MAP, editCustomerVehicleMap)
    yield takeEvery(CREATE_CUSTOMER_VEHICLE_MAP, createCustomerVehicleMap)
    yield takeEvery(SHOW_CUSTOMER_VEHICLE_MAP, showCustomerVehicleMap)
}

function* CustomerVehicleMappingSaga() {
    yield all([
        fork(watchCustomerVehicleMapping),
    ]);
}

export default CustomerVehicleMappingSaga;