import React from "react";
import loader from "../../assets/images/loader.gif";
// import "./spinner.scss";

const Loader = isLoading => {
  
    return (
    //  <div id='outer'>
    //     <div id='middle'>
          
    //         <div id='inner'>    
    //         </div>
    //     </div>
    // </div>

    //Gif Loader
    <div>
        <img style={{marginTop: '25%',marginLeft: '50%'}} src={loader} />
    </div>

    );
};

export default Loader;
