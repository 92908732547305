import { PARKING_SPACE_NAME_ERROR,PARKING_SPACE_NAME_SUCCESS,EDIT_PARKING_SPACE_NAME,SHOW_PARKING_SPACE_NAME,CREATE_PARKING_SPACE_NAME } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createParkingSpaceName = (parkingSpaceName) => {
    return {
        type: CREATE_PARKING_SPACE_NAME,
        payload: { parkingSpaceName }
    }
}

export const showParkingSpaceName = (parkingSpaceName_id) => {
    return {
        type: SHOW_PARKING_SPACE_NAME,
        payload: { parkingSpaceName_id }
    }
}

export const editParkingSpaceName = (parkingSpaceName) => {
    return {
        type: EDIT_PARKING_SPACE_NAME,
        payload: { parkingSpaceName }
    }
}



export const parkingSpaceNameSuccess = (msg) => {
    return {
        type: PARKING_SPACE_NAME_SUCCESS,
        payload: msg
    }
}


export function parkingSpaceNameShow(parkingSpaceName_id){
    return getApi().get('parkingSpaceName/'+parkingSpaceName_id)
}


export function parkingSpaceNameList(params){
    return getApi().get('parkingSpaceName/list',{
        params: params
    })
}


export const parkingSpaceNameError = (error) => {
    return {
        type: PARKING_SPACE_NAME_ERROR,
        payload: error
    }
}
export function getDivisionDropdown(params) {
    return getApi().get('parkingSpaceName/division/list',{
        params: params
    });
}
    
export function getDistrictDropdown(division_id,params) {
    return getApi().get('parkingSpaceName/district/'+division_id,{
        params: params
    });
  }

  export function getThanaDropdown(thana_id,params) {
    return getApi().get('parkingSpaceName/thana/'+thana_id,{
        params: params
    });
}
