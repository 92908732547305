import { CUSTOMER_VEHICLE_MAP_ERROR,CUSTOMER_VEHICLE_MAP_SUCCESS,EDIT_CUSTOMER_VEHICLE_MAP,SHOW_CUSTOMER_VEHICLE_MAP,CREATE_CUSTOMER_VEHICLE_MAP } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createCustomerVehicleMap = (customerVehicleMapping) => {
    return {
        type: CREATE_CUSTOMER_VEHICLE_MAP,
        payload: { customerVehicleMapping }
    }
}

export const showCustomerVehicleMap = (customerVehicleMapping_id) => {
    return {
        type: SHOW_CUSTOMER_VEHICLE_MAP,
        payload: { customerVehicleMapping_id }
    }
}

export const editCustomerVehicleMap = (customerVehicleMapping) => {
    return {
        type: EDIT_CUSTOMER_VEHICLE_MAP,
        payload: { customerVehicleMapping }
    }
}



export const customerVehicleMapSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: CUSTOMER_VEHICLE_MAP_SUCCESS,
        payload: msg
    }
}


export function customerVehicleMapShow(customerVehicleMapping_id){
    return getApi().get('customerVehicleMapping/'+customerVehicleMapping_id)
}


export function customerVehicleMapList(params){
    return getApi().get('customerVehicleMapping/list',{
        params: params
    })
}

export function userList(params){
    return getApi().get('user/list',{
        params: params
    })
}


export const customerVehicleMapError = (error) => {
    return {
        type: CUSTOMER_VEHICLE_MAP_ERROR,
        payload: error
    }
}
