import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createCustomer,customerList,editCustomer} from '../../../store/customer/actions';
 
class Customers extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                customer: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
        this.fetchcustomerList();  
    }

    
    fetchcustomerList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        customerList(options).then((response) => {

            this.setState({
                customerList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (customer) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.customer = customer;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createCustomer(values);
            this.form && this.form.reset();
            this.fetchcustomerList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editCustomer(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));

            this.fetchcustomerList(); 
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchcustomerList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchcustomerList();
            });
        }

    render() {
      
        let customerList = [];
        
        let paginator;
        if (this.state.hasOwnProperty('customerList')) {
            customerList = this.state.customerList.data;
            paginator = {
                'currentPage':this.state.customerList.current_page,
                'pagesCount':this.state.customerList.last_page,
                'total':this.state.customerList.total,
                'to':this.state.customerList.to,
                'from':this.state.customerList.from,
            }
        }

        const {customer_id,customer_name_en,customer_name_bn,gender,mobile_no,email,address,nid,nationality} = this.state.customer;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Customer" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="8" md="8">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Customer List</CardTitle>
                                        <div className="table-responsive">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table  mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Customer Name</th>
                                                        <th>Mobile No</th>
                                                        <th>Email</th>
                                                        <th>Gender</th>
                                                        <th>Address</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {customerList.map((customer, index) =>
                                                    <tr key={customer.customer_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{customer.customer_name_en}</td>
                                                        <td>{customer.mobile_no}</td>
                                                        <td>{customer.email}</td>
                                                        <td>{customer.gender}</td>
                                                        <td>{customer.address}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(customer) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{customer_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Customer Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                
                                                <div className="col-md-12">
                                                    <AvField className="form-control" label="Customer Name "  type="text" name="customer_name_en" id="customer_name_en" value={customer_name_en} />
                                                     <AvField type="hidden" name="customer_id" id="customer_id" value={customer_id} />
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Customer Name (Bangla) "  type="text" name="customer_name_bn" id="customer_name_bn" value={customer_name_bn} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Mobile No "  type="number" name="mobile_no" id="mobile_no" value={mobile_no} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Email"  type="text" name="email" id="email" value={email} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Address"  type="text" name="address" id="address" value={address} />
                                                 </div>

                                                 {/* <div className="col-md-12">
                                                    <AvField type="select"className="custom-select" label="Gender" name="gender" id="gender" value={gender}>
                                                        <option  >Select</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </AvField>
                                                 </div> */}

                                                 <div className="col-md-12">
                                                    <AvField type="select"className="custom-select" label="Nationality" name="nationality" id="nationality" value={nationality}>
                                                        <option  >Select</option>
                                                        <option value="bangladesh">Bangladesh</option>
                                                    </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="NID"  type="text" name="nid" id="nid"  value={nid}/>
                                                 </div>

                                                 
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="4" md="4">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create New Customer</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Customer Name "  type="text" name="customer_name_en" id="customer_name_en" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Customer Name (Bangla) "  type="text" name="customer_name_bn" id="customer_name_bn" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Mobile No "  type="number" name="mobile_no" id="mobile_no" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Email"  type="text" name="email" id="email" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Address"  type="text" name="address" id="address" />
                                                 </div>

                                                 {/* <div className="col-md-12">
                                                    <AvField type="select"className="custom-select" label="Gender" name="gender" id="gender">
                                                        <option  >Select</option>
                                                        <option  value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </AvField>
                                                 </div> */}

                                                 <div className="col-md-12">
                                                    <AvField type="select"className="custom-select" label="Nationality" name="nationality" id="nationality">
                                                        <option  >Select</option>
                                                        <option  value="bangladesh">Bangladesh</option>
                                                    </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="NID"  type="text" name="nid" id="nid" />
                                                 </div>
                                             
                                                 

                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Customer;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createCustomer,editCustomer })(Customers));
