import { VEHICLE_TYPE_ERROR,VEHICLE_TYPE_SUCCESS,EDIT_VEHICLE_TYPE,SHOW_VEHICLE_TYPE,CREATE_VEHICLE_TYPE } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createVehicleType = (vehicleType) => {
    return {
        type: CREATE_VEHICLE_TYPE,
        payload: {vehicleType}
    }
}

export const editVehicleType = (vehicleType) => {
    return {
        type: EDIT_VEHICLE_TYPE,
        payload: {vehicleType}
    }
}

export const vehicleTypeSuccess = (msg) => {
    return {
        type: VEHICLE_TYPE_SUCCESS,
        payload: msg
    }
}

export function vehicleTypeList(params){
    return getApi().get('vehicle/list',{
        params: params
    })
}


export const vehicleTypeError = (error) => {
    return {
        type: VEHICLE_TYPE_ERROR,
        payload: error
    }
}
