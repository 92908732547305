import { PARKING_SLOT_ERROR,PARKING_SLOT_SUCCESS,EDIT_PARKING_SLOT } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ParkingSlot = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_PARKING_SLOT:
            state = { ...state }
            break;
        case PARKING_SLOT_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case PARKING_SLOT_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ParkingSlot;