import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AsyncSelect from "react-select/async";
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createBill,searchByBillData,billShow,billPayment,customerVehicleList,companyInfo} from '../../../store/bill/actions';
 
class BillPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                customer_name: '',
                comission: 0,
                f_payment_type: 1,
                selectedVehicle: [],
                customer: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
        if (this.props.match.params.id) {
            this.fetchbillShow(this.props.match.params.id);
        }
        this.fetchCompanyInfo();  
    }


    fetchCompanyInfo = () => {

        companyInfo().then((response) => {
            this.setState({
                company: response.data
            })
        })
        .catch((err) => {
            console.log(err);
        })
        
      }
    
    fetchbillShow = (bill_id) => {
        billShow(bill_id).then((response) => {

            this.setState({
                billShow : response.data.billMaster, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    selectPayment = (e,v) => {
        // if(e.target.name == 'today_collected_amount'){
        //     if(v > this.state.billMaster.total_bill_due){
        //         alert('Invalid Payment');
        //         e.target.value = null;
        //     }
        // }else{
            this.setState({
                f_payment_type : v, 
            });
        // }
    }

    //modal
    tog_scroll = (customer) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.customer = customer;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {  
            var x = window.confirm('Are you sure to payment?')  
            if(x == true){
                values.f_bill_master_id =  this.state.billShow.bill_id;
                values.f_customer_id =  this.state.billShow.f_customer_id;
                values.is_billed =  1;

                this.props.billPayment(values);
                this.form && this.form.reset();
                if (this.props.match.params.id) {
                    this.fetchbillShow(this.props.match.params.id);
                    this.fetchbillShow(this.props.match.params.id);
                }
            }      
        }

        
       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchbillList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchbillList();
            });
        }

    render() {
      
        let billShow = [];
        let company_name = '' , address = '' , mobile = '';
        let customer = '' , customer_address = '', bill_generate_date = '' , bill_generate_month ='' ;

        let billDetails= [];


        if (this.state.hasOwnProperty('company')) {
            company_name = this.state.company.name;
            address = this.state.company.address;
            mobile = this.state.company.mobile;
        }

        if (this.state.hasOwnProperty('billShow')) { 
            billShow = this.state.billShow; 
            customer = billShow.customer.customer_name_en;
            customer_address = billShow.customer.address;
            bill_generate_date = billShow.bill_generate_date;
            bill_generate_month = billShow.bill_generate_month;
            billDetails = billShow.bill_details;
        }


      

        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
          
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Bill Payment" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle> Bill Payment</CardTitle>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                            <h2 className="text-center"><b>{company_name}</b></h2>
                                                            <span className="text-center">{address}</span><br></br>
                                                            <span className="text-center">Phone: {mobile}</span>
                                                            <br></br>
                                                            <h4 className="text-center"><u>{customer}</u></h4>
                                                            <h6 className="text-center">{customer_address}</h6>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4">
                                                                {/* <h6><u>Bill Payment</u></h6> */}
                                                        </div>
                                                        <div className="col-4">
                                                            <h3 className="text-center" style={{border: 'solid'}}>{ new Date(bill_generate_month).toLocaleString('default', { month: 'long' })} { new Date(bill_generate_month).getFullYear()}</h3>
                                                        </div>
                                                        <div className="col-4" style={{textAlign: 'right'}}>
                                                            <p>Date: {new Date().toLocaleDateString()}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-8">
                                                        <Table bordered responsive style={{borderColor: 'black'}}>
                                                            <thead>
                                                                <tr>
                                                                <th>Vehicle Name</th>
                                                                <th>Vehicle No</th>
                                                                <th>Responsible Phone No</th>
                                                                <th>Parking Space</th>
                                                                <th>Parking Slot</th>
                                                                <th>Vehicle Type</th>
                                                                <th>Rate Type</th>
                                                                <th>Monthly Rate </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {billDetails.map((monthValue, index) =>
                                                                    <tr>
                                                                        <td>{monthValue.customer_vehicle_map.vehicle_name}</td>
                                                                        <td>{monthValue.customer_vehicle_map.vehicle_number}</td>
                                                                        <td>{monthValue.customer_vehicle_map.responsible_person_number}</td>
                                                                        <td>{monthValue.customer_vehicle_map.parking_space_name == null? monthValue.customer_vehicle_map.f_parking_space_name_id:monthValue.customer_vehicle_map.parking_space_name.space_name_en}</td>
                                                                        <td>{monthValue.customer_vehicle_map.parking_slot == null? monthValue.customer_vehicle_map.f_parking_slot_id: monthValue.customer_vehicle_map.parking_slot.parking_slot_name_en}</td>
                                                                        <td>{monthValue.customer_vehicle_map.vehicle_type == null? monthValue.customer_vehicle_map.f_vehicle_type_id: monthValue.customer_vehicle_map.vehicle_type.vehicle_type_name_en}</td>
                                                                        <td>{monthValue.customer_vehicle_map.rate_type == null? monthValue.customer_vehicle_map.f_rate_type_id: monthValue.customer_vehicle_map.rate_type.rate_name_en }</td>
                                                                        <td>{monthValue.bill_amount}</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan={7} style={{textAlign: 'end'}}><b>Comission</b></td>
                                                                    <td>{billShow.total_commision}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={7} style={{textAlign: 'end'}}><b>Total Bill</b></td>
                                                                    <td>{billShow.total_bill*1 - billShow.total_commision*1}</td>
                                                                </tr>
                                                                <br></br>
                                                                <tr>
                                                                    <td colSpan={7} style={{textAlign: 'end'}}>Paid Amount</td>
                                                                    <td>{billShow.total_bill_paid}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={7} style={{textAlign: 'end'}}>Due Amount</td>
                                                                    <td>{billShow.total_bill_due}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        </div>

                                                <div className="col-4">

                                                <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>

                                                        <AvField className="form-control"  type="hidden" name="f_bill_master_id" id="f_bill_master_id" value={billShow.bill_id} />
                                                        <AvField className="form-control"  type="hidden" name="f_customer_id" id="f_customer_id" value={billShow.f_customer_id} />
                                                        <AvField className="form-control"  type="hidden" name="is_billed" id="is_billed" value={1} />

                                                        <div className="col-md-12">
                                                            <AvField className="form-control" label="Today Payment Amount"  type="text" name="today_collected_amount" id="today_collected_amount" onChange={(e,v) => this.selectPayment(e,v)} required />
                                                        </div>

                                                        <div className="col-md-12">
                                                            <AvField type="select"className="custom-select" label="Payment Type" onChange={(e,v) => this.selectPayment(e,v)} name="f_payment_type" id="f_payment_type" required >
                                                                <option  >Select</option>
                                                                <option value="1">Cash</option>
                                                                <option value="2">Bank</option>
                                                            </AvField>
                                                        </div>

                                                        <div className="col-md-12" style={(this.state.f_payment_type == 2)?{}:{display: 'none'}} >
                                                            <AvField type="select"className="custom-select" label="Bank-Branch" name="f_bank_id" id="f_bank_id" >
                                                                <option  >Select</option>
                                                                <option value="1">Cash</option>
                                                                <option value="2">Bank</option>
                                                            </AvField>
                                                        </div>

                                                        <div className="col-md-12" style={(this.state.f_payment_type == 2)?{}:{display: 'none'}} >
                                                            <AvField className="form-control" label="Cheque No"  type="text" name="cheque_info" id="cheque_info"  />
                                                        </div>

                                                        <div className="col-md-12">
                                                            <AvField className="form-control" label="Remarks"  type="textarea" name="remarks" id="remarks"  />
                                                        </div>

                                                        <div className="col-md-12">
                                                            <AvField className="form-control" label="Payment Date"  type="date" name="tran_date" id="tran_date" required />
                                                        </div>

                                                        <div className=" mt-4">
                                                                <button type="submit" className="btn btn-success save-event">Submit</button>
                                                        </div>
                                                </AvForm> 
                                                </div>
                                            </div>
                                                
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Bill;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createBill ,billPayment})(BillPayment));
