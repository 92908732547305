import { PARKING_RATE_ERROR,PARKING_RATE_SUCCESS,EDIT_PARKING_RATE,SHOW_PARKING_RATE,CREATE_PARKING_RATE } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createParkingRateInfo = (parkingRateInfo) => {
    return {
        type: CREATE_PARKING_RATE,
        payload: { parkingRateInfo }
    }
}

export const showParkingRateInfo = (parkingRateInfo_id) => {
    return {
        type: SHOW_PARKING_RATE,
        payload: { parkingRateInfo_id }
    }
}

export const editParkingRateInfo = (parkingRateInfo) => {
    return {
        type: EDIT_PARKING_RATE,
        payload: { parkingRateInfo }
    }
}



export const parkingRateInfoSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: PARKING_RATE_SUCCESS,
        payload: msg
    }
}


export function parkingRateInfoShow(parkingRateInfo_id){
    return getApi().get('parkingRateInfo/'+parkingRateInfo_id)
}


export function parkingRateInfoList(params){
    return getApi().get('parkingRateInfo/list',{
        params: params
    })
}

export function shiftSetupList(params){
    return getApi().get('shiftSetup/list',{
        params: params
    })
}

export function rateTypeList(params){
    return getApi().get('rateType/list',{
        params: params
    })
}

export function vehicleTypeList(params){
    return getApi().get('vehicleType/list',{
        params: params
    })
}



export const parkingRateInfoError = (error) => {
    return {
        type: PARKING_RATE_ERROR,
        payload: error
    }
}
