import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardHeader,CardFooter,Modal, FormGroup } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createParkIn,searchByParkInReport} from '../../../store/park/actions';
import { rateTypeList,vehicleTypeList} from '../../../store/parkingRate/actions';
import { companyInfo} from '../../../store/bill/actions';
import '../Report/tableDesign.scss';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../pages/Loader/Loader";

class ParkInReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                loading: false,
                toggleSwitch: false,
                // startDate: new Date().toISOString().split('T')[0],
                startDate: new Date(),
                endDate: new Date(),
                edittoggleSwitch: false,  
                modal_scroll: false,
                slot: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchRateTypeList();     
            this.fetchVehicleTypeList();     
            this.fetchCompanyInfo();     
    }

    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList : response.data.rateType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchVehicleTypeList = () => {
        vehicleTypeList().then((response) => {
            this.setState({
                vehicleTypeList : response.data.vehicleType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (slot) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.slot = slot;
        this.removeBodyCss();
      }


      printDiv = () => {
        var content = document.getElementById('example').innerHTML;
        var title = document.title;
        var printWindow = window.open("", "_blank", "width=800,height=600");
        printWindow.document.open();
        printWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous"><style>footer { font-size: 10px; text-align: center;} @media print { footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width: 210mm; height: 297mm; }.table-bordered th, .table-bordered td {border: 1px solid #181b1e !important;}.table th, .table td {border-top: 1px solid #181b1e !important;}</style></head><body>');
        printWindow.document.write(content);
        printWindow.document.write('<footer>Printed at: '+ new Date().toLocaleString() + '</footer></body></html>');
        printWindow.document.close();
        printWindow.focus();
        setTimeout(function () {
          printWindow.print();
          printWindow.close();
        }, 1000);
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {       
            this.state.loading = true;   
            searchByParkInReport(values).then((response) => {
                this.setState({
                    parkInData : response.data.details, 
                    parkInSummary : response.data.summary, 
                    loading: false,
                });
            })
            .catch((err) => {
                this.state.loading = false;   

                console.log(err);
            })
            // this.form && this.form.reset();
        }

        fetchCompanyInfo = () => {

            companyInfo().then((response) => {
                this.setState({
                    company: response.data
                })
            })
            .catch((err) => {
                console.log(err);
            })
            
          }

    render() {
        if (this.state.loading) return <Loader />;

        let vehicleTypeList = [];
        let rateTypeList = [];        
        let parkInData = [];        
        let parkInSummary = [];        
        let company_name = '' , address = '' , mobile = '',total_hour_spend =0 , total_billed_amount =0 , total_receive_amount =0;

        if (this.state.hasOwnProperty('vehicleTypeList')) { vehicleTypeList = this.state.vehicleTypeList; }
        if (this.state.hasOwnProperty('rateTypeList')) { rateTypeList = this.state.rateTypeList; }
        if (this.state.hasOwnProperty('parkInData')) { parkInData = this.state.parkInData; }
        if (this.state.hasOwnProperty('parkInSummary')) { parkInSummary = this.state.parkInSummary; }
        if (this.state.hasOwnProperty('company')) {
            company_name = this.state.company.name;
            address = this.state.company.address;
            mobile = this.state.company.mobile;
        }
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Park In Report" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Park In Report</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>

                                            <FormGroup row>
                                                
                                                <div className="col-md-4">
                                                    <label>Date Range</label>
                                                    <DatePicker
                                                        selected={this.state.startDate}
                                                        className="form-control" 
                                                        onChange={date => this.setState({startDate: date})}
                                                        selectsStart
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                    />
                                                    <DatePicker
                                                        selected={this.state.endDate}
                                                        className="form-control" 
                                                        onChange={date => this.setState({endDate: date})}
                                                        selectsEnd
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        minDate={this.state.startDate}
                                                    />
                                                        <AvField    type="hidden" name="start_date" id="start_date" value={this.state.startDate} />
                                                        <AvField    type="hidden" name="end_date" id="end_date" value={this.state.endDate} />

                                                    {/* <AvField className="form-control" label=" Date"  type="date" name="search_date" id="search_date" value={this.state.searchDate} onChange={(e,v)=>this.setState({searchDate: v})} required/> */}
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <AvField type="select"className="custom-select" label="Vehicle Type" name="f_vehicle_type_id" id="f_vehicle_type_id">
                                                            <option defaultValue>Select</option>
                                                            {vehicleTypeList.map((vehicle, index) =>
                                                                <option value={vehicle.id}>{vehicle.vehicle_type_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>
                                             
                                                <div className="col-md-4">
                                                    <AvField className="form-control" label="Vehile Name / Token"  type="text" name="vehicle_or_token" id="vehicle_or_token" />
                                                </div>

                                            </FormGroup>

                                                 
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Search</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader>
                                        <FormGroup row>
                                                <CardTitle> PARK IN REPORT</CardTitle>

                                                <button size="lg" style={{marginLeft: '80%'}} className="btn-sm btn-primary mr-2 pull-right" onClick={() => this.printDiv()}>PRINT</button>
                                        </FormGroup>
                                    </CardHeader>
                                    <CardBody>
                                        <div id="example">

                                                    <div className="row">
                                                        <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                            <h2 className="text-center"><b>{company_name}</b></h2>
                                                            <span className="text-center">{address}</span><br></br>
                                                            <span className="text-center">Phone: {mobile}</span>
                                                            <br></br>
                                                            <h6><u>PARK IN REPORT</u></h6>
                                                            <p>Date: {new Date(this.state.startDate).toLocaleDateString()}</p>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                    <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={2} style={{textAlign: 'center'}}>Summary</th></tr>
                                                                <tr>
                                                                    <th> Vehicle Type</th>
                                                                    <th>Total Vehicle</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkInSummary.map((summary, index) =>
                                                                <tr key={summary.id .toString()}>
                                                                    <td>{summary.vehicle_type_name_en}</td>
                                                                    <td>{summary.total_vehicle}</td>
                                                                </tr>
                                                                )}
                                                                <tr>
                                                                    <th> Total</th>
                                                                    <th>{parkInData.length}</th>
                                                                </tr>
                                                            </tbody>
                                                    </Table>
                                                    </div>
                                                    <div className="col-12">
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr>
                                                                    <th >No</th>
                                                                    <th>Token</th>
                                                                    <th>Vehicle</th>
                                                                    <th>Type</th>
                                                                    <th>Entry Time</th>
                                                                    <th>In By</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {parkInData.map((parkOut, index) =>
                                                            <tr key={parkOut.id .toString()}>
                                                                <td scope="row">{index+1}</td>
                                                                <td>{parkOut.token_no}</td>
                                                                <td>{parkOut.vehicle_name.substr(0,2)+'-'+parkOut.vehicle_name.substr(2)}</td>
                                                                <td>{parkOut.vehicle_type == null? parkOut.f_vehicle_type_id: parkOut.vehicle_type.vehicle_type_name_en}</td>
                                                                <td>{parkOut.tran_start_date_time}</td>
                                                                <td>{parkOut.insert_user==null?parkOut.insert_user_id:parkOut.insert_user.name}</td>
                                                            </tr>
                                                            )}
                                                            </tbody>
                                                        </Table>
                                                        </div>

                                                    </div>
                                            </div>
                                                
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingSlot;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createParkIn })(ParkInReport));
