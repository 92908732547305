import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_USER,USER_SUCCESS,EDIT_USER,SHOW_USER } from './actionTypes';
import { userSuccess, userError,userShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createUser({ payload: { user } }) {
    console.log('I am here');

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'user/registration', user);
             if(response.success == false){
                
                yield put(userError(response.message));
            }else if(response.success == true){
                
                yield put(userSuccess(response.data.message));
            }

          }
    } catch (error) {
        yield put(userError(error));
    }
}


function* editUser({ payload: { user } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'user/'+user.id, user);
             if(response.success == false){
                
                yield put(userError(response.message));
            }else if(response.success == true){
                
                yield put(userSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(userError(error));
    }
}


function* showUser({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'user/'+user_id);
             yield put(userShow(response));
          }
          
          
    } catch (error) {
        yield put(userError(error));
    }
}

export function* watchUser() {
    yield takeEvery(EDIT_USER, editUser)
    yield takeEvery(CREATE_USER, createUser)
    yield takeEvery(SHOW_USER, showUser)
}

function* UserSaga() {
    yield all([
        fork(watchUser),
    ]);
}

export default UserSaga;