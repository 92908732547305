import { EXPENSE_GROUP_TYPE_MAP_ERROR,EXPENSE_GROUP_TYPE_MAP_SUCCESS,EDIT_EXPENSE_GROUP_TYPE_MAP } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ExpenseGroupTypeMap = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_EXPENSE_GROUP_TYPE_MAP:
            state = { ...state }
            break;
        case EXPENSE_GROUP_TYPE_MAP_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case EXPENSE_GROUP_TYPE_MAP_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ExpenseGroupTypeMap;