import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,Label,FormGroup,CustomInput,CardHeader } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createRole,roleList,editRole,getPermissionGroupList,roleShow,getPermissionTotal} from '../../../store/role/actions';


function PermissionGroupRow(props) {
    const group = props.permission_group;
    const checked_group = props.checked_group;

    return (
        <div className="col-md-4">
             <Card outline color="info" className="border">
                <CardHeader className="bg-transparent">
                    <FormGroup check inline>
                        <CustomInput className="my-0 text-info " type="checkbox" id={group.id} name={group.id} data-id={group.id}
                                    label={group.name.split('_').join(' ').toUpperCase()+' Permission'}
                                    onChange={(e) => props.checkedGroup(e, group.name)} checked={checked_group.includes( group.id )}/>
                    </FormGroup>
                
                </CardHeader>
                <CardBody>

                
                    <Row className="border-top pt-4 pb-4">
                        {group.permissions.map((permission) => {
                            let id = `permission-${permission.id}`

                            let check_permission = props.role_permissions.filter((permission_id) => {
                                return permission.id === permission_id
                            })

                            let status = false
                            if (check_permission.length) {
                                status = true
                            }

                            return (
                                <Col sm="12" key={permission.id}>
                                    <FormGroup check inline>
                                        <CustomInput type="switch" id={id} name={id} data-id={permission.id}
                                                    label={permission.name.split('_').join(' ').toUpperCase()}
                                                    onChange={(e) => props.handleChange(e, permission.name)} checked={status}/>
                                    </FormGroup>
                                </Col>
                            )
                        })}
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}
 
class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                allPermission: false,
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                edit_mode: false,  
                modal_scroll: false,
                totalPermission: 0,
                permission_group: [],
                checked_group: [],
                role: {
                    permissions: [],
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
        this.fetchroleList();  
        this.fetchPermissionGroups();  
        this.fetchPermissionTotal();  
    }

    
    fetchPermissionTotal = () => {
        getPermissionTotal().then((response) => {
            this.setState({
                totalPermission :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchroleList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        roleList(options).then((response) => {

            this.setState({
                roleList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (role) => {
        this.setState(prevState => ({
          edit_mode: !prevState.edit_mode,
        }));

        roleShow(role.id)
            .then((response) => {
                console.log(response);

                let role = response.data.role;
                let permissions = response.data.permission.map((permission) => permission.id);
                role["permissions"] = permissions;

                this.setState({
                    role: role
                })
            })
            .catch((err) => {
                console.log(err);
            })
        // this.state.role = role;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createRole(values);
            this.form && this.form.reset();
            this.setState({
                checked_group: [],
                role: {
                    permissions: []
                },
                allPermission: false

            });
            this.fetchroleList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editRole(values);
            this.setState(prevState => ({
                edit_mode: !prevState.edit_mode,
              }));

            this.fetchroleList(); 
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchroleList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchroleList();
            });
        }

        handleChange = (e, name) => {
            let permissions= this.state.role.permissions;
    
            if (e.target.checked === true) {
                permissions.push(parseInt(e.target.dataset.id));
            } else {
                permissions =  permissions.filter(function(permission) {
                    return permission !== parseInt(e.target.dataset.id);
                });
            }
    
            let roleCopy= Object.assign({}, this.state.role);
            roleCopy["permissions"] = permissions;
    
            console.log(roleCopy);
    
            this.setState({
                role: roleCopy
            });
    
        }

        checkedGroup = (e, name) => {
            let permission_group= this.state.permission_group;
            let permissions= this.state.role.permissions;
            let checkedGroup= this.state.checked_group;
            const group_permissions = permission_group.find(element => element.id == e.target.dataset.id);

            if (e.target.checked === true) {
                checkedGroup.push(parseInt(e.target.dataset.id));

                group_permissions.permissions.forEach(value => {
                    permissions.push(parseInt(value.id));
                });
            } else {
                
                checkedGroup =  checkedGroup.filter(function(group) {
                    return group !== parseInt(e.target.dataset.id);
                });

                group_permissions.permissions.forEach(value => {
                    permissions =  permissions.filter(function(permission) {
                        return permission !== parseInt(value.id);
                    });
                });
            }
    
            let roleCopy= Object.assign({}, this.state.role);
            roleCopy["permissions"] = permissions;

            this.setState({
                checked_group: checkedGroup,
                role: roleCopy
            });
    
        }

        selectAllPermission = () => {
            let permission_group= this.state.permission_group;
            let permissions= this.state.role.permissions;
            let checkedGroup= this.state.checked_group;
            let checkValue= !this.state.allPermission;


            if (checkValue === true) {
                permission_group.forEach(element => {
                    checkedGroup.push(parseInt(element.id));
                    element.permissions.forEach(value => {
                        permissions.push(parseInt(value.id));
                    });
                });

                
            } else {
                permission_group.forEach(element => {
                    checkedGroup =  checkedGroup.filter(function(group) {
                        return group !== parseInt(element.id);
                    });

                    element.permissions.forEach(value => {
                        permissions =  permissions.filter(function(permission) {
                            return permission !== parseInt(value.id);
                        });
                    });
                });
            }
    
            let roleCopy= Object.assign({}, this.state.role);
            roleCopy["permissions"] = permissions;

            this.setState({
                checked_group: checkedGroup,
                role: roleCopy,
                allPermission: checkValue

            });

            this.setState({
            })

        }

        fetchPermissionGroups = () => {

            getPermissionGroupList()
                .then((response) => {
                    console.log(response);
                    this.setState({
                        permission_group: response.data
                    })
                })
                .catch((err) => {
                    console.log(err);
                })
        }

    render() {
      
        let roleList = [];
        
        let paginator;
        if (this.state.hasOwnProperty('roleList')) {
            roleList = this.state.roleList.data;
            paginator = {
                'currentPage':this.state.roleList.current_page,
                'pagesCount':this.state.roleList.last_page,
                'total':this.state.roleList.total,
                'to':this.state.roleList.to,
                'from':this.state.roleList.from,
            }
        }

        const {expense_group_id,expense_group_name,expense_group_name_bangla} = this.state.role;
        const {role, permission_group,totalPermission} = this.state;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }

        let authUser =  JSON.parse(localStorage.getItem("authUser"));
        let companyName = authUser.data.Company.name;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Role" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="12" md="12" style={(this.state.edit_mode == true)?{display: 'none'}:{}}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Role List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table  mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Permissions</th>
                                                        <th scope="col">Company</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {roleList.map((role, index) =>
                                                    <tr key={role.id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{role.name}</td>
                                                        <td>{totalPermission} / {role.permissions_count}  </td>
                                                        <td>{companyName}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(role) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                            </Col>
                            <Col lg="12" md="12" style={(this.state.edit_mode == false)?{display: 'none'}:{}}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Update  Role</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }} ref={c => (this.form = c)}>
                                                
                                             
                                                 <div className="col-md-6">
                                                    <AvField className="form-control" label="Role Name " required  type="text" name="name" id="name" value={role.name}/>
                                                    <AvField required  type="hidden" name="permissions" id="permissions" value={role.permissions} />
                                                     <AvField required  type="hidden" name="id" id="id" value={role.id} />
                                                 </div>
                                                 <div className="col-md-12">
                                                    <Card>
                                                        <CardHeader>
                                                            <strong>Permission Information</strong>
                                                            <small> Assign Permission</small>

                                                            
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Row>
                                                                <div className="col-md-12" style={{padding: '10px'}}>
                                                                <div className="custom-control custom-checkbox mb-3">
                                                                    <input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={this.selectAllPermission} checked={this.state.allPermission} />
                                                                    <label className="custom-control-label" onClick={this.selectAllPermission } >Select All</label>
                                                                </div>
                                                                </div>

                                                                {permission_group.map((permission, index) =>
                                                                    <PermissionGroupRow key={index} permission_group={permission} handleChange={this.handleChange} checkedGroup={this.checkedGroup} checked_group={this.state.checked_group} role_permissions={role.permissions}/>
                                                                )}
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </div>

                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Role;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createRole,editRole })(Roles));
