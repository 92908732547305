import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AsyncSelect from "react-select/async";
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createBill,searchByBillData,billList,customerSearchList,customerVehicleList,companyInfo} from '../../../store/bill/actions';
 
class BillList extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                customer_name: '',
                comission: 0,
                selectedVehicle: [],
                customer: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
        this.fetchbillList();  
        this.fetchCompanyInfo();  
    }


    fetchCompanyInfo = () => {

        companyInfo().then((response) => {
            this.setState({
                company: response.data
            })
        })
        .catch((err) => {
            console.log(err);
        })
        
      }
    
    fetchbillList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        billList(options).then((response) => {

            this.setState({
                billList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (customer) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.customer = customer;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createBill(values);
            alert('Bill Created Successfully');
            // window.location.reload();    
        }

        handleSearchByBillData(event, values) {          
            searchByBillData(values).then((response) => {

                if(response.status == true){
                    this.setState({
                        bill_data: response.data.bill
                    })
                }else{
                    alert(response.data.message);
                }
              
            })
            .catch((err) => {
                console.log(err);
            })
        }

        handleCustomerSearch( customer){
            this.setState({
                customer_id: customer.value,
                customer_name: customer.label,
                selectedVehicle: []
            })

            this.fetchCustomerVehicleList(customer.value);

        }


        onSelect = (selectedList, selectedItem)=> {
            let selected = selectedList;
            this.setState({
                selectedVehicle: selected,
              });
        }
        
        onRemove = (selectedList, removedItem)=> {
            let selected = selectedList;
            this.setState({
                selectedVehicle: selected,
              });
        }

        setStartDate = (month) => {
            this.setState({
                selected_month: month
            })
        }

        loadOptions = (inputValue, callback) => {

            customerSearchList(inputValue).then((response) => {

                const requestResults = response.data;
              
                callback(requestResults);
            })
            .catch((err) => {
                console.log(err);
            })
            
          }
        
          fetchCustomerVehicleList = (customer_id) => {

            customerVehicleList(customer_id).then((response) => {
                this.setState({
                    customerVehicleList: response.data
                })
            })
            .catch((err) => {
                console.log(err);
            })
            
          }
       

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchbillList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchbillList();
            });
        }

    render() {
      
        let bill_data = [];
        let customerVehicleList = [];
        let company_name = '' , address = '' , mobile = '';
        let total_bill = 0;

        let billList = [];
        
        let paginator;
        if (this.state.hasOwnProperty('billList')) {
            billList = this.state.billList.data;
            paginator = {
                'currentPage':this.state.billList.currentPage,
                'pagesCount':this.state.billList.pagesCount,
                'total':this.state.billList.total,
                'to':this.state.billList.to,
                'from':this.state.billList.from,
            }
        }

        if (this.state.hasOwnProperty('company')) {
            company_name = this.state.company.name;
            address = this.state.company.address;
            mobile = this.state.company.mobile;
        }

        if (this.state.hasOwnProperty('customerVehicleList')) { customerVehicleList = this.state.customerVehicleList; }

        bill_data.map(function(billValue){
            total_bill += billValue.monthly_rate;
        });

      

        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }
          
        const getStatusValue = (status) => {
            return status == 1 ? 'Yes' : 'No'
        }
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Bill List" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle> Bill List</CardTitle>
                                            <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                    
                                                    <Table striped responsive >
                                                            <thead>
                                                                <tr>
                                                                    <th>Month</th>
                                                                    <th>Bill Code</th>
                                                                    <th>Invoice  No</th>
                                                                    <th>Customer</th>
                                                                    <th>Bill Amount</th>
                                                                    <th>Comission</th>
                                                                    <th>Paid Amount</th>
                                                                    <th>Generate Date</th>
                                                                    <th>Is Closed?</th>
                                                                    <th>Action  </th> 
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {billList.map((cusVehicleMap, index) =>
                                                                <tr key={index}>
                                                                    <td>{monthNames[new Date(cusVehicleMap.bill_generate_month).getMonth()]}</td>
                                                                    <td>{cusVehicleMap.bill_code}</td>
                                                                    <td>{cusVehicleMap.invoice_number}</td>
                                                                    <td>{cusVehicleMap.customer == null?cusVehicleMap.f_customer_id: cusVehicleMap.customer.customer_name_en}</td>
                                                                    <td>{cusVehicleMap.total_bill}</td>
                                                                    <td>{cusVehicleMap.total_commision}</td>
                                                                    <td>{cusVehicleMap.total_bill_paid}</td>
                                                                    <td>{new Date(cusVehicleMap.bill_generate_date).toDateString()}</td>
                                                                    <td><Badge color={getBadge(cusVehicleMap.is_closed)}>{getStatusValue(cusVehicleMap.is_closed)}</Badge></td>
                                                                    <td>
                                                                        <Link
                                                                            className="btn btn-sm btn-primary waves-effect waves-light"
                                                                            to={`/bill/details/${cusVehicleMap.bill_id}`}
                                                                            role="button"
                                                                        >
                                                                            Details
                                                                        </Link>
                                                                        &nbsp;&nbsp; 
                                                                        <Link
                                                                            className="btn btn-sm btn-success waves-effect waves-light"
                                                                            to={`/bill/payment/${cusVehicleMap.bill_id}`}
                                                                            role="button"
                                                                        >
                                                                            Payment
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            
                                                            </tbody>
                                                        </Table>
                                                    
                                                
                                            </CardBody>
                                        {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingSlot;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createBill })(BillList));
