import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {EDIT_VIEW_DETAILS,SHOW_VIEW_DETAILS } from './actionTypes';
import { viewDetailsSuccess, viewDetailsError,viewDetailsShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* editViewDetails({ payload: { viewDetails } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'print/'+viewDetails.id, viewDetails);
             if(response.success == false){
                
                yield put(viewDetailsError(response.message));
            }else if(response.success == true){
                
                yield put(viewDetailsSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(viewDetailsError(error));
    }
}


function* showViewDetails({ payload: { viewDetails_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'viewDetails/'+viewDetails_id);
             yield put(showViewDetails(response));
          }
          
          
    } catch (error) {
        yield put(viewDetailsError(error));
    }
}

export function* watchViewDetails() {
    yield takeEvery(EDIT_VIEW_DETAILS, editViewDetails)
    yield takeEvery(SHOW_VIEW_DETAILS, showViewDetails)
}

function* ViewDetailsSaga() {
    yield all([
        fork(watchViewDetails),
    ]);
}

export default ViewDetailsSaga;