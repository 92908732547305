import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createParkIn,parkOutList,editParkIn,rateTypeList,vehicleTypeList} from '../../../store/park/actions';
import { parkingSpaceNameList} from '../../../store/parkingSpaceName/actions';
import Loader from "../../../pages/Loader/Loader";

class ParkingOutList extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                loading: true,
                parkOutList: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchparkOutList();     
            this.fetchRateTypeList();     
            this.fetchVehicleTypeList();     
            this.fetchparkingSpaceNameList();     
    }

    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList : response.data.rateType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchVehicleTypeList = () => {
        vehicleTypeList().then((response) => {
            this.setState({
                vehicleTypeList : response.data.vehicleType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkingSpaceNameList = () => {
        parkingSpaceNameList().then((response) => {
            this.setState({
                parkingSpaceNameList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkOutList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        parkOutList(options).then((response) => {
            this.setState({
                parkOutList :response.data, 
                loading: false
            });
        })
        .catch((err) => {
            this.state.loading = false;   
            console.log(err);
        })
    }

    //modal
    tog_scroll = (parkOutList) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.parkOutList = parkOutList;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createParkIn(values);
            alert('Created Successfully');
            // this.fetchparkOutList();   
            window.location.reload();    
        }

       
       handleEditSubmit(event, values) {  
            this.props.editParkIn(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            // this.fetchparkOutList();   
            alert('Updated Successfully');
            window.location.reload();    
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchparkOutList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchparkOutList();
            });
        }

    render() {
        if (this.state.loading) return <Loader />;

        let vehicleTypeList = [];
        let rateTypeList = [];
        let parkingSpaceNameList = [];
        let parkOutList = [];
        let paginator;
        if (this.state.parkOutList.hasOwnProperty('data')) {
            parkOutList = this.state.parkOutList.data;
            paginator = {
                'currentPage':this.state.parkOutList.current_page,
                'pagesCount':this.state.parkOutList.last_page,
                'total':this.state.parkOutList.total,
                'to':this.state.parkOutList.to,
                'from':this.state.parkOutList.from,
            }
        }

        if (this.state.hasOwnProperty('vehicleTypeList')) { vehicleTypeList = this.state.vehicleTypeList; }
        if (this.state.hasOwnProperty('rateTypeList')) { rateTypeList = this.state.rateTypeList; }
        if (this.state.hasOwnProperty('parkingSpaceNameList')) { parkingSpaceNameList = this.state.parkingSpaceNameList; }

        const {id,rate_info_name_en,rate_info_name_bn,f_parking_space_name_id,f_rate_type_id,f_vehicle_type_id,fixed_rate,per_hour_rate,entry_first_hour_rate} = this.state.parkOutList;
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Park Out List" />
                        {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                        <Row>
                        <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>ParkOut List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                        <div className="table-responsive">
                                            <table className="table table-centered table-nowrap mb-0">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Token</th>
                                                        <th>Vehicle</th>
                                                        <th>Type</th>
                                                        <th>Entry Time</th>
                                                        <th>Leaving Time</th>
                                                        <th>Spent Time</th>
                                                        <th>Bill Amount</th>
                                                        <th>Receive Amount</th>
                                                        <th>Device</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {parkOutList.map((parkOut, index) =>
                                                    <tr key={parkOut.id .toString()}>
                                                    <td scope="row">{index+1}</td>
                                                    <td>{parkOut.token_no}</td>
                                                    <td>{parkOut.vehicle_name.substr(0,2)+'-'+parkOut.vehicle_name.substr(2)}</td>
                                                    <td>{parkOut.vehicle_type == null? parkOut.f_vehicle_type_id: parkOut.vehicle_type.vehicle_type_name_en}</td>
                                                    <td>{parkOut.tran_start_date_time}</td>
                                                    <td>{parkOut.tran_end_date_time}</td>
                                                    <td>{parkOut.total_hour_spend} Hour</td>
                                                    <td>{parkOut.total_billed_amount}</td>
                                                    <td>{parkOut.total_receive_amount}</td>
                                                    <td><Badge color={(parkOut.device_id == 'web')?'success':'info'}>{(parkOut.device_id == 'web')?'Web':'Android'}</Badge></td>
                                                    <td>
                                                        <Button className="btn btn-sm btn-success">Details</Button>
                                                    </td>
                                                   </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingTransaction;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createParkIn,editParkIn })(ParkingOutList));
