import { CUSTOMER_VEHICLE_MAP_ERROR,CUSTOMER_VEHICLE_MAP_SUCCESS,EDIT_CUSTOMER_VEHICLE_MAP } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const CustomerVehicleMap = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_CUSTOMER_VEHICLE_MAP:
            state = { ...state }
            break;
        case CUSTOMER_VEHICLE_MAP_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case CUSTOMER_VEHICLE_MAP_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default CustomerVehicleMap;