import {Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";
import React from "react";

export class DataTabeSearch extends React.Component {

    render() {
        return (
            <Row>
                <Col md="2">
                    <FormGroup check inline>
                        <Label className="form-check-label mr-1" check htmlFor="inline-radio1">Show</Label>
                        <Input type="select" name="per_page" id="per_page" bsSize="sm" onChange={this.props.handleInputChange}>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                        </Input>
                        <Label className="form-check-label ml-1" check htmlFor="inline-radio1">Entities</Label>
                    </FormGroup>
                </Col>
                <Col md={{size: 3, offset: 7}}>
                    <FormGroup>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText><i className="fa fa-search"></i></InputGroupText>
                            </InputGroupAddon>
                            <Input type="text" id="search" name="search" placeholder="Search" onChange={this.props.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}