import { VIEW_DETAILS_ERROR,VIEW_DETAILS_SUCCESS,EDIT_VIEW_DETAILS, SHOW_VIEW_DETAILS} from './actionTypes';
import {getApi} from "../../services/httpService";

export const showViewDetails = (viewDetails_id) => {
    return {
        type: SHOW_VIEW_DETAILS,
        payload: { viewDetails_id }
    }
}

export const editViewDetails = (viewDetails) => {
    return {
        type: EDIT_VIEW_DETAILS,
        payload: { viewDetails }
    }
}

export const viewDetailsSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: VIEW_DETAILS_SUCCESS,
        payload: msg
    }
}

export const viewDetailsError = (error) => {
    return {
        type: VIEW_DETAILS_ERROR,
        payload: error
    }
}

export function detailsInfo(params){
    return getApi().get('print/list',{
        params: params
    })
}