import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,Input, FormGroup, FormText } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AsyncSelect from "react-select/async";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createParkIn,parkOutList,editParkIn,searchByRateTypeList,searchParkInVehicle,parkOutShow,parkInList} from '../../../store/park/actions';
import { companyInfo} from '../../../store/bill/actions';
import './receipt.scss';
import ParkingOutList from './ParkingOutList';

class ParkOut extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                f_vehicle_type_id: null,
                f_parking_space_name_id: null,
                f_rate_type_id: null,
                total_hour_spend: 0,
                total_billed_amount: 0,
                parkOutList: [],
                parkOutLabel: {
                    
                },
                parkingTransaction: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {     
            this.fetchparkInList();     
            this.fetchCompanyInfo();     
    }


    fetchCompanyInfo = () => {

        companyInfo().then((response) => {
            this.setState({
                company: response.data
            })
        })
        .catch((err) => {
            console.log(err);
        })
        
      }
    
    fetchparkOutDetails = (tran_id) => {
        parkOutShow(tran_id).then((response) => {
            this.setState({
                parkingTransaction : response.data.parkingTransaction, 
            });
            var date_time = new Date(); 
            var tran_start_date_time =  new Date(response.data.parkingTransaction.tran_start_date_time);
            const total_hour_spend = Math.ceil(Math.abs(date_time - tran_start_date_time)/36e5);
            var total_billed_amount = (total_hour_spend-1)*response.data.parkingTransaction.per_hour_rate + response.data.parkingTransaction.first_hourly_rate*1;
            this.setState({
                total_hour_spend : total_hour_spend, 
                total_billed_amount: total_billed_amount,
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }


    fetchparkInList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        parkInList(options).then((response) => {
            this.setState({
                parkInList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      loadOptions = (inputValue, callback) => {

        searchParkInVehicle(inputValue,{web: 1}).then((response) => {

            const requestResults = response.data;

            if(response.data.length == 1){
                this.handleVehicleSearch(response.data[0]);
                this.state.parkOutLabel = response.data[0];
            }
            this.state.parkOutList = response.data;
            callback(requestResults);
        })
        .catch((err) => {
            console.log(err);
        })
        
      }

    handleVehicleSearch( transaction){
        var trans_value = (transaction == null)?null:transaction.value;
        this.setState({
            tran_id: trans_value,
        })
        if(transaction != null){
            this.fetchparkOutDetails(trans_value);
            this.setState({ modal_scroll: true })
        }else{
            this.setState({
                parkingTransaction : {}, 
            });
        }
    }

    format(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1;
        var y = date.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    getStringInBangla( string) {
        if(Number.isInteger(string)){ string = string.toString();}
        if(string !=undefined && string.length > 0){
        let bangla_number = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
        let eng_number = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        let array_string = Array.from(string);
        array_string.forEach((element,index) => {
            if(eng_number.indexOf(element) > -1 ){
                let num_index = eng_number.indexOf(element);
                array_string[index] = bangla_number[num_index];
            }
        });

        return array_string.join("");
        }
    }
    
    printDiv = () => {
        var content = document.getElementById('invoice-POS').innerHTML;
        var title = document.title;
        var printWindow = window.open("", "_blank", "width=800,height=600");
        printWindow.document.open();
        printWindow.document.write('<html><head><title>' + title + '</title><style>footer { font-size: 7px; } @media print { footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width: 50mm; padding: 0em; },h1{font-size: 1em;}h2{font-size: 1em;line-height: 0.7em;}h3{  font-size: 0.7em;line-height: 0.7em;}h4{  font-size: 0.7em;line-height: 0.7em;}h5{  font-size: 0.5em;line-height: 0.5em;}p{  font-size: 0.5em; line-height: 0.5em; }</style></head><body>');
        printWindow.document.write(content);
        printWindow.document.write('<footer>Printed at: '+ new Date().toLocaleString() + '</footer></body></html>');
        printWindow.document.close();
        printWindow.focus();
        setTimeout(function () {
          printWindow.print();
          printWindow.close();
        }, 1000);
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            
            this.printDiv();
        }

        gateTime = (tran_start_date_time,grace_period) =>{
            let minutes = Math.abs(new Date() -new Date(tran_start_date_time))/1000/60;
            let hours = (minutes > grace_period)?(minutes - grace_period)/60:(minutes)/60;
            if(hours%1 > 0.00 &&  hours%1 < 0.51){
                hours = Math.floor(hours)+1;
            }else{
              hours = Math.floor(hours);
            }
            return hours
        }
       
       handleEditSubmit(event, values) { 
            var date_time = new Date(); 
            var tran_start_date_time =  new Date(this.state.parkingTransaction.tran_start_date_time);
            const total_hour_spend = this.gateTime(tran_start_date_time,this.state.parkingTransaction.vehicle_type.grace_period);
            var total_billed_amount = total_hour_spend*1 > this.state.parkingTransaction.vehicle_type.charge_count_hour*1 ?this.state.parkingTransaction.first_hourly_rate*1 + (total_hour_spend*1 - this.state.parkingTransaction.vehicle_type.charge_count_hour*1)*this.state.parkingTransaction.per_hour_rate*1: this.state.parkingTransaction.first_hourly_rate*1;

            values.tran_end_date_time =  this.format(date_time)+' '+date_time.getHours()+':'+date_time.getMinutes()+':'+date_time.getSeconds();   
            values.tran_end_date   =  this.format(date_time);     
            values.tran_end_time  =   date_time.getHours()+':'+date_time.getMinutes()+':'+date_time.getSeconds();   
            values.total_hour_spend = total_hour_spend;
            values.total_billed_amount = total_billed_amount;
            values.total_receive_amount = total_billed_amount;

            this.props.editParkIn(values);
            {this.props.success && this.props.success &&
                this.form && this.form.reset();
                this.setState({ modal_scroll: false });
                this.printDiv();
            }
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchparkOutList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchparkOutList();
            });
        }

    render() {
        const {id,per_hour_rate,first_hourly_rate,fixed_rate,device_id,vehicle_name,f_vehicle_type_id,f_parking_slot_id,f_parking_space_name_id,f_rate_type_id,tran_start_date_time,tran_start_date,tran_start_time,token_no,parkOutLabel,parkOutList,vehicle_type,tran_code} = this.state.parkingTransaction;
        let company_name = '' , address = '' , mobile = '';
        if (this.state.hasOwnProperty('company')) {
            company_name = this.state.company.name;
            address = this.state.company.address;
            mobile = this.state.company.mobile;
        }

        const h3Style= {padding: '5px'};
        const textCenter= {textAlign: 'center'};

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Park Out" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Park Out</CardTitle>
                                        
                                        <AvForm className="form-horizontal" >

                                                    <div className="col-md-12">
                                                        <label>Vehicle</label>
                                                        <AsyncSelect 
                                                            loadOptions={this.loadOptions}
                                                            onChange={(value) => this.handleVehicleSearch(value)}
                                                            isClearable={true}
                                                            cacheOptions
                                                            isSearchable={true}
                                                            defaultOptions={parkOutList}
                                                            defaultValue={parkOutLabel}
                                                        />
                                                        <FormText style={{background: 'yellow'}}>Search By Vehicle No / Token No </FormText>

                                                        
                                                    </div>


                                             
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="5" md="5">
                            <Card>
                                    <CardBody>
                                        <CardTitle>Park Out</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}>

                                        <div id="invoice-POS">
    
                                            <center id="top">
                                            <div class="info"> 
                                                <h4>ঢাকা দক্ষিণ সিটি কর্পোরেশন</h4>
                                                <p>নগর ভবন , ঢাকা।</p>
                                                <p>সিটি সেন্টার কার পার্কিং এর আদায় রশিদ</p>
                                                <p>পরিচালনায়ঃ জাহান এন্টারপ্রাইস</p>
                                            </div>
                                            </center>
                                             
                                                <div id="mid">
                                                    <div class="info ">
                                                        <h3 style={textCenter}>{vehicle_type == null?'':vehicle_type.vehicle_type_name_bn} - {token_no}</h3>
                                                        <p style={textCenter}> প্রবেশ ফি: ৳{this.getStringInBangla(first_hourly_rate)}</p>
                                                        <p style={textCenter}> অতিরিক্ত অবস্থান ফি: ৳{this.getStringInBangla(per_hour_rate)}</p>
                                                        <p style={textCenter}> অতিবাহিত সময়: {this.getStringInBangla(this.state.total_hour_spend)} ঘন্টা</p>
                                                        <h2 style={textCenter}>মোট ফি: ৳{this.getStringInBangla(this.state.total_billed_amount)}</h2>
                                                        <h2 style={textCenter}>গাড়ি নং: {(vehicle_name == null)?' ':vehicle_name.substr(0,2)+'-'+vehicle_name.substr(2)}</h2> 
                                                        <p style={textCenter}>{tran_code}</p>
                                                        <p style={textCenter}> প্রবেশ : {this.getStringInBangla(new Date(tran_start_date_time).toLocaleString())} </p>
                                                        <p style={textCenter}> প্রস্থান : {this.getStringInBangla(new Date().toLocaleString())} </p> 
                                                        <h5 style={textCenter}> সহযোগিতায়ঃ হোভাটা টেকনোলজিস </h5>
                                                        <p style={{textAlign: 'center',letterSpacing:'3px'}}>www.hovata.com</p>
                                                    </div>
                                                </div>
                                            </div>


                                             <div className=" mt-4">
                                                 <Button type="submit" color="danger">Print</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   size="xl"
                                   >
                                   <div className="modal-header">
                                       <h4 className="modal-title mt-0">Park Out</h4>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                    <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }} ref={c => (this.form = c)}>

                                        <div id="invoice-POS" style={{marginInline: 'auto'}}>

                                            <center id="top">
                                                <div class="info"> 
                                                        <h4 className="modal-title mt-0" style={h3Style}>Vehicle No: {(vehicle_name == null)?' ':vehicle_name.substr(0,2)+'-'+vehicle_name.substr(2)}</h4>
                                                        <h6 className="modal-title mt-0" style={h3Style}>Token No: {token_no}</h6>
                                                </div>
                                            </center>
                                            
                                            <div id="mid">
                                                <Row>
                                                    <Col md="6" style={{borderRight: "1px solid #EEE"}}>
                                                        <div class="info text-center">
                                                            <h6 style={h3Style}>Arriving</h6>
                                                            <p> 
                                                            {new Date(tran_start_date_time).toLocaleString()}
                                                            </p>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div class="info text-center">
                                                            <h6 style={h3Style}>Leaving</h6>
                                                            <p> 
                                                            {new Date().toLocaleString()}
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            
                                            <div id="bot">
                                                <div class="info text-center">
                                                    <h3 style={h3Style}>TOTAL FEE:  ৳{this.state.total_billed_amount} </h3>
                                                </div>
                                                <div class="info text-center">
                                                    <h3 style={h3Style}>TOTAL TIME:  {this.state.total_hour_spend} Hours</h3>
                                                </div>
                                                <div class="info text-center">
                                                    <h3 style={h3Style}>FIRST HOUR RATE:  ৳{first_hourly_rate} </h3>
                                                </div>
                                                <div class="info text-center">
                                                    <h3 style={h3Style}>PER HOUR RATE:  ৳{per_hour_rate} </h3>
                                                </div>
                                                <div className="col-md-12" style={h3Style}>
                                                     <AvField className="form-control" readOnly  type="number" name="total_billed_amount" id="total_billed_amount" value={this.state.total_billed_amount} />
                                                 </div>
                                                <div className="col-md-12" style={h3Style}>
                                                     <AvField className="form-control"  type="text" name="general_remarks" id="general_remarks" placeholder="Remarks" />
                                                </div>

                                            </div>
                                        </div>
                                                        <AvField  type="hidden" name="id" id="id" value={this.state.tran_id} />
                                                        <AvField  type="hidden" name="fixed_rate" id="fixed_rate" value={fixed_rate}  />
                                                        <AvField  type="hidden" name="device_id" id="device_id" value={device_id}  />
                                                        <AvField  type="hidden" name="f_in_out_info" id="f_in_out_info" value={2}  />
                                                        <AvField  type="hidden" name="first_hourly_rate" id="first_hourly_rate" value={first_hourly_rate}  />
                                                        <AvField  type="hidden" name="per_hour_rate" id="per_hour_rate" value={per_hour_rate}  />
                                                        <AvField  type="hidden" name="f_parking_slot_id" id="f_parking_slot_id" value={f_parking_slot_id}  />
                                                        <AvField  type="hidden" name="f_rate_type_id" id="f_rate_type_id" value={f_rate_type_id}  />
                                                        <AvField  type="hidden" name="f_vehicle_type_id" id="f_vehicle_type_id" value={f_vehicle_type_id}  />
                                                        <AvField  type="hidden" name="f_parking_space_name_id" id="f_parking_space_name_id" value={f_parking_space_name_id}  />
                                                        <AvField  type="hidden" name="vehicle_name" id="vehicle_name" value={vehicle_name}  />
                                                        <AvField  type="hidden" name="token_no" id="token_no" value={token_no}  />
                                                        <AvField  type="hidden" name="tran_start_date_time" id="tran_start_date_time" value={tran_start_date_time}  />
                                                        <AvField  type="hidden" name="tran_start_date" id="tran_start_date" value={tran_start_date}  />
                                                        <AvField  type="hidden" name="tran_start_time" id="tran_start_time" value={tran_start_time}  />

                                            <div className=" col-md-12 text-center">
                                                 <Button type="submit" color="success">Park Out</Button>
                                            </div>
                                        </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="12" md="12">
                                <ParkingOutList />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingTransaction;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createParkIn,editParkIn })(ParkOut));
