import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_EXPENSE_GROUP_TYPE_MAP,EXPENSE_GROUP_TYPE_MAP_SUCCESS,EDIT_EXPENSE_GROUP_TYPE_MAP,SHOW_EXPENSE_GROUP_TYPE_MAP } from './actionTypes';
import { expenseGroupTypeMapSuccess, expenseGroupTypeMapError,expenseGroupTypeMapShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createExpenseGroupTypeMap({ payload: { expenseGroupTypeMap } }) {
    console.log('I am here');

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'expenseGroupTypeMap/create', expenseGroupTypeMap);
             if(response.success == false){
                
                yield put(expenseGroupTypeMapError(response.message));
            }else if(response.success == true){
                
                yield put(expenseGroupTypeMapSuccess(response.data.message));
            }

          }
    } catch (error) {
        yield put(expenseGroupTypeMapError(error));
    }
}


function* editExpenseGroupTypeMap({ payload: { expenseGroupTypeMap } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'expenseGroupTypeMap/'+expenseGroupTypeMap.expense_group_type_map_id, expenseGroupTypeMap);
             if(response.success == false){
                
                yield put(expenseGroupTypeMapError(response.message));
            }else if(response.success == true){
                
                yield put(expenseGroupTypeMapSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(expenseGroupTypeMapError(error));
    }
}


function* showExpenseGroupTypeMap({ payload: { expense_group_type_map_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'expenseGroupTypeMap/'+expense_group_type_map_id);
             yield put(expenseGroupTypeMapShow(response));
          }
          
          
    } catch (error) {
        yield put(expenseGroupTypeMapError(error));
    }
}

export function* watchExpenseGroupTypeMap() {
    yield takeEvery(EDIT_EXPENSE_GROUP_TYPE_MAP, editExpenseGroupTypeMap)
    yield takeEvery(CREATE_EXPENSE_GROUP_TYPE_MAP, createExpenseGroupTypeMap)
    yield takeEvery(SHOW_EXPENSE_GROUP_TYPE_MAP, showExpenseGroupTypeMap)
}

function* ExpenseGroupTypeMapSaga() {
    yield all([
        fork(watchExpenseGroupTypeMap),
    ]);
}

export default ExpenseGroupTypeMapSaga;