import { VIEW_DETAILS_ERROR,VIEW_DETAILS_SUCCESS,EDIT_VIEW_DETAILS } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ViewDetails = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_VIEW_DETAILS:
            state = { ...state }
            break;
        case VIEW_DETAILS_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case VIEW_DETAILS_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ViewDetails;