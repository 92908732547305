import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_EXPENSE_TYPE,EXPENSE_TYPE_SUCCESS,EDIT_EXPENSE_TYPE,SHOW_EXPENSE_TYPE } from './actionTypes';
import { expenseTypeSuccess, expenseTypeError,expenseTypeShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createExpenseType({ payload: { expenseType } }) {
    console.log('I am here');

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'expenseType/create', expenseType);
             if(response.success == false){
                
                yield put(expenseTypeError(response.message));
            }else if(response.success == true){
                
                yield put(expenseTypeSuccess(response.data.message));
            }

          }
    } catch (error) {
        yield put(expenseTypeError(error));
    }
}


function* editExpenseType({ payload: { expenseType } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'expenseType/'+expenseType.expense_type_id, expenseType);
             if(response.success == false){
                
                yield put(expenseTypeError(response.message));
            }else if(response.success == true){
                
                yield put(expenseTypeSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(expenseTypeError(error));
    }
}


function* showExpenseType({ payload: { expense_type_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'expenseType/'+expense_type_id);
             yield put(expenseTypeShow(response));
          }
          
          
    } catch (error) {
        yield put(expenseTypeError(error));
    }
}

export function* watchExpenseType() {
    yield takeEvery(EDIT_EXPENSE_TYPE, editExpenseType)
    yield takeEvery(CREATE_EXPENSE_TYPE, createExpenseType)
    yield takeEvery(SHOW_EXPENSE_TYPE, showExpenseType)
}

function* ExpenseTypeSaga() {
    yield all([
        fork(watchExpenseType),
    ]);
}

export default ExpenseTypeSaga;