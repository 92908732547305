import { PARKING_SPACE_NAME_ERROR,PARKING_SPACE_NAME_SUCCESS,EDIT_PARKING_SPACE_NAME } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ParkingSpaceName = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_PARKING_SPACE_NAME:
            state = { ...state }
            break;
        case PARKING_SPACE_NAME_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case PARKING_SPACE_NAME_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ParkingSpaceName;