import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PARKING_SLOT,PARKING_SLOT_SUCCESS,EDIT_PARKING_SLOT,SHOW_PARKING_SLOT } from './actionTypes';
import { parkingSlotSuccess, parkingSlotError,parkingSlotShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createParkingSlot({ payload: { parkingSlot } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'parkingSlot/create', parkingSlot);
                console.log(' parkingSlotSuccess res',response)
            if(response.success == false){
                alert(response.message);
                yield put(parkingSlotError(response.message));
            }else if(response.success == true){
                
                yield put(parkingSlotSuccess(response.data.message));
            }
          }
    } catch (error) {
        yield put(parkingSlotError(error));
    }
}


function* editParkingSlot({ payload: { parkingSlot } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'parkingSlot/'+parkingSlot.id, parkingSlot);
             if(response.success == false){
                alert(response.message);
                yield put(parkingSlotError(response.message));
            }else if(response.success == true){
                
                yield put(parkingSlotSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(parkingSlotError(error));
    }
}


function* showParkingSlot({ payload: { parkingSlot_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'parkingSlot/'+parkingSlot_id);
             yield put(parkingSlotShow(response));
          }
          
          
    } catch (error) {
        yield put(parkingSlotError(error));
    }
}

export function* watchParkingSlot() {
    yield takeEvery(EDIT_PARKING_SLOT, editParkingSlot)
    yield takeEvery(CREATE_PARKING_SLOT, createParkingSlot)
    yield takeEvery(SHOW_PARKING_SLOT, showParkingSlot)
}

function* ParkingSlotSaga() {
    yield all([
        fork(watchParkingSlot),
    ]);
}

export default ParkingSlotSaga;