import { EXPENSE_GROUP_ERROR,EXPENSE_GROUP_SUCCESS,EDIT_EXPENSE_GROUP,SHOW_EXPENSE_GROUP,CREATE_EXPENSE_GROUP } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createExpenseGroup = (expenseGroup) => {
    return {
        type: CREATE_EXPENSE_GROUP,
        payload: { expenseGroup }
    }
}

export const showExpenseGroup = (expense_group_id) => {
    return {
        type: SHOW_EXPENSE_GROUP,
        payload: { expense_group_id }
    }
}

export const editExpenseGroup = (expenseGroup) => {
    return {
        type: EDIT_EXPENSE_GROUP,
        payload: { expenseGroup }
    }
}



export const expenseGroupSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: EXPENSE_GROUP_SUCCESS,
        payload: msg
    }
}


export function expenseGroupShow(expense_group_id){
    return getApi().get('expenseGroup/'+expense_group_id)
}


export function expenseGroupList(params){
    return getApi().get('expenseGroup/list',{
        params: params
    })
}

export function expenseGroupSearchList(keyword,params){
    return getApi().get('expenseGroup/search/'+keyword,{
        params: params
    })
}



export const expenseGroupError = (error) => {
    return {
        type: EXPENSE_GROUP_ERROR,
        payload: error
    }
}
