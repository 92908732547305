import { SHOW_DASHBOARD,SHOW_DASHBOARD_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";







export function dashboardDataShow(){
    return getApi().get('dashboard/list');
}


export const dashboardDataShowError = (error) => {
    return {
        type: SHOW_DASHBOARD_ERROR,
        payload: error
    }
}