import { EXPENSE_GROUP_ERROR,EXPENSE_GROUP_SUCCESS,EDIT_EXPENSE_GROUP } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ExpenseGroup = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_EXPENSE_GROUP:
            state = { ...state }
            break;
        case EXPENSE_GROUP_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case EXPENSE_GROUP_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ExpenseGroup;