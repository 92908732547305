import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,Label,FormGroup,Input } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createParkingSpaceName,parkingSpaceNameList,editParkingSpaceName,getDistrictDropdown,getThanaDropdown,getDivisionDropdown} from '../../../store/parkingSpaceName/actions';

class ParkingSpaceNameCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                parkingSpaceName: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    districtHandleChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
         this.fetchDistrict(event.target.value);
        // alert(event.target.value);
        //  this.updateEmployeeObject(name, value);
      }
      
    thanaHandleChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
         this.fetchThana(event.target.value);
        //  this.updateEmployeeObject(name, value);
      }
      
fetchDistrict = (value) => {

  getDistrictDropdown(value)
      .then((response) => {
          this.setState({
              district: response.data
          })
      }, (respose) => {

      })
      .catch((err) => {
          console.log(err);
      })
}


fetchThana = (value) => {

  getThanaDropdown(value)
      .then((response) => {
           console.log("thana",response);
          this.setState({
              thana: response.data
          })
      }, (respose) => {

      })
      .catch((err) => {
          console.log(err);
      })

}

fetchDivision = () => {

    getDivisionDropdown()
        .then((response) => {
             console.log("division",response);
            this.setState({
                division: response.data
            })
        }, (respose) => {
  
        })
        .catch((err) => {
            console.log(err);
        })
  
  }

    componentDidMount()
    {
            this.fetchparkingSpaceNameList();   
            
            this.fetchDivision();         
    }

    fetchparkingSpaceNameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        parkingSpaceNameList(options).then((response) => {
            this.setState({
                parkingSpaceNameList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (parkingSpaceName) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.parkingSpaceName = parkingSpaceName;
        this.fetchDistrict(parkingSpaceName.f_division_id);
        this.fetchThana(parkingSpaceName.f_zilla_id);
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }


      autoSearchInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
        // this.updateDesignationObject(name, value);
      }
      
      // handleValidSubmit
        handleValidSubmit(event, values) {       
            this.props.createParkingSpaceName(values);

            this.form && this.form.reset();
            this.fetchparkingSpaceNameList();    
        }
       
       handleEditSubmit(event, values) {  
            this.props.editParkingSpaceName(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            // this.fetchparkingSpaceNameList();  
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchparkingSpaceNameList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchparkingSpaceNameList();
            });
        }

    render() {
        let divisionList = [];
        let districtList = [];
        let thanaList = [];

        if (this.state.hasOwnProperty('division')) {
            divisionList =  this.state.division;
          }

        if (this.state.hasOwnProperty('district')) {
            districtList =  this.state.district;
          }

        if (this.state.hasOwnProperty('thana')) {
            thanaList =  this.state.thana;
          }
        let parkingSpaceNameList = [];
        let paginator;
        if (this.state.hasOwnProperty('parkingSpaceNameList')) {
            parkingSpaceNameList = this.state.parkingSpaceNameList.data;
            paginator = {
                'currentPage':this.state.parkingSpaceNameList.currentPage,
                'pagesCount':this.state.parkingSpaceNameList.pagesCount,
                'total':this.state.parkingSpaceNameList.total,
                'to':this.state.parkingSpaceNameList.to,
                'from':this.state.parkingSpaceNameList.from,
            }
        }
        const {id,space_name_bn,space_name_en,f_division_id,f_zilla_id,f_thana_id,address} = this.state.parkingSpaceName;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Parking Space" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Parking Space </CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Parking Space Name"  id="space_name_en" name="space_name_en"  required/>
                                                     {/* <AvField name="id" id="id" value={id}  type="hidden"  /> */}
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Parking Space Name(Bangla)"  id="space_name_bn" name="space_name_bn"/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="textarea" label="Address"  id="address" name="address" value={address} required/>
                                                 </div>
                                                <Row >

                                                 <div className="form-group  col-md-4 row" style={{marginLeft: '10px'}}>
                                                    <AvField type="select"className="custom-select" label="Select Division" name="f_division_id" id="f_division_id" onChange={(e) => this.districtHandleChange(e)} className="form-control" required>
                                                            <option defaultValue>Select Division</option>
                                                            {divisionList.map((division, index) =>
                                                        <option  value={division.division_id}>{division.division_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>
                                                <div className="form-group col-md-4 row" style={{marginLeft: '10px'}}>
                                                    <AvField type="select" className="custom-select" label="Select District" name="f_zilla_id" id="f_zilla_id"  onChange={(e) => this.thanaHandleChange(e)} className="form-control" required>
                                                        <option defaultValue>Select District</option>
                                                            {districtList.map((district, index) =>
                                                        <option value={district.district_id}>{district.district_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-4 row" style={{marginLeft: '10px'}}>
                                                    <AvField type="select" className="custom-select" label="Select Thana" name="f_thana_id" id="f_thana_id" onChange={(e) => this.autoSearchInputChange(e)} className="form-control" required>
                                                        <option defaultValue>Select Thana</option>
                                                        {thanaList.map((thana, index) =>
                                                        <option value={thana.thana_id}>{thana.thana_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>
                                                </Row>
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                        </Col>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Parking Space List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Space Name</th>
                                                        <th>Address</th>
                                                        <th>Division</th>
                                                        <th>District</th>
                                                        <th>Thana</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {parkingSpaceNameList.map((parkingSpaceName, index) =>
                                                    <tr key={parkingSpaceName.id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{parkingSpaceName.space_name_en}</td>
                                                        <td>{parkingSpaceName.address}</td>
                                                        <td>{parkingSpaceName.division== null?parkingSpaceName.f_division_id:parkingSpaceName.division.division_name}</td>
                                                        <td>{parkingSpaceName.district==null?parkingSpaceName.f_zilla_id:parkingSpaceName.district.district_name}</td>
                                                        <td>{parkingSpaceName.thana==null?parkingSpaceName.f_thana_id:parkingSpaceName.thana.thana_name}</td>
                                                        {/* <td>{parkingSpaceName.geo_address}</td>
                                                        <td>{parkingSpaceName.lattitude}</td>
                                                        <td>{parkingSpaceName.longtitude}</td> */}
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(parkingSpaceName) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{space_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Parking Space Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Parking Space Name "  id="space_name_en" name="space_name_en" value={space_name_en} />
                                                     <AvField name="id" id="id" value={id}  type="hidden"  />
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Parking Space Name(Bangla)"  id="space_name_bn" name="space_name_bn" value={space_name_bn} />
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="textarea" label="Address"  id="address" name="address" value={address} />
                                                 </div>
                                                <Row >
                                                 <div className="form-group col-md-3" style={{marginLeft: '10px'}}>
                                                    <AvField type="select"className="custom-select" label="Select Division" name="f_division_id" id="f_division_id" value={f_division_id}  onChange={(e) => this.districtHandleChange(e)} className="form-control" required>
                                                            <option defaultValue>Select Division</option>
                                                            {divisionList.map((division, index) =>
                                                        <option  value={division.division_id}>{division.division_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-4" style={{marginLeft: '10px'}}>
                                                    <AvField type="select" className="custom-select" label="Select District" name="f_zilla_id" id="f_zilla_id" value={f_zilla_id}  onChange={(e) => this.thanaHandleChange(e)} className="form-control" required>
                                                        <option defaultValue>Select District</option>
                                                            {districtList.map((district, index) =>
                                                        <option  value={district.district_id}>{district.district_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-4" style={{marginLeft: '10px'}}>
                                                    <AvField type="select" className="custom-select" label="Select Thana" name="f_thana_id" id="f_thana_id" value={f_thana_id}  onChange={(e) => this.autoSearchInputChange(e)} className="form-control" required>
                                                        <option defaultValue>Select Thana</option>
                                                        {thanaList.map((thana, index) =>
                                                        <option  value={thana.thana_id}>{thana.thana_name}</option>
                                                      )}
                                                    </AvField>
                                                </div>
                                                </Row>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ParkingSpaceName;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createParkingSpaceName,editParkingSpaceName })(ParkingSpaceNameCreate));
