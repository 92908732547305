import { DEVICE_SETTINGS_ERROR,DEVICE_SETTINGS_SUCCESS,EDIT_PARK_DEVICE_SETTINGS,SHOW_DEVICE_SETTINGS,CREATE_PARK_DEVICE_SETTINGS } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createParkDeviceSettings = (ParkdeviceSettings) => { 
    return {
        type: CREATE_PARK_DEVICE_SETTINGS,
        payload: { ParkdeviceSettings }
    }
}

export const showDeviceSettings = (deviceSettings_id) => {
    return {
        type: SHOW_DEVICE_SETTINGS,
        payload: { deviceSettings_id }
    }
}

export const editParkDeviceSettings = (park_Device_Settings) => {
    return {
        type: EDIT_PARK_DEVICE_SETTINGS,
        payload: { park_Device_Settings }
    }
}



export const deviceSettingsSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: DEVICE_SETTINGS_SUCCESS,
        payload: msg
    }
}


export function deviceSettingsShow(deviceSettings_id){
    return getApi().get('deviceSettings/'+deviceSettings_id)
}


export function getParkDeviceSettingsList(params){
    return getApi().get('ParkDeviceAuthorizationSettings/list',{
        params: params
    })
}

export function getRateList(park_info_id){
    return getApi().get('ParkRate/rate/info/'+park_info_id)
}

export function userList(params){
    return getApi().get('user/list',{
        params: params
    })
}


export const deviceSettingsError = (error) => {
    return {
        type: DEVICE_SETTINGS_ERROR,
        payload: error
    }
}
