import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,Input, FormGroup, FormText } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import AsyncSelect from "react-select/async";
import barcode from  "../../../assets/images/barcode.png";
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {editViewDetails,detailsInfo} from '../../../store/viewDetails/actions';
import { companyInfo} from '../../../store/bill/actions';
// import './rece/ipt.scss';
// Form Editor
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw } from 'draft-js';
import Parser from 'html-react-parser';
import {stateToHTML} from 'draft-js-export-html';

class ViewDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                f_vehicle_type_id: null,
                f_parking_space_name_id: null,
                f_rate_type_id: null,
                total_hour_spend: 0,
                total_bn: 0,
                parkOutList: [],
                parkOutLabel: {
                    
                },
                ViewDetails: {
                    
                },
                editorState: EditorState.createEmpty(),
                noteState: EditorState.createEmpty(),
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {        
            this.fetchCompanyInfo();
            this.fetchDetailsInfo();     
    }


    fetchCompanyInfo = () => {

        companyInfo().then((response) => {
            this.setState({
                company: response.data
            })
        })
        .catch((err) => {
            console.log(err);
        })
        
      }

      fetchDetailsInfo = () => {

        detailsInfo().then((response) => {
            this.setState({
                ViewDetails: response.data,
                editorState : EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(response.data.address_bn)
                    )
                ),
                noteState : EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(response.data.note_bn)
                    )
                )
            })
        })
        .catch((err) => {
            console.log(err);
        })
        
      }


      onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
        console.log(editorState.getCurrentContent());
        this.state.ViewDetails.address_bn = stateToHTML(editorState.getCurrentContent());
      };

      
      onNoteStateChange = (noteState) => {
        this.setState({
          noteState,
        });
        this.state.ViewDetails.note_bn = stateToHTML(noteState.getCurrentContent());
      };
    //   removeBodyCss() {
    //     document.body.classList.add("no_padding");
    //   }

    //   loadOptions = (inputValue, callback) => {

    //     searchParkInVehicle(inputValue,{web: 1}).then((response) => {

    //         const requestResults = response.data;

    //         if(response.data.length == 1){
    //             this.handleVehicleSearch(response.data[0]);
    //             this.state.parkOutLabel = response.data[0];
    //         }
    //         this.state.parkOutList = response.data;
    //         callback(requestResults);
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     })
        
    //   }

    format(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1;
        var y = date.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    getStringInBangla( string) {
        if(Number.isInteger(string)){ string = string.toString();}
        if(string !=undefined && string.length > 0){
        let bangla_number = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
        let eng_number = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        let array_string = Array.from(string);
        array_string.forEach((element,index) => {
            if(eng_number.indexOf(element) > -1 ){
                let num_index = eng_number.indexOf(element);
                array_string[index] = bangla_number[num_index];
            }
        });

        return array_string.join("");
        }
    }
    
    printDiv = () => {
        var content = document.getElementById('invoice-POS').innerHTML;
        var title = document.title;
        var printWindow = window.open("", "_blank", "width=800,height=600");
        printWindow.document.open();
        printWindow.document.write('<html><head><title>' + title + '</title><style>footer { font-size: 7px; } @media print { footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width: 50mm; padding: 0em; },h1{font-size: 1em;}h2{font-size: 1em;line-height: 0.7em;}h3{  font-size: 0.7em;line-height: 0.7em;}h4{  font-size: 0.7em;line-height: 0.7em;}h5{  font-size: 0.5em;line-height: 0.5em;}p{  font-size: 0.5em; line-height: 0.5em; }</style></head><body>');
        printWindow.document.write(content);
        printWindow.document.write('<footer>Printed at: '+ new Date().toLocaleString() + '</footer></body></html>');
        printWindow.document.close();
        printWindow.focus();
        setTimeout(function () {
          printWindow.print();
          printWindow.close();
        }, 1000);
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            
            this.printDiv();
        }

        set_modal = () => {
            this.setState({
                modal_scroll: !this.state.modal_scroll
            })
        }


    handleEditSubmit(event, values) {  
        values = Object.entries(values).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {})
        this.props.editViewDetails(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
          }));
   }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchparkOutList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchparkOutList();
            });
        }

    render() {
        const {id,company_name_en,company_name_bn,address_en,address_bn,sub_address_en,sub_address_bn,managed_by_en,managed_by_bn,note_en,note_bn,first_hour_no_en,first_hour_no_bn,enter_fee_en,enter_fee_bn,extra_stay_fee_en,extra_stay_fee_bn,vehicle_name_en,vehicle_name_bn,insert_date_time,token_no_en,token_no_bn,total_en,total_bn,vehicle_no_en,vehicle_no_bn,extra_time_stay_en,extra_time_stay_bn,service_bn} = this.state.ViewDetails;
        let company_name = '' , address = '' , mobile = '';
        if (this.state.hasOwnProperty('company')) {
            company_name = this.state.company.name;
            address = this.state.company.address;
            mobile = this.state.company.mobile;
        }

        const h3Style= {padding: '5px'};
        const textCenter= {textAlign: 'center'};


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="View Details" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}

                            <div class="row ">
                                <div class="col-3 md-5">
                                <Card>
                                    <div style={{marginTop:'15px',marginRight:'10px'}}>
                                    <button  className="btn btn-primary float-right"  onClick={() => { this.set_modal() }} ><i className="fas fa-edit"></i></button>  
                                    </div>
                                    <CardBody>
                                        <br></br>
                                        {/* <div id="invoice-POS" > */}
                                            <center id="top">
                                            <div class="info"> 
                                                <h4>{company_name_bn}</h4>
                                                <p style={textCenter}>{address_bn == undefined?'':Parser(address_bn)}</p>
                                                <br></br>
                                            </div>
                                            </center>
                                             
                                                <div id="mid">
                                                    <div class="info ">
                                                        <p style={{marginTop:'-20px',textAlign: 'center',fontSize:'15px'}}><b>বাই সাইকেল -A1</b></p>
                                                        <p style={{marginTop:'-15px',textAlign: 'center'}}>{enter_fee_bn} ৳১০</p>
                                                        <p style={{marginTop:'-15px',textAlign: 'center'}}>{extra_stay_fee_bn} ৳২০</p>
                                                        <p style={{marginTop:'-15px',textAlign: 'center'}}>{extra_time_stay_bn} ২ ঘন্টা</p>
                                                        <h3 style={{marginTop:'-15px',textAlign: 'center'}}><b>{total_bn} ৳৬০</b></h3>
                                                        <h3 style={textCenter}><b>{vehicle_no_bn} 00-00A1</b></h3>
                                                        <p style={{textAlign: 'center',letterSpacing:'4px'}}>A10000A110000</p>
                                                        <p style={{marginTop:'-15px',textAlign: 'center'}}> প্রবেশ : ৫/৩০/২০২১, ১২:১৮:৪৫ PM </p>
                                                        <p style={{marginTop:'-15px',textAlign: 'center'}}> প্রস্থান : ৫/৩০/২০২১, ১২:১৮:৪৫ PM </p> 
                                                        <h6 style={{marginTop:'-10px',textAlign: 'center'}}><b> সহযোগিতায়ঃ হোভাটা টেকনোলজিস </b></h6>
                                                        <p style={{textAlign: 'center',letterSpacing:'6px'}}>www.hovata.com</p>
                                                    </div>
                                                </div>
                                            {/* </div> */}


                                             {/* <div className=" mt-4">
                                                 <Button type="submit" color="danger">Print</Button>
                                             </div> */}
                                    </CardBody>
                                </Card>
                                </div>
                            <div class="col-3 md-5"> 
                                <Card >
                                        <div style={{marginTop:'15px',marginRight:'10px'}}>
                                        <button  className="btn btn-primary float-right"  onClick={() => { this.set_modal() }} ><i className="fas fa-edit"></i></button>  
                                        </div>
                                    <CardBody>
                                        <br></br>
                                        {/* <div id="invoice-POS" > */}

                                            <center id="top">
                                            <div class="info"> 
                                                <h4>{company_name_bn}</h4>
                                                <p style={textCenter}>{address_bn == undefined?'':Parser(address_bn)}</p>
                                                <br></br>
                                            </div>
                                            </center>
                                             
                                                <div id="mid">
                                                    <div class="info ">
                                                        <p style={{marginTop:'-15px',textAlign: 'center',fontSize:'15px'}}><b>বাই সাইকেল</b></p>
                                                        <p style={{marginTop:'-15px',textAlign: 'center'}}> প্রবেশ সময় : {this.getStringInBangla(new Date(insert_date_time).toLocaleString())} </p>
                                                        <h3 style={{marginTop:'-15px',textAlign: 'center'}}><b>{token_no_bn} A5</b></h3>
                                                        <h3 style={textCenter}><b>{vehicle_no_bn} 00-00A1</b></h3>
                                                        <img src={barcode} width="250" height="50"></img>
                                                        <p style={{textAlign: 'center',letterSpacing:'9px'}}>A10000A110000</p> 
                                                        <p style={{textAlign: 'center', fontSize: '11px'}} >{note_bn == undefined?'':Parser(note_bn)}</p>
                                                        <h6 style={{marginTop:'-10px',textAlign: 'center'}}><b> সহযোগিতায়ঃ হোভাটা টেকনোলজিস </b></h6>
                                                        <p style={{textAlign: 'center',letterSpacing:'6px'}}>www.hovata.com</p>
                                                    </div>
                                                </div>
                                            {/* </div> */}


                                             {/* <div className=" mt-4">
                                                 <Button type="submit" color="danger">Print</Button>
                                             </div> */}
                                    </CardBody>
                                </Card>
                                    </div>
                                </div>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   size="lg"
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0" style={textCenter}> Update Print Setup</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                              View Details Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <div className="col-md-12">
                                                     {/* <AvField className="form-control" type="texteditor" label="Company Name"  id="company_name_en" name="company_name_en" value={company_name_en} /> */}
                                                     <AvField className="form-control" type="hidden"  id="id" name="id" value={id} />
                                                </div>
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Company Name (Bangla)"  id="company_name_bn" name="company_name_bn" value={company_name_bn} />
                                                </div>
                                                <div className="col-md-12">
                                                    
                                                     <AvField className="form-control" type="hidden" label="Address"  id="address_bn" name="address_bn" value={address_bn} />
                                                     <Editor
                                                            editorStyle={{ height: "100px" }}
                                                            editorState={this.state.editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            onEditorStateChange={this.onEditorStateChange}
                                                        />
                                                </div>

                                                {/* <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Address(Bangla)"  id="address_en" name="address_en" value={address_en} />
                                                </div> */}
                                                {/* <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Enter Fee"  id="enter_fee_en" name="enter_fee_en" value={enter_fee_en} />
                                                </div>
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Enter Fee(Bangla)"  id="enter_fee_bn" name="enter_fee_bn" value={enter_fee_bn} />
                                                </div> */}
                                                {/* <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Extra Stay Fee"  id="extra_stay_fee_en" name="extra_stay_fee_en" value={extra_stay_fee_en} />
                                                </div>
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Extra Stay Fee(Bangla)"  id="extra_stay_fee_bn" name="extra_stay_fee_bn" value={extra_stay_fee_bn} />
                                                </div>

                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Extra Stay Hour"  id="extra_time_stay_en" name="extra_time_stay_en" value={extra_time_stay_en} />
                                                </div>
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Extra Stay Hour(Bangla)"  id="extra_time_stay_bn" name="extra_time_stay_bn" value={extra_time_stay_bn} />
                                                </div> */}

                                                {/* <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Total Fee"  id="total_en" name="total_en" value={total_en} />
                                                </div> */}
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Total Fee(Bangla)"  id="total_bn" name="total_bn" value={total_bn} />
                                                </div>

                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Service (Bangla)"  id="service_bn" name="service_bn" value={service_bn} />
                                                </div>

                                                {/* <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Vehicle No"  id="vehicle_no_en" name="vehicle_no_en" value={vehicle_no_en} />
                                                </div> */}
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Vehicle No(Bangla)"  id="vehicle_no_bn" name="vehicle_no_bn" value={vehicle_no_bn} />
                                                </div>

                                                {/* <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Token No"  id="token_no_en" name="token_no_en" value={token_no_en} />
                                                </div>
                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Token No(Bangla)"  id="token_no_bn" name="token_no_bn" value={token_no_bn} />
                                                </div> */}

                                                <div className="col-md-12">
                                                     <AvField className="form-control" type="hidden" label="Note(Bangla)"  id="note_bn" name="note_bn" value={note_bn} />
                                                     <Editor editorStyle={{ height: "100px" }}
                                                            editorState={this.state.noteState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            onEditorStateChange={this.onNoteStateChange}
                                                        />
                                                </div>
                                                {/* <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Note(Bangla)"  id="note_en" name="note_en" value={note_en} />
                                                </div> */}
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ViewDetails;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, {editViewDetails })(ViewDetails));
