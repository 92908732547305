import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_EXPENSE_TRANSACTION,EXPENSE_TRANSACTION_SUCCESS,EDIT_EXPENSE_TRANSACTION,SHOW_EXPENSE_TRANSACTION } from './actionTypes';
import { expenseTransactionSuccess, expenseTransactionError,expenseTransactionShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createExpenseTransaction({ payload: { expenseTransaction } }) {
    console.log('I am here');

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'expenseTransaction/create', expenseTransaction);
             if(response.success == false){
                
                yield put(expenseTransactionError(response.message));
            }else if(response.success == true){
                
                yield put(expenseTransactionSuccess(response.data.message));
            }

          }
    } catch (error) {
        yield put(expenseTransactionError(error));
    }
}


function* editExpenseTransaction({ payload: { expenseTransaction } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'expenseTransaction/'+expenseTransaction.expense_transaction_no, expenseTransaction);
             if(response.success == false){
                
                yield put(expenseTransactionError(response.message));
            }else if(response.success == true){
                
                yield put(expenseTransactionSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(expenseTransactionError(error));
    }
}


function* showExpenseTransaction({ payload: { expense_transaction_no } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'expenseTransaction/'+expense_transaction_no);
             yield put(expenseTransactionShow(response));
          }
          
          
    } catch (error) {
        yield put(expenseTransactionError(error));
    }
}

export function* watchExpenseTransaction() {
    yield takeEvery(EDIT_EXPENSE_TRANSACTION, editExpenseTransaction)
    yield takeEvery(CREATE_EXPENSE_TRANSACTION, createExpenseTransaction)
    yield takeEvery(SHOW_EXPENSE_TRANSACTION, showExpenseTransaction)
}

function* ExpenseTransactionSaga() {
    yield all([
        fork(watchExpenseTransaction),
    ]);
}

export default ExpenseTransactionSaga;