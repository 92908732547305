import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_ROLE,ROLE_SUCCESS,EDIT_ROLE,SHOW_ROLE } from './actionTypes';
import { roleSuccess, roleError,roleShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createRole({ payload: { role } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'user/role/create', role);
             if(response.success == false){
                
                yield put(roleError(response.message));
            }else if(response.success == true){
                
                yield put(roleSuccess(response.data.message));
            }

          }
    } catch (error) {
        yield put(roleError(error));
    }
}


function* editRole({ payload: { role } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'user/role/'+role.id, role);
             if(response.success == false){
                
                yield put(roleError(response.message));
            }else if(response.success == true){
                
                yield put(roleSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(roleError(error));
    }
}


function* showRole({ payload: { role_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'role/'+role_id);
             yield put(roleShow(response));
          }
          
          
    } catch (error) {
        yield put(roleError(error));
    }
}

export function* watchRole() {
    yield takeEvery(EDIT_ROLE, editRole)
    yield takeEvery(CREATE_ROLE, createRole)
    yield takeEvery(SHOW_ROLE, showRole)
}

function* RoleSaga() {
    yield all([
        fork(watchRole),
    ]);
}

export default RoleSaga;