import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_EXPENSE_GROUP,EXPENSE_GROUP_SUCCESS,EDIT_EXPENSE_GROUP,SHOW_EXPENSE_GROUP } from './actionTypes';
import { expenseGroupSuccess, expenseGroupError,expenseGroupShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createExpenseGroup({ payload: { expenseGroup } }) {
    console.log('I am here');

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'expenseGroup/create', expenseGroup);
             if(response.success == false){
                
                yield put(expenseGroupError(response.message));
            }else if(response.success == true){
                
                yield put(expenseGroupSuccess(response.data.message));
            }

          }
    } catch (error) {
        yield put(expenseGroupError(error));
    }
}


function* editExpenseGroup({ payload: { expenseGroup } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'expenseGroup/'+expenseGroup.expense_group_id, expenseGroup);
             if(response.success == false){
                
                yield put(expenseGroupError(response.message));
            }else if(response.success == true){
                
                yield put(expenseGroupSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(expenseGroupError(error));
    }
}


function* showExpenseGroup({ payload: { expense_group_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'expenseGroup/'+expense_group_id);
             yield put(expenseGroupShow(response));
          }
          
          
    } catch (error) {
        yield put(expenseGroupError(error));
    }
}

export function* watchExpenseGroup() {
    yield takeEvery(EDIT_EXPENSE_GROUP, editExpenseGroup)
    yield takeEvery(CREATE_EXPENSE_GROUP, createExpenseGroup)
    yield takeEvery(SHOW_EXPENSE_GROUP, showExpenseGroup)
}

function* ExpenseGroupSaga() {
    yield all([
        fork(watchExpenseGroup),
    ]);
}

export default ExpenseGroupSaga;