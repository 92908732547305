import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,Label } from "reactstrap";
import { AvForm, AvField,AvInput,AvGroup  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createDeviceSettings,deviceSettingsList,editDeviceSettings,userList} from '../../../store/deviceSetings/actions';
import { rateTypeList,vehicleTypeList} from '../../../store/parkingRate/actions';
import { parkingSpaceNameList} from '../../../store/parkingSpaceName/actions';
import { parkingSlotList} from '../../../store/parkingSlot/actions';

class DeviceSetings extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                slot: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchdeviceSettingsList();  
            this.fetchRateTypeList();     
            this.fetchVehicleTypeList();     
            this.fetchparkingSpaceNameList();     
            this.fetchparkingSlotList();  
            this.fetchUserList();  
    }

    fetchUserList = () => {
        userList().then((response) => {
            this.setState({
                userList : response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList : response.data.rateType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchVehicleTypeList = () => {
        vehicleTypeList().then((response) => {
            this.setState({
                vehicleTypeList : response.data.vehicleType, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkingSpaceNameList = () => {
        parkingSpaceNameList().then((response) => {
            this.setState({
                parkingSpaceNameList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchparkingSlotList = () => {
        
        parkingSlotList().then((response) => {

            this.setState({
                parkingSlotList :response.data.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchdeviceSettingsList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        deviceSettingsList(options).then((response) => {
            console.log('p',response);

            this.setState({
                deviceSettingsList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (slot) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.slot = slot;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {   
            if(values.is_android == ''){ values.is_android = 1; }       
            this.props.createDeviceSettings(values);
            this.form && this.form.reset();
            this.fetchdeviceSettingsList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editDeviceSettings(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));

            this.fetchdeviceSettingsList(); 
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchdeviceSettingsList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchdeviceSettingsList();
            });
        }

    render() {
        let vehicleTypeList = [];
        let rateTypeList = [];
        let parkingSpaceNameList = [];
        let deviceSettingsList = [];
        let parkingSlotList = [];
        let userList = [];
        let paginator;
        if (this.state.hasOwnProperty('deviceSettingsList')) {
            deviceSettingsList = this.state.deviceSettingsList.data;
            paginator = {
                'currentPage':this.state.deviceSettingsList.current_page,
                'pagesCount':this.state.deviceSettingsList.last_page,
                'total':this.state.deviceSettingsList.total,
                'to':this.state.deviceSettingsList.to,
                'from':this.state.deviceSettingsList.from,
            }
        }

        if (this.state.hasOwnProperty('vehicleTypeList')) { vehicleTypeList = this.state.vehicleTypeList; }
        if (this.state.hasOwnProperty('rateTypeList')) { rateTypeList = this.state.rateTypeList; }
        if (this.state.hasOwnProperty('parkingSpaceNameList')) { parkingSpaceNameList = this.state.parkingSpaceNameList; }
        if (this.state.hasOwnProperty('parkingSlotList')) { parkingSlotList = this.state.parkingSlotList; }
        if (this.state.hasOwnProperty('userList')) { userList = this.state.userList; }

        const {id,device_id,device_name,f_rate_type_id,f_vehicle_type_id,mobile_no,f_parking_space_name_id,f_parking_slot_id,f_user_id,is_android,status} = this.state.slot;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Device Settings" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="8" md="8">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Device Settings List</CardTitle>
                                        <div className="table-responsive">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table  mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Device ID</th>
                                                        <th>Device Name</th>
                                                        <th>User</th>
                                                        <th>Mobile No</th>
                                                        <th>Parking Space</th>
                                                        <th>Parking Slot</th>
                                                        <th>Vehicle Type</th>
                                                        <th>Rate Type</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {deviceSettingsList.map((device, index) =>
                                                    <tr key={device.id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{device.device_id}</td>
                                                        <td>{device.device_name}</td>
                                                        <td>{device.user == null?device.f_user_id:device.user.name}</td>
                                                        <td>{device.mobile_no}</td>
                                                        <td>{device.parking_space_name == null? device.f_parking_space_name_id:device.parking_space_name.space_name_en}</td>
                                                        <td>{device.parking_slot == null? device.f_parking_slot_id: device.parking_slot.parking_slot_name_en}</td>
                                                        <td>{device.vehicle_type == null? device.f_vehicle_type_id: device.vehicle_type.vehicle_type_name_en}</td>
                                                        <td>{device.rate_type == null? device.f_rate_type_id: device.rate_type.rate_name_en }</td>
                                                        <td>{device.status == 1? 'Active':'Deactive'}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(device) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{device_name}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Device Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                
                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="User" name="f_user_id" id="f_user_id" value={f_user_id} >
                                                            <option defaultValue>Select</option>
                                                            {userList.map((user, index) =>
                                                                <option value={user.id}>{user.name}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Device ID"  id="device_id" name="device_id" value={device_id} />
                                                     <AvField name="id" id="id" value={id}  type="hidden"  />
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Device Name "  type="text" name="device_name" id="device_name" value={device_name} />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Mobile No "  type="number" name="mobile_no" id="mobile_no" value={mobile_no} />
                                                 </div>
                                            
                                             
                                                 <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Rate Type" name="f_rate_type_id" id="f_rate_type_id" value={f_rate_type_id} >
                                                            <option defaultValue>Select</option>
                                                            {rateTypeList.map((rtype, index) =>
                                                                <option value={rtype.id}>{rtype.rate_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                    
                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Vehicle Type" name="f_vehicle_type_id" id="f_vehicle_type_id" value={f_vehicle_type_id} >
                                                            <option defaultValue>Select</option>
                                                            {vehicleTypeList.map((vehicle, index) =>
                                                                <option value={vehicle.id}>{vehicle.vehicle_type_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Parking Space" name="f_parking_space_name_id" id="f_parking_space_name_id" value={f_parking_space_name_id}>
                                                            <option defaultValue>Select</option>
                                                            {parkingSpaceNameList.map((space, index) =>
                                                                <option value={space.id}>{space.space_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="parking Slot" name="f_parking_slot_id" id="f_parking_slot_id" value={f_parking_slot_id}>
                                                            <option defaultValue>Select</option>
                                                            {parkingSlotList.map((slot, index) =>
                                                                <option value={slot.id}>{slot.parking_slot_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                {/* <div className="col-md-12">
                                                        <AvInput type="checkbox"  name="is_android" id="is_android" id="status" value={is_android==1?true:false} /> Is Android?
                                                 </div> */}

                                                <div className="form-group col-md-12">
                                                        <AvInput type="checkbox"   name="status" id="status" value={status==1?true:false} /> Active
                                                </div>


                                                 
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="4" md="4">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Device Authorization</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="User" name="f_user_id" id="f_user_id" >
                                                            <option defaultValue>Select</option>
                                                            {userList.map((user, index) =>
                                                                <option value={user.id}>{user.name}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Device ID"  id="device_id" name="device_id" />
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Device Name "  type="text" name="device_name" id="device_name" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Mobile No "  type="number" name="mobile_no" id="mobile_no" />
                                                 </div>

                                                 <div className="col-md-12">
                                                    <AvField type="select"className="custom-select" label="Is Android?" name="is_android" id="is_android" value='1'>
                                                        <option defaultValue value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </AvField>
                                                 </div>
                                            
                                             
                                                 <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Rate Type" name="f_rate_type_id" id="f_rate_type_id">
                                                            <option defaultValue>Select</option>
                                                            {rateTypeList.map((rtype, index) =>
                                                                <option value={rtype.id}>{rtype.rate_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                    
                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Vehicle Type" name="f_vehicle_type_id" id="f_vehicle_type_id">
                                                            <option defaultValue>Select</option>
                                                            {vehicleTypeList.map((vehicle, index) =>
                                                                <option value={vehicle.id}>{vehicle.vehicle_type_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="Parking Space" name="f_parking_space_name_id" id="f_parking_space_name_id">
                                                            <option defaultValue>Select</option>
                                                            {parkingSpaceNameList.map((space, index) =>
                                                                <option value={space.id}>{space.space_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>
                                                
                                                <div className="form-group col-md-12">
                                                    <AvField type="select"className="custom-select" label="parking Slot" name="f_parking_slot_id" id="f_parking_slot_id">
                                                            <option defaultValue>Select</option>
                                                            {parkingSlotList.map((slot, index) =>
                                                                <option value={slot.id}>{slot.parking_slot_name_en}</option>
                                                            )}
                                                    </AvField>
                                                </div>

                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.DeviceSetings;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createDeviceSettings,editDeviceSettings })(DeviceSetings));
