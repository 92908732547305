import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PARKING_SPACE_NAME,PARKING_SPACE_NAME_SUCCESS,EDIT_PARKING_SPACE_NAME,SHOW_PARKING_SPACE_NAME } from './actionTypes';
import { parkingSpaceNameSuccess, parkingSpaceNameError,parkingSpaceNameShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createParkingSpaceName({ payload: { parkingSpaceName } }) {
    console.log('I am here');

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'parkingSpaceName/create', parkingSpaceName);
                console.log(' parkingSpaceNameSuccess res',response)

             if(response.success == false){
                
                yield put(parkingSpaceNameError(response.message));
            }else if(response.success == true){
                
                yield put(parkingSpaceNameSuccess(response.data.message));
            }
          }
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}


function* editParkingSpaceName({ payload: { parkingSpaceName } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'parkingSpaceName/'+parkingSpaceName.id, parkingSpaceName);
             if(response.success == false){
                
                yield put(parkingSpaceNameError(response.message));
            }else if(response.success == true){
                
                yield put(parkingSpaceNameSuccess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}


function* showParkingSpaceName({ payload: { parkingSpaceName_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'parkingSpaceName/'+parkingSpaceName_id);
             yield put(parkingSpaceNameShow(response));
          }
          
          
    } catch (error) {
        yield put(parkingSpaceNameError(error));
    }
}

export function* watchParkingSpaceName() {
    yield takeEvery(EDIT_PARKING_SPACE_NAME, editParkingSpaceName)
    yield takeEvery(CREATE_PARKING_SPACE_NAME, createParkingSpaceName)
    yield takeEvery(SHOW_PARKING_SPACE_NAME, showParkingSpaceName)
}

function* ParkingSpaceNameSaga() {
    yield all([
        fork(watchParkingSpaceName),
    ]);
}

export default ParkingSpaceNameSaga;