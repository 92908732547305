import axios from 'axios';


export const GENDER_TYPES = [
  {value: 1, label: 'Male'},
  {value: 2, label: 'Female'},
  // {value: 3, label: 'Other'},
];

export const LOGIN_TOKEN_NAME = 'jwtToken';

export const PAGINATION = {
  PER_PAGE: 20
};

export const STATUS_VALUES = {
  ENABLE: 1,
  DISABLE: 0
};

// export const permissions = JSON.parse(localStorage.getItem("permissions"));


