import React, { Component } from 'react';
import { Container, Row, Col, Card,Alert, CardBody,Media, Button } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';

import avatar from '../../assets/images/users/avatar-1.jpg';
// actions
import { editProfile ,showProfile,profileShow} from '../../store/actions';

class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {email : "", user_name : "", user_id : "" }

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.editProfile(values);
        profileShow(this.state.user_id).then((response) => {
            this.setState({
                user_name :response.data.user_name,
                email :response.data.email,
                user_id : response.data.user_id,  
                mobile : response.data.mobile,  
                first_name : response.data.first_name,  
                last_name : response.data.last_name,  
            });
        })
        .catch((err) => {
            console.log(err);
        })

    }

    componentDidMount()
    {

        if(localStorage.getItem("authUser"))
           {
             const obj = JSON.parse(localStorage.getItem("authUser"));
            if(process.env.REACT_APP_DEFAULTAUTH === 'jwt')
             {
                this.setState({
                    user_name :obj.user_name,
                    email :obj.email,
                    user_id : obj.user_id,    
                });
             }               
           }
    }
    componentDidUpdate(prevProps, prevState)
    {
        if(this.props !== prevProps)
        {
            profileShow(this.state.user_id).then((response) => {
                this.setState({
                    user_name :response.data.user_name,
                    email :response.data.email,
                    user_id : response.data.user_id,  
                    mobile : response.data.mobile,  
                    first_name : response.data.first_name,  
                    last_name : response.data.last_name,  
                });
            })
            .catch((err) => {
                console.log(err);
            })        
        }
    }

    render() {

        let authUser =  JSON.parse(localStorage.getItem("authUser"));
        let client = authUser.data.Company.name;
        let user = authUser.data.user.name;
        let mobile = authUser.data.user.mobile;
        let email = authUser.data.user.email;

        return (
           <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumb title="Skote" breadcrumbItem="Profile" />

                         <Row>
                            <Col lg="4" md="4">
                             

                                <Card>
                                    <CardBody>
                                        <Media>
                                            <div className="mr-3">
                                                <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail"/>
                                            </div>
                                            <Media body className="align-self-center">
                                                <div className="text-muted">
                                                    <h5>{user}</h5>
                                                    <p className="mb-0">Company: #{client}</p>
                                                </div>
                                            </Media>
                                        </Media>
                                        <br></br>
                                        <div className="form-group ">
                                            <a>User Name: {user}</a>
                                        </div>
                                        <div className="form-group ">
                                            <a>Mobile: {mobile}</a>
                                        </div>
                                        <div className="form-group ">
                                            <a>Email: {email}</a>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="8" md="8">
                                {/* <h4 className="card-title mb-4">Update Profile</h4> */}
                                {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                                <Card>
                                    <CardBody>
                                    <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}>
                                        {this.props.msg && this.props.msg ? (
                                            <Alert color="success">
                                                User Updated Successfully!
                                            </Alert>
                                            ) : null}
                                            <div className="form-group ">
                                                <AvField name="user_name" label="Name" value={user} className="form-control " readOnly placeholder="Enter UserName" type="text" />
                                                <AvField name="user_id"  value={this.state.user_id}  type="hidden"  />
                                            </div>
                                            <div className="form-group">
                                                <AvField name="email" readOnly label="Email" value={email} className="form-control" placeholder="Enter email" type="email" />
                                            </div>
                                            <div className="form-group">
                                                <AvField name="mobile" label="Mobile" value={mobile} className="form-control" placeholder="Enter mobile" type="phone" readOnly />
                                            </div>
                                            {/* <div className="text-center mt-4">
                                                <Button type="submit" color="danger">Update</Button>
                                            </div> */}
                                    </AvForm>
                                    
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
            
const mapStatetoProps = state => {
    const { msg,error,success } = state.Profile;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { editProfile,showProfile })(UserProfile));

