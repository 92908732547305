import axios from 'axios';

import {getApi} from "../services/httpService";

// Gets the logged in user data from local session 
const getLoggedInUser = () => {
    const user = localStorage.getItem('user');
    if (user)
        return JSON.parse(user);
    return null;
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}


// Login Method
const postJwtData = (url, data) => {
    return getApi().post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.message;
        return response;
    }).catch(err => {
        throw err[1];
    });
}

const editJwtData = (url, data) => {
    return getApi().put(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.message;
        return response;
    }).catch(err => {
        throw err[1];
    });
}

const deleteJwtData = (url) => {
    return getApi().delete(url).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.message;
        return response;
    }).catch(err => {
        throw err[1];
    });
}

const getJwtData = (url) => {
    return getApi().get(url).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        throw err[1];
    });
}



export { getLoggedInUser, isUserAuthenticated, postJwtData ,getJwtData ,editJwtData ,deleteJwtData}